import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createPieces } from '../../actions/PieceActions';
// import AccessDenied from '../../pages/Errors/403';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
const AddPieces = ({ currentUser, createPieces, success }) => {
  const [piece, setPiece] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create facture object
    const natureData = {
      piece,
    };

    // Dispatch createFacture action
    createPieces(natureData);

    // Reset form fields
    setPiece('');
    setShowAlert(true);
  };

  const handleChange = (e) => {
    setPiece(e.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des pièces")) ? (
        <div className=''>
          <h2 class="text-center">Ajouter un Pièce</h2>
          {showAlert && (
            <div className="alert alert-success mt-3" role="alert">
              Pièce créée avec succès !
            </div>
          )}
          <form class="form-horizontal row ms-5 ps-5 mt-4" onSubmit={handleSubmit}>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="control-label col-sm-4">Pièce:</label>
                <div class="col-sm-8">
                  <input
                    type="text"
                    name="piece"
                    value={piece}
                    onChange={handleChange}
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-1">
              <button className="btn btn-info text-center mt-3 w-50" type="submit">Sauvegarder</button>
            </div>
          </form>
        </div>
      ) : (
        <p>error</p>

      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    success: state.nature.success,
  };
};

export default connect(mapStateToProps, { createPieces })(AddPieces);
