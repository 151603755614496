import React, { useRef } from "react";
// import logo from "../../assets/images/logo1.png";
// import logo from "../../assets/images/1.png";
import logo from "../../assets/images/logo-geomaping.png";
// import logo from "../../assets/images/saftuing-logo.png";
import { Button, Paper, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";

const FicheDePaiament = ({ projet }) => {
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const today = new Date();
    const cardRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => cardRef.current,
    });

    return (
        <>
            <Paper ref={cardRef}
                style={{
                    padding: '30px',
                    marginBottom: '20px',
                    boxShadow: '0px !important',
                }}>
                <Typography variant="h3" mb={5} textAlign={'center'}>
                    Reçu de paiement
                </Typography>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <img src={logo} height={'70px'} alt="" />
                    <Typography variant="h3">{projet?.client?.full_name}</Typography>
                </div>
                <br />
                <Typography variant="body2" color="textSecondary" paragraph>
                </Typography>

                <div style={{ borderTop: "1px solid #ccc", marginTop: "1rem", paddingTop: "1rem" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <div>CIN:&nbsp;
                                <strong>
                                    {projet?.client?.cin || '-'}
                                </strong>
                            </div>
                            <Typography variant="body2">
                                Telephone:&nbsp; {projet.client?.telephone || '-'}
                                <br />
                                Adresse:&nbsp; {projet.client?.adresse || '-'}
                            </Typography>
                        </div>
                        <div className="col-md-6 text-md-end float-end">
                            <Typography variant="subtitle2" color="textSecondary" paragraph>
                                Date:&nbsp;
                                <strong>{formatDate(today)}</strong>
                            </Typography>
                        </div>
                    </div>
                </div>

                <div style={{ borderTop: "1px solid #ccc", paddingTop: "1rem" }}>
                    <div className="row">
                        <div className="col-md-6">
                            <Typography variant="body2" paragraph>
                                Objet:&nbsp;&nbsp;
                                {/* </Typography>
                        </div>
                        <div className="col-md-6 text-md-end">
                            <Typography variant="body2" paragraph> */}
                                Prestations Topographiques
                            </Typography>
                        </div>
                    </div>
                </div>
                <hr />
                <table style={{ marginTop: "0.3rem", width: "100%" }}>
                    <thead>
                        <tr>
                        <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "center", padding: '8px' }}>N°</th>
                            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "start", padding: '8px' }}>Designations</th>
                            <th scope="col" style={{ fontSize: "1.3rem", fontWeight: "bold", borderBottom: "1px solid #ccc", textAlign: "end", padding: '8px' }}>Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        {projet?.nature && projet?.nature.length > 0 ? (
                            <>
                                {/* {projet?.nature.map((natures, index) => ( */}

                                <tr style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <td style={{ padding: '10px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                                   1
                                </td>
                                    <td style={{ padding: '10px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>
                                        {projet?.nature?.map(nature => nature.name).join(' et ') || 'Default Name'}
                                    </td>
                                    <td style={{ textAlign: "end", paddingRight: '8px', fontSize: "1.3rem", borderBottom: "1px solid #ccc" }}>{projet?.prix}</td>
                                </tr>
                                {/* ))} */}
                            </>
                        ) : (
                            <tr>
                                <td colSpan="2" style={{ fontSize: "1.3rem", borderBottom: "1px solid #ccc", padding: '8px' }}>-</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Typography variant="h5" style={{ marginRight: "1rem" }}>Total:</Typography>
                    <Typography variant="h5" color="success">
                        {projet?.prix}
                    </Typography>
                </div>
            </Paper>

            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
                <Button variant="contained"
                    sx={{
                        color: "#fff !important",
                        // backgroundColor: "green !important",
                    }}
                    color="primary"
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </div>
        </>
    );
};

export default FicheDePaiament;


