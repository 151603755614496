import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPrimes } from '../../actions/PrimesActions';
import { getAllUsers } from "../../actions/UserActions";
import {
    Box,
    TextField,
    Typography,
    Container,
    Grid,
    Select,
    MenuItem,
    Snackbar,
    Alert,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import imposablePrimes from './data/ImposableData';
import nonImposablePrimes from './data/NonImposableData';
import AccessDenied from "../Errors/AccessDenied";

const CreatePrime = ({ currentUser }) => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);

    const [primeData, setPrimeData] = useState({
        mois: '',
        userId: '',
        primes: [],
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        dispatch(getAllUsers());
    }, [dispatch]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPrimeData({
            ...primeData,
            [name]: value,
        });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleAddPrime = () => {
        if (!primeData.name || !primeData.montant) return;
        const primeToAdd = {
            name: primeData.name,
            montant: primeData.montant,
            type: primeData.type,
        };
        setPrimeData({
            ...primeData,
            primes: [...primeData.primes, primeToAdd],
            name: '',
            montant: '',
        });
    };

    const handleRemovePrime = (index) => {
        const updatedSelectedPrimes = [...primeData.primes];
        updatedSelectedPrimes.splice(index, 1);
        setPrimeData({
            ...primeData,
            primes: updatedSelectedPrimes,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const primesToSend = primeData.primes;

        dispatch(createPrimes({ ...primeData, primes: primesToSend }))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Prime(s) created successfully');
                setSnackbarOpen(true);
                setPrimeData({
                    mois: '',
                    userId: '',
                    primes: [],
                });
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(`Error creating prime: ${error.message}`);
                setSnackbarOpen(true);
            });
    };

    const renderPrimesList = () => {
        const primesList = primeData.type === 'imposable' ? imposablePrimes.imposable.names : nonImposablePrimes.nonImposable.names;
        const primesNotes = primeData.type === 'non imposable' ? nonImposablePrimes.nonImposable.notes : [];

        return (
            <Select
                label="Prime Name"
                name="name"
                value={primeData.name}
                onChange={handleInputChange}
                fullWidth
                required
            >
                <MenuItem value="" disabled>
                    Select Prime
                </MenuItem>
                {primesList.map((prime, index) => (
                    <MenuItem key={index} value={prime}>
                        {prime} - {primesNotes[index]}
                    </MenuItem>
                ))}
            </Select>
        );
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Affectation de Primes")) ? (
                <Container maxWidth="lg">
                    <Typography textAlign={'center'} variant="h4" gutterBottom>
                        Insertion des primes
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid mt={2} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label='Employé'
                                    name="userId"
                                    value={primeData.userId}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                >
                                    <MenuItem value="" disabled>
                                        Select User
                                    </MenuItem>
                                    {users?.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user?.nom}&nbsp;{user?.prenom}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Mois"
                                    type='month'
                                    name="mois"
                                    value={primeData.mois}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    select
                                    label="Type"
                                    name="type"
                                    value={primeData.type}
                                    onChange={handleInputChange}
                                    fullWidth
                                >
                                    <MenuItem value="non imposable">Non Imposable</MenuItem>
                                    <MenuItem value="imposable">Imposable</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                {renderPrimesList()}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Montant"
                                    type='number'
                                    name="montant"
                                    value={primeData.montant}
                                    onChange={handleInputChange}
                                    fullWidth
                                // required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MDButton onClick={handleAddPrime} variant="contained" color="primary">
                                    Ajouter Prime
                                </MDButton>
                            </Grid>
                        </Grid>
                        <div className='mt-2'>
                            <Typography variant="h6" gutterBottom>
                                Primes sélectionnées :
                            </Typography>
                            {primeData.primes.map((prime, index) => (
                                <div className='d-flex' key={index}>
                                    <Typography variant="subtitle1">
                                        {prime.name} 
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>
                                            - Montant:
                                        </strong>
                                        {prime.montant}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>
                                            - Type: </strong>{prime.type}
                                    </Typography>
                                    <MDButton
                                        className='ms-2'
                                        onClick={() => handleRemovePrime(index)}
                                        variant="contained"
                                        color="error"
                                        size='small'
                                    >
                                        Remove
                                    </MDButton>
                                </div>
                            ))}
                        </div>
                        <Box mt={2}>
                            <MDButton type="submit" variant="contained" color="info">
                                Create Prime
                            </MDButton>
                        </Box>
                    </form>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default CreatePrime;
