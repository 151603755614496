import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectAdvances, deleteAvance } from "../../actions/EncaissementActions";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    IconButton,
    Tooltip,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
    Box,
    Button,
} from '@mui/material';
import FactureAvance from "../encaissements/FactureAvanace";
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AvanceFiles from "../encaissements/FilesList";
import { updateJusOnProjet } from '../../actions/ProjetActions';

const ProjectAdvancesList = ({ project, darkMode, handleClose }) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.encaissement.encaissements);
    const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getProjectAdvances(project?.id));
    }, [dispatch]);

    const totalAdvance = data.encaissements?.reduce((total, avance) => total + parseFloat(avance.prix), 0);

    const openFactureDialog = (item) => {
        setSelectedItem(item);
        setIsFactureDialogOpen(true);
    };

    const closeFactureDialog = () => {
        setSelectedItem(null);
        setIsFactureDialogOpen(false);
    };

    const openDeleteDialog = (item) => {
        setSelectedItem(item);
        setIsDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setSelectedItem(null);
        setIsDeleteDialogOpen(false);
    };

    const openFiles = (avance) => {
        setSelectedItem(avance);
        setIsDialogOpen(true);
    };

    const closeFiles = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteAvance(selectedItem.id))
                .then(() => {
                    dispatch(getProjectAdvances(project?.id));
                    dispatch(updateJusOnProjet(project?.id, { paiement: 'non' }))
                });
            setSelectedItem(null);
        }
        closeDeleteDialog();
    };

    const handleSwitchChange = (avance) => {
        const isSelected = selectedItems.some((item) => item.id === avance.id);

        if (isSelected) {
            setSelectedItems((prevItems) => prevItems.filter((item) => item.id !== avance.id));
        } else {
            setSelectedItems((prevItems) => [...prevItems, avance]);
        }
    };

    let totalHt = 0;
    if (project?.facture === 'oui' && selectedItems) {
        totalHt = selectedItems?.reduce((total, avance) => total + parseFloat(avance.prix), 0) / 1.2;
    } else {
        totalHt = selectedItems?.reduce((total, avance) => total + parseFloat(avance.prix), 0);
    }
    let messageNote = '';
    if (project?.facture === 'oui' && selectedItems) {
        messageNote = 'Les prix des avances sont pris comme des prix TTC car les projets sont facturés';
    } else {
        messageNote = 'Les prix des avances sont pris comme prix hors taxes car les projets ne facturent pas';
    }
    return (
        <>
            <TableContainer className={darkMode ? "text-light" : "text-dark"} sx={{ padding: '10px', marginTop: '10px', }} component={Paper}>
                <Table>
                    <TableHead style={{ display: 'table-header-group' }}>
                        <TableRow>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Prix</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Date de paiement</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Mode de paiement</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Statut</TableCell>
                            <TableCell className={darkMode ? "text-light" : "text-dark"}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.encaissements?.map((avance) => (
                            <TableRow key={avance.id}>
                                <TableCell color={darkMode ? "text-light" : "text-dark"}>
                                    {avance?.prix}
                                </TableCell>
                                <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                    {avance?.date}
                                </TableCell>
                                <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                    {avance?.type}
                                </TableCell>
                                <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                    {avance?.status}
                                </TableCell>
                                <TableCell className={darkMode ? "text-light" : "text-dark"}>
                                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Ajouter des factures")) && (
                                        <>
                                            {(avance.facture !== 'oui' && avance?.status === 'oui') &&
                                                <Tooltip title="Ajouter pour facturé" arrow>
                                                    <Switch
                                                        color="info"
                                                        onChange={() => handleSwitchChange(avance)}
                                                        checked={selectedItems.some((item) => item.id === avance.id)}
                                                    />
                                                </Tooltip>
                                            }
                                        </>
                                    )}
                                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Consulter les fichiers")) && (
                                        <>
                                            <Tooltip title="List des fihiers" arrow>
                                                <IconButton
                                                    color="success"
                                                    onClick={() => openFiles(avance)}
                                                >
                                                    <FileCopyIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les encaissements")) && (
                                        <>
                                            {(avance.facture !== 'oui') &&
                                                <Tooltip title="Supprimer" arrow>
                                                    <IconButton
                                                        color="error"
                                                        aria-label="Delete"
                                                        onClick={() => openDeleteDialog(avance)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Typography className="float-end mt-3 mb-3 me-2" variant="h5" gutterBottom>
                    Totale des Avances : {totalAdvance?.toFixed(2)}
                </Typography>
            </TableContainer>
            {selectedItems.length > 0 ? (
                <Box mt={1} p={2}>
                    <Typography variant="h5">Les avances sélectionnées pour la facturation :</Typography>
                    <Typography
                        variant="h6"
                        textAlign={'center'}
                        sx={{
                            marginTop: '1rem',
                            color: 'darkorange'
                        }}
                    >
                        <EditNoteIcon fontSize="medium" /> {messageNote.toUpperCase()}
                    </Typography>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell className="float-end">Prix</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedItems.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1} -&nbsp;
                                            {project?.nature && project?.nature.length > 0 ? (
                                                <>
                                                    {project.nature.map((natures) => (
                                                        <>{natures.name}</>
                                                    ))}
                                                </>
                                            ) : (
                                                "Aucune Nature!"
                                            )}
                                        </TableCell>
                                        <TableCell className="float-end">{item.prix}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className="row">
                            <div className="col-md-12">
                                <Typography variant="h6" className="float-end mt-3 mb-3 me-4">
                                    Total HT:&nbsp;&nbsp;&nbsp;
                                    {totalHt.toFixed(2) || 0}
                                </Typography>
                            </div>
                            <div className="col-md-12">
                                <Typography variant="h6" className="float-end mt-3 mb-3 me-4">
                                    Total TTC:&nbsp;&nbsp;&nbsp;
                                    {(totalHt * 1.2).toFixed(2) || 0}
                                </Typography>
                            </div>
                        </div>
                    </TableContainer>
                    <Box className='float-end'>
                        <Tooltip title="Facturé l'avance" arrow>
                            <IconButton
                                color="warning"
                                aria-label="Preview"
                                onClick={() => openFactureDialog()}
                            >
                                <DescriptionIcon />&nbsp;Facturé
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            ) : (
                <>
                </>
            )}

            <Dialog
                open={isFactureDialogOpen}
                onClose={closeFactureDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Facture l'avance
                </DialogTitle>
                <DialogContent>
                    {selectedItems ? (
                        <>
                            <FactureAvance avances={selectedItems} projet={selectedItems[0]?.projet} handelCloseAll={handleClose} handleClose={closeFactureDialog} totalHt={totalHt} />
                        </>
                    ) : (
                        <>
                            <Typography>Oups, il y a peut-être une erreur</Typography>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFactureDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: 'red !important',
                        color: '#fff !important',
                    }}
                >
                    Confirmer la Suppression
                </DialogTitle>
                <DialogContent>
                    <DialogContentText mt={2}>
                        Êtes-vous sûr de vouloir supprimer cet avance?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-secondary btn-sm" onClick={closeDeleteDialog}>Annuler</Button>
                    <Button sx={{ color: '#fff !important', backgroundColor: 'red !important' }} className="btn btn-danger btn-sm" onClick={handleDelete}>
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogOpen}
                onClose={closeFiles}
                fullWidth
                maxWidth="md"
            >
                {selectedItem && (
                    <>
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Liste des fichiers
                        </DialogTitle>
                        <DialogContent>
                            <AvanceFiles projectId={selectedItem?.projetId} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeFiles} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default ProjectAdvancesList;
