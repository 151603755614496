import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllPendingCharges, deleteCharge, updateCharge } from "../../actions/ChargesActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    TextField,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    useMediaQuery,
    Alert,
    Button,
    IconButton,
    Tooltip,
    Box, Grid, Paper
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessDenied from "../Errors/AccessDenied";
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

const formatDateForMonthDisplay = (dateString) => {
    if (!dateString) return "";
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
};

const PendingCharges = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const charges = useSelector((state) => state.charges.charges);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCharge, setSelectedCharge] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [isModalValiderOpen, setModalValiderOpen] = useState(false);
    const [isValiderOpen, setIsValiderOpen] = useState(false);
    const [isDisplayOpen, setIsDisplayOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity] = useState("success");
    const [pageSize, setPageSize] = useState(25);
    const [paiement, setPaiement] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(fetchAllPendingCharges());
    }, [dispatch]);

    const openDeleteModal = (charge) => {
        setSelectedCharge(charge);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedCharge(null);
        setDeleteModalOpen(false);
    };

    const openValideModal = (item) => {
        setSelectedItem(item);
        setModalValiderOpen(true);
    };

    const closeValideModal = () => {
        setSelectedItem(null);
        setModalValiderOpen(false);
    };

    const openDisplayModal = (item) => {
        setSelectedItem(item);
        setIsDisplayOpen(true);
    };

    const closeDisplayModal = () => {
        setSelectedItem(null);
        setIsDisplayOpen(false);
    };

    const handleValider = () => {
        if (selectedItem) {
            dispatch(updateCharge(selectedItem.id, { status: 'oui', paiement: paiement }))
                .then(() => {
                    dispatch(fetchAllPendingCharges());
                })
                .catch((error) => {
                    console.error('Erreur de confirmation:', error);
                });
            setSelectedItem(null);
        }
        setIsValiderOpen(false);
        closeValideModal();
    };

    const handleRejeter = () => {
        if (selectedItem) {
            dispatch(updateCharge(selectedItem.id, { status: 'non' }))
                .then(() => {
                    dispatch(fetchAllPendingCharges());
                })
                .catch((error) => {
                    console.error('Erreur de rejetant:', error);
                });
            setSelectedItem(null);
        }
        closeValideModal();
    };

    const handleDelete = async () => {
        if (selectedCharge) {
            try {
                await dispatch(deleteCharge(selectedCharge.id));
                setSnackbarMessage("Charge supprimés avec succès.");
            } catch (error) {
                setSnackbarMessage("Erreur lors de la suppression des charges.");
                console.error("Erreur lors de la suppression des charges:", error);
            }
            setSnackbarOpen(true);
            setSelectedCharge(null);
        }
        closeDeleteModal();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const filteredCharges = charges.charges?.filter(
        (charge) =>
            charge.designation.toLowerCase().includes(searchInput.toLowerCase()) ||
            formatDateForMonthDisplay(charge.mois).includes(searchInput.toLowerCase()) ||
            charge.cout.toString().includes(searchInput.toLowerCase())
    );
    const formatDateForDisplay = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const day = (dateObj.getDay()).toString().padStart(2, "0");
        return `${day}-${month}-${year}`;
    };

    const columns = [
        { field: 'id', headerName: 'ID', flex: 0.5 },
        {
            field: 'designation', headerName: 'Designation',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'mois', headerName: 'Mois',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForMonthDisplay(params.value)
        },
        {
            field: 'createdAt', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueFormatter: (params) => formatDateForDisplay(params.value)
        },
        {
            field: 'cout', headerName: 'Coût',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'user?.nom', headerName: 'Personnel',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.user?.nom || '',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <Tooltip title="Voir les détails" arrow>
                        <IconButton color="warning" onClick={() => openDisplayModal(params.row)}>
                            <VisibilityIcon fontSize="medium" />
                        </IconButton>
                    </Tooltip>
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les charges")) && (
                        <Tooltip title="Modifier" arrow>
                            <IconButton color="info" component={Link} to={`/charges/update/${params.id}`} >
                                <BorderColorIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les charges")) && (
                        <Tooltip title="Supprimer" arrow>
                            <IconButton color="error" onClick={() => openDeleteModal(params.row)}>
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <MDButton
                        variant="contained"
                        color="success"
                        size="small"
                        onClick={() => openValideModal(params.row)}
                        style={{ marginLeft: "8px" }}
                    >
                        Confirmer
                    </MDButton>
                </>
            ),
            flex: 4,
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) ? (
                <Container >
                    <Typography variant="h2" textAlign={"center"} mb={5} gutterBottom>
                        Charges en attente
                    </Typography>
                    <TextField
                        label="Search by Designation, Date, or Cost"
                        value={searchInput}
                        fullWidth
                        onChange={(e) => setSearchInput(e.target.value)}
                        variant="filled"
                        margin="normal"
                    />
                    {charges.count === 0 ? (
                        <>
                            <Typography variant="body1">No charges available.</Typography>
                        </>
                    ) : (
                        <Box
                            m="0 0 0 0"
                            height="90vh"

                        >
                            <DataGrid
                                rows={filteredCharges || []}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                className={darkMode ? "text-light" : "text-dark"}
                                components={{
                                    Toolbar: GridToolbar
                                }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                autoHeight
                            />
                        </Box>
                    )}

                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Etes-vous sûr de vouloir supprimer ce Charge?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Annuler</Button>
                    <Button onClick={handleDelete} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isModalValiderOpen} onClose={closeValideModal}>
                <DialogTitle className={darkMode ? "text-light" : "text-dark"}>
                    Confirmation de la Charge
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        Êtes-vous sûr de vouloir confirmer ou rejeter ce Charge ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeValideModal}>
                        Annuler
                    </MDButton>
                    <MDButton color='success' className="btn btn-sm" onClick={() => setIsValiderOpen(true)}>
                        Valider
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleRejeter}>
                        Rejeter
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog open={isValiderOpen} onClose={() => setIsValiderOpen(false)}>
                <DialogTitle textAlign={'center'} className={darkMode ? "text-light" : "text-dark"}>
                    Confirmation de la Charge
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        <Typography variant="body1" mb={1} p={2}>
                            Choisissez votre méthode de paiement
                        </Typography>
                        <TextField
                            select
                            label='Mode de paiement'
                            name="paiement"
                            value={paiement}
                            onChange={(e) => setPaiement(e.target.value)}
                            required
                            fullWidth
                        >
                            <MenuItem value="" disabled>
                                Sélectionnez le mode
                            </MenuItem>
                            <MenuItem value="Espèces">Espèces</MenuItem>
                            <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                            <MenuItem value="Chèques">Chèques</MenuItem>
                        </TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={() => setIsValiderOpen(false)}>
                        Annuler
                    </MDButton>
                    <MDButton color='success' className="btn btn-sm" onClick={handleValider}>
                        Confimer
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDisplayOpen}
                onClose={closeDisplayModal}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle textAlign={'center'} className={darkMode ? "text-light" : "text-dark"}>
                    Détails de la charge
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <PersonIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Personnel
                                    </Typography>
                                    <Typography>{selectedItem?.user.nom}&nbsp;{selectedItem?.user.prenom}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item md={6}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <AssignmentIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Designation
                                    </Typography>
                                    <Typography>{selectedItem?.designation}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={12}>
                                <Paper sx={{ padding: 2 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <CommentIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Observation
                                    </Typography>
                                    <Typography>{selectedItem?.note || 'Aucune remarque !'}</Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={6}>
                                <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <PaidIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Cout
                                    </Typography>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {selectedItem?.cout}&nbsp;
                                        <Typography component="span" variant="body2" color="success" sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                                            MAD
                                        </Typography>
                                    </Typography>
                                </Paper>
                            </Grid>

                            <Grid item md={6}>
                                <Paper sx={{ padding: 2, textAlign: 'center' }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        <CalendarMonthIcon fontSize="medium" sx={{ mr: 1 }} />
                                        Mois
                                    </Typography>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {formatDateForMonthDisplay(selectedItem?.mois)}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography>Oups, il y a peut-être une erreur</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeDisplayModal}>
                        Annuler
                    </MDButton>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default PendingCharges;
