import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, deleteUser } from "../../actions/UserActions";
import { Link } from "react-router-dom";
import {
  Typography,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { encodeId } from "../../actions/Crypte";

const Personnels = ({ currentUser, darkMode }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);

  const [pageSize, setPageSize] = useState(25);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteUser(userToDelete))
      .then(() => {
        setSnackbarMessage('User deleted successfully.');
        setSnackbarOpen(true);
        // Refetch users to refresh the state
        dispatch(getAllUsers());
      })
      .catch((error) => {
        console.error('Error deleting user:', error);
        setSnackbarMessage('Error deleting user. Please try again.');
        setSnackbarOpen(true);
      })
      .finally(() => {
        setDeleteDialogOpen(false);
      });
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Nom & Prenom',
      flex: 2,
      renderCell: (params) => (
        <div className="d-flex flex-column">
          <div className="blue-label">{params.row.nom} {params.row.prenom}</div>
          <div className="fs-08">{params.row.roles}</div>
        </div>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Date Recrutement',
      flex: 2,
      renderCell: (params) => (
        <div className="d-flex flex-column text-center">
          <div className="fs-08">{params.row.date_recrutement}</div>
        </div>
      ),
    },
    {
      field: 'info',
      headerName: 'Info',
      flex: 1,
      renderCell: (params) => (
        <IconButton
          component={Link}
          to={`/ressources-humaines/personnel/user/info/${encodeId(params.row.id)}`}
          title="Show All Assinged Projects"
          color="info"
        >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: 'modidfier',
      headerName: 'Modifier',
      flex: 1,
      renderCell: (params) => (
        <>
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les personnel")) && (
            <IconButton
              component={Link}
              to={`/ressources-humaines/personnels/update/${params.row.id}`}
              title="Edit Personnel"
              color="success"
            >
              <BorderColorIcon />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: 'supprimer',
      headerName: 'Supprimer',
      flex: 1,
      renderCell: (params) => (
        <>
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les personnel")) && (
            <IconButton
              title="Delete User"
              color="error"
              onClick={() => handleDeleteClick(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const createFactureButton = (
    <MDButton
      component={Link}
      to={`/ressources-humaines/personnel/create`}
      variant="contained"
      backgroundColor={'transparent'}
      size='small'
    >
      <ControlPointIcon sx={{ mr: "12px" }} />
      Ajouter
    </MDButton>
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Consulter les personnel"))) ? (
        <Container maxWidth="lg">
          <Typography variant="h2" style={{ textAlign: "center", marginBottom: "20px" }}>
            liste du personnel
          </Typography>
          <div id="personnels" className="container">
            <DataGrid
              rows={users}
              columns={columns}
              components={{
                Toolbar: (props) => (
                  <div className='d-flex'>
                    {createFactureButton}
                    <GridToolbar {...props} />
                  </div>
                ),
              }}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              autoHeight
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              style={{ color: darkMode ? "#fff" : "#000" }}
              disableSelectionOnClick
            />
          </div>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <Typography>
                Êtes-vous sûr de vouloir supprimer cet utilisateur ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
              <Button onClick={handleDeleteConfirm}
                sx={{
                  color: 'white !important',
                  backgroundColor: 'red !important',
                }}
              >
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <AccessDenied />
      )}
    </DashboardLayout>
  );
};

export default Personnels;
