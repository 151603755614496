import React, { useState } from 'react';
import { Grid, Typography, Snackbar, LinearProgress, InputLabel } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { uploadUserFiles, getUser } from "../../actions/UserActions";
import { useDispatch, useSelector } from 'react-redux';
import MDButton from '../../components/MDButton';

const FileUploadToUser = ({ userId }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const files = e.target.files;
        setSelectedFiles([...files]);
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) {
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append('files', file);
        });

        try {
            setUploadProgress(0);
            setUploadError(null);

            const response = await dispatch(uploadUserFiles(userId, formData, (progressEvent) => {
                const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                setUploadProgress(progress);
            })) || {};

            const responseData = response.data || [];
            if (Array.isArray(responseData)) {
                responseData.forEach(item => {
                    console.log(item);
                });
            }

            setSelectedFiles([]);
            setUploadProgress(0);
            setUploadSuccess(true);
            setTimeout(() => setUploadSuccess(false), 3000);
        } catch (error) {
            setUploadError('An error occurred during the upload.');
        }
    };

    return (
        <Grid container spacing={2}>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || currentUser.permissions.includes("Importer les fichiers")) && (
                    <>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="file-upload">
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept=".pdf,.jpg,.png,.doc,.docx"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                />
                                {selectedFiles?.map((file, index) => (
                                    <Typography key={index} variant="body2">
                                        {file.name}
                                    </Typography>
                                ))}
                                <MDButton
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    htmlFor="file-upload"
                                >
                                    <CloudUpload fontSize="small" style={{ marginRight: '8px' }} /> Sélectionnez les fichiers
                                </MDButton>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <MDButton variant="contained" color="info" onClick={handleUpload}>
                                Ajouter
                            </MDButton>
                        </Grid>
                        {uploadProgress > 0 && (
                            <Grid item xs={12}>
                                <LinearProgress variant="determinate" value={uploadProgress} />
                            </Grid>
                        )}
                        {uploadError && (
                            <Grid item xs={12}>
                                <Typography color="error">{uploadError}</Typography>
                            </Grid>
                        )}
                    </>
                )}
            <Snackbar
                open={uploadSuccess}
                autoHideDuration={3000}
                onClose={() => setUploadSuccess(false)}
                message="Files uploaded successfully"
            />
        </Grid>
    );
};

export default FileUploadToUser;

