import React from 'react';
import { Typography, Grid, Paper, Link, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionIcon from '@mui/icons-material/Description';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Link as RouterLink } from 'react-router-dom';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const GradientCard = styled(Paper)(({ theme }) => ({
  minHeight: '170px',
  margin: 0,
  padding: '1.7rem 1.2rem',
  borderRadius: 0,
  color: theme.palette.text.primary,
  letterSpacing: '0.05rem',
  fontFamily: "'Oswald', sans-serif",
  boxShadow: '0 0 21px rgba(0,0,0,.27)',
  transition: 'padding-left .5s',
  position: 'relative',
  '&:hover': {
    paddingLeft: '0',
    '& .txt': {
      marginLeft: '1rem',
      '& h1, & p': {
        color: 'rgba(255,255,255,1)',
        opacity: 1,
      },
    },
    '& a': {
      color: 'rgba(255,255,255,1)',
      '&:after': {
        width: '10%',
      },
    },
  },
}));

const MainContainer = styled('div')({
  height: '100vh',
  position: 'relative',
  marginTop: '1rem'
});

const styles = {
  h1: {
    fontSize: '1.5rem',
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  p: {
    fontSize: '0.7rem',
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: '0rem',
    marginTop: '33px',
    opacity: 0,
    color: 'rgba(255,255,255,1)',
  },
  a: {
    fontSize: '0.7rem',
    color: 'rgba(0,0,0,1)',
    marginLeft: '1rem',
    position: 'relative',
    bottom: '-0.5rem',
    textTransform: 'uppercase',
    '&:after': {
      content: '""',
      display: 'inline-block',
      height: '0.5em',
      width: '0',
      marginRight: '-100%',
      marginLeft: '10px',
      borderTop: '1px solid rgba(255,255,255,1)',
      transition: 'width .5s',
    },
  },
  icoCard: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  icon: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: '12rem',
    opacity: '0.2',
    color: 'rgba(255,255,255,1)',
  },
};

const Header = styled(Paper)({
  padding: '1rem',
  textAlign: 'center',
  background: 'linear-gradient(170deg, #fff 0%, #9b9b9b 100%)',
  color: 'white',
  marginBottom: '1rem',
});

const GradientCardComponent = ({ gradient, title, description, link, icon }) => {
  return (
    <Grid item xs={12} sm={6}>
      <Link component={RouterLink} to={link} sx={{ textDecoration: 'none', color: 'inherit' }}>
        <GradientCard sx={gradient}>
          <div className="txt">
            <Typography variant="h1" sx={styles.h1}>
              {title}
            </Typography>
            <Typography component="p" sx={styles.p}>
              {description}
            </Typography>
          </div>
          <Link component={RouterLink} to={link} sx={styles.a}>
            Plus
          </Link>
          <div className="ico-card" sx={styles.icoCard}>
            <IconButton sx={styles.icon}>
              {icon}
            </IconButton>
          </div>
        </GradientCard>
      </Link>
    </Grid>
  );
};

const FinanceTables = ({currentUser}) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MainContainer>
        <Typography
          sx={{
            fontSize: '36px',
            textAlign: 'center',
            marginTop: '12px',
            fontWeight: 'bold',
            color: '#3e4396',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          }}
          variant="h2"
          gutterBottom
        >
          FINANCES
        </Typography>
        <Grid container spacing={2} className="d-flex mt-3">
        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les factures")) && (
          <GradientCardComponent
            gradient={{ background: 'linear-gradient(170deg, #01E4F8 0%, #1D3EDE 100%)' }}
            title="Factures"
            description="Liste de Factures"
            link="/finances/factures"
            icon={<DescriptionIcon />}
          />
        )}
         {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les devis")) && (
          <GradientCardComponent
            gradient={{ background: 'linear-gradient(170deg, #B4EC51 0%, #429321 100%)' }}
            title="devis"
            description="Liste de devis"
            link="/finances/devis"
            icon={<FileCopyIcon />}
          />
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les charges")) && (
          <GradientCardComponent
            gradient={{ background: 'linear-gradient(170deg, #FF8484 0%, #FF3333 100%)' }}
            title="charges"
            description="Historiques des charges"
            link="/charges"
            icon={<RequestQuoteIcon />}
          />
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les salaires")) && (
          <GradientCardComponent
            gradient={{ background: 'linear-gradient(170deg, #f98fb6 0%, #D81B60 100%)' }}
            title="salaires"
            description="Historiques des salaires"
            link="/finances/salaires"
            icon={<MonetizationOnIcon />}
          />
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les encaissements")) && (
          <GradientCardComponent
            gradient={{ background: 'linear-gradient(170deg, #FAD02E 0%, #F4A261 100%)' }}
            title="recettes"
            description="Historiques des recettes"
            link="/finances/projects/avances"
            icon={<PointOfSaleIcon />}
          />
          )}
        </Grid>
      </MainContainer>
    </DashboardLayout>
  );
};

export default FinanceTables;

