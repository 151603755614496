import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createClient } from '../../actions/ClientActions';
import {
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  AlertTitle,
  Snackbar,
  FormControl,
  MenuItem,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from "../../components/MDButton";

const AddClient = ({ currentUser, createClient }) => {
  const [cin, setCin] = useState('');
  const [full_name, setFullName] = useState('');
  const [adresse, setAdresse] = useState('');
  const [telephone, setTelephone] = useState('');
  const [ice, setICE] = useState('');
  const [rc, setRC] = useState('');
  const [ifc, setIFC] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState('none');
  const [showForm, setShowForm] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [generatedFullName, setGeneratedFullName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const location = useLocation();

  const saveClient = () => {
    const clientData = {
      cin: selectedOption === 'organisme public' ? null : cin,
      full_name: full_name.toUpperCase(),
      adresse,
      telephone,
      type: selectedOption,
      ice: selectedOption !== 'personne physique' ? ice.trim() !== '' ? ice : null : null,
      rc: selectedOption === 'organisme public' ? null : rc.trim() !== '' ? rc : null,
      ifc: selectedOption !== 'personne physique' && selectedOption !== 'organisme public' ? ifc.trim() !== '' ? ifc : null : null,
    };

    createClient(clientData)
      .then((response) => {
        setClientId(response.id);
        setGeneratedFullName(full_name);
        setSubmitted(true);
        openSnackbar('Client créé avec succès', 'success');
        setCin('');
        setFullName('');
        setAdresse('');
        setTelephone('');
        setICE('');
        setRC('');
        setIFC('');
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          openSnackbar('Le client existe déjà', 'error');
        } else {
          openSnackbar(error.response.data.message, 'error');
        }
      });
  };

  const newClient = () => {
    setCin('');
    setFullName('');
    setAdresse('');
    setTelephone('');
    setICE('');
    setRC('');
    setIFC('');
    setSubmitted(false);
  };

  const onChangeCin = (e) => {
    setCin(e.target.value);
  };

  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };

  const onChangeAdresse = (e) => {
    setAdresse(e.target.value);
  };

  const onChangeTelephone = (e) => {
    setTelephone(e.target.value);
  };

  const onChangeICE = (e) => {
    setICE(e.target.value);
  };

  const onChangeRC = (e) => {
    setRC(e.target.value);
  };

  const onChangeIFC = (e) => {
    setIFC(e.target.value);
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    const showForm = selectedOption !== 'none';
    setSelectedOption(selectedOption);
    setShowForm(showForm);
  };

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity || 'success');
    setSnackbarOpen(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des clients")) ? (
        <Container maxWidth="lg">
          <Typography
            textAlign={'center'}
            variant="h3"
            gutterBottom
            sx={{
              fontSize: '35px',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            Créer un nouveau client
          </Typography>

          {submitted ? (
            <div>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Le client a été créé avec succès —
                <strong>
                  <Link to="/clients" className="text-decoration-none ms-1">
                    Vérifiez-le!
                  </Link>
                </strong>
              </Alert>
              <div className="justify-content-center text-center">
                <Button
                  className="btn btn-success mt-3"
                  onClick={newClient}
                >
                  Créer un nouveau client
                </Button>
                <Link to={`/projects/create`} state={{ from: location.pathname, clientId: clientId, clientName: generatedFullName }}>
                  <Button className="btn btn-success ms-2 mt-3">
                    Créer un projet
                  </Button>
                </Link>
              </div>
            </div>
          ) : (
            <div className=''>
              <div className="mb-2">
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Type"
                    margin="normal"
                    required
                    value={selectedOption}
                    onChange={handleOptionChange}
                    variant="standard"
                  >
                    <MenuItem value="personne physique">Personne physique</MenuItem>
                    <MenuItem value="personne morale">Personne morale</MenuItem>
                    <MenuItem value="organisme public">Organisme public</MenuItem>
                  </TextField>
                </FormControl>
              </div>
              <div className="mb-2">
                <TextField
                  fullWidth
                  id="fullName"
                  label="Name"
                  required
                  value={full_name.toUpperCase()}
                  onChange={onChangeFullName}
                  name="fullName"
                  margin='20px'
                  variant="standard"
                />
              </div>
              {showForm && (
                <div className="row">
                  <div className="col-md-6 mb-2">
                    {(selectedOption !== 'personne physique') && (
                      <TextField
                        fullWidth
                        id="ice"
                        label="ICE"
                        required
                        value={ice}
                        onChange={onChangeICE}
                        name="ice"
                        // variant="outlined"
                        variant="standard"
                        className="mb-2"
                      />
                    )}
                    {(selectedOption !== 'personne physique' && selectedOption !== 'organisme public') && (
                      <TextField
                        fullWidth
                        id="rc"
                        label="RC"
                        required
                        value={rc}
                        onChange={onChangeRC}
                        name="rc"
                        // variant="outlined"
                        variant="standard"
                      />
                    )}
                  </div>
                  <div className="col-md-6">
                    {(selectedOption !== 'personne physique' && selectedOption !== 'organisme public') && (
                      <TextField
                        fullWidth
                        id="ifc"
                        label="IFC"
                        required
                        value={ifc}
                        onChange={onChangeIFC}
                        name="ifc"
                        // variant="outlined"
                        variant="standard"
                      />
                    )}
                  </div>
                </div>
              )}
              {(selectedOption !== 'organisme public') && (
                <div className="mb-2">
                  <TextField
                    fullWidth
                    id="cin"
                    label="CIN"
                    required={selectedOption === 'personne physique'}
                    value={cin}
                    onChange={onChangeCin}
                    name="cin"
                    variant="standard"
                  />

                </div>
              )}
              <div className="mb-2">
                <TextField
                  fullWidth
                  id="adresse"
                  label="Adresse"
                  // required
                  value={adresse}
                  onChange={onChangeAdresse}
                  name="adresse"
                  // variant="outlined"
                  variant="standard"
                />
              </div>
              <div className="mb-2">
                <TextField
                  fullWidth
                  id="telephone"
                  label="Telephone"
                  // required
                  value={telephone}
                  onChange={onChangeTelephone}
                  name="telephone"
                  variant="standard"
                />
              </div>

              <MDButton
                variant="contained"
                color="info"
                onClick={saveClient}
                className="mt-4"
              >
                sauvegarder
              </MDButton>
            </div>
          )}
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={closeSnackbar}
          >
            <Alert variant='filled' onClose={closeSnackbar} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps, { createClient })(AddClient);
