import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
    Alert,
    Paper,
} from "@mui/material";
import { createDevis } from "../../actions/DevisActions";
import { getAllClients } from "../../actions/ClientActions";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import { useMaterialUIController } from "../../context";

const CreateDevis = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const [date, setDate] = useState("");
    const [objet, setObjet] = useState("");
    const [selectedClient, setSelectedClient] = useState(null);
    const [items, setItems] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const clients = useSelector((state) => state.clients);

    useEffect(() => {
        dispatch(getAllClients());
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const devisData = {
            date,
            objet,
            clientId: selectedClient.value,
            items,
        };

        dispatch(createDevis(devisData))
            .then(() => {
                openSnackbar('Devis créé avec succès', 'success');
                setDate("");
                setObjet("");
                setSelectedClient(null);
                setItems([]);
            })
            .catch((error) => {
                openSnackbar('Échec de la création de Devis', 'error');
                console.log('Error creating devis:', error);
            });
    };

    const handleAddItem = () => {
        const newItem = {
            name: "",
            unite: "",
            quantity: 0,
            prix_unitaire: 0,
            price: 0,
        };
        setItems([...items, newItem]);
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = items.map((item, i) =>
            i === index ? { ...item, [field]: value } : item
        );

        const updatedItem = updatedItems[index];
        const calculatedPrice = parseFloat(updatedItem.quantity) * parseFloat(updatedItem.prix_unitaire);
        updatedItem.price = isNaN(calculatedPrice) ? 0 : calculatedPrice;

        setItems(updatedItems);
    };

    const handleRemoveItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const clientOptions = clients.map((client) => ({
        value: client.id,
        label: client.full_name,
    }));

    const openSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity || 'success');
        setSnackbarOpen(true);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des devis")) ? (
                <Container maxWidth="">
                    <Box p={3}>
                        <Typography variant="h2" align="center" gutterBottom>
                            Créer des devis
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />

                            <TextField
                                label="Objet"
                                value={objet}
                                onChange={(e) => setObjet(e.target.value)}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                            />

                            <Autocomplete
                                options={clientOptions}
                                value={selectedClient}
                                onChange={(event, newValue) => setSelectedClient(newValue)}
                                getOptionLabel={(option) => option.label}
                                isClearable
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Client"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />

                            {items.length > 0 && (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead style={{ display: 'table-header-group' }}>
                                            <TableRow>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Designation</TableCell>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Unite</TableCell>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Prix Unitaire</TableCell>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Quantity</TableCell>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Total</TableCell>
                                                <TableCell style={{ color: darkMode ? "#fff" : "#000" }}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {items.map(({ name, unite, prix_unitaire, quantity, price }, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            value={name}
                                                            onChange={(e) => handleItemChange(index, "name", e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            value={unite}
                                                            onChange={(e) => handleItemChange(index, "unite", e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            type="number"
                                                            value={prix_unitaire}
                                                            onChange={(e) => handleItemChange(index, "prix_unitaire", e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            sx={{ width: '100%' }}
                                                            type="number"
                                                            value={quantity}
                                                            onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{price}</TableCell>
                                                    <TableCell>
                                                        <MDButton
                                                            variant="contained"
                                                            color="error"
                                                            onClick={() => handleRemoveItem(index)}
                                                        >
                                                            Remove
                                                        </MDButton>
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            <MDButton
                                variant="contained"
                                color="info"
                                className='mt-2'
                                onClick={handleAddItem}
                            >
                                Ajouter un item
                            </MDButton>
                            <div className="float-end">
                                <MDButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    sx={{ mt: 3 }}
                                >
                                    Create Devis
                                </MDButton>
                            </div>
                        </form>
                    </Box>
                </Container>
            ) : (
                <div className="container">
                    <h4 className="text-center">You don't have access to this page!</h4>
                </div>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={closeSnackbar}
            >
                <Alert variant="filled" onClose={closeSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default CreateDevis;

