import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline as LeafletPolyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';

const ShowPolylines = ({ polylines }) => {
    const mapRef = useRef(null);
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [initialCenter, setInitialCenter] = useState([30.39341198110206, -9.565342191279383]);
    const [initialZoom, setInitialZoom] = useState(10);

    useEffect(() => {
        if (polylines.length === 0) return;

        const zone = polylines[0].zone;
        let sourceCRS;
        console.log(zone);
        switch (zone) {
            case '26191':
                sourceCRS = '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
                break;
            case '26192':
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
                break;
            case '26194':
                sourceCRS = '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
                break;
            case '26195':
                sourceCRS = '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
                break;
            default:
                sourceCRS = '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
        console.log(sourceCRS);

        const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${zone}`, sourceCRS);

        const converted = polylines[0].geometry.coordinates.map(coord => {
            const [x, y] = proj4(sourceCRS, targetCRS, coord);
            return [y, x];
        });

        setConvertedCoordinates(converted);
        setInitialCenter(converted[0]);
        setInitialZoom(18);
    }, [polylines]);

    console.log(convertedCoordinates);

    // useEffect(() => {
    //     console.log('initialCenter', initialCenter);
    //     console.log('setInitialZoomout', initialZoom);
    // }, [initialCenter, initialZoom]);

    return (
        <>
            {convertedCoordinates.length > 0 && (
                <MapContainer
                    center={initialCenter}
                    zoom={initialZoom}
                    style={{ height: '80vh', width: '100%' }}
                    whenCreated={(mapInstance) => {
                        mapRef.current = mapInstance;
                    }}
                >
                    <TileLayer
                        url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                        maxZoom={21}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                    />
                    <LeafletPolyline positions={convertedCoordinates} />
                </MapContainer>
            )}
        </>
    );
};

export default ShowPolylines;
