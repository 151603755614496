import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser, updateUser } from "../../actions/UserActions";
import {
    Container,
    Typography,
    TextField,
    Grid,
} from "@mui/material";
import MDButton from "../../components/MDButton";
const EditUser = ({ userId }) => {
    const dispatch = useDispatch();
    const id = userId;

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [cin, setCin] = useState("");
    const [nom, setNom] = useState("");
    const [prenom, setPrenom] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");

    const user = useSelector((state) => state.users.user);

    useEffect(() => {
        dispatch(getUser(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (user) {
            setUsername(user.username);
            setEmail(user.email);
            setCin(user.cin);
            setNom(user.nom);
            setPrenom(user.prenom);
            setAdresse(user.adresse);
            setTelephone(user.telephone);
        }
    }, [user]);

    const handleUpdate = () => {
        const updatedUser = {
            id: id,
            username,
            cin,
            email,
            nom,
            prenom,
            // password,
            adresse,
            telephone,
        };

        dispatch(updateUser(id, updatedUser))
            .then(() => {
                window.alert("User updated successfully!"); // Show the success alert
            })
            .catch((error) => {
                // Handle error if needed
                console.log(error);
            });
    };

    return (
        <Container maxWidth="md">
            <Typography variant="h4" component="div" align="center" mt={3}>
                Mettre à jour  les informations
            </Typography>
            <form className="mt-3">
                <Grid container spacing={2}>
                    {user ? (
                        <>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Username"
                                    variant="outlined"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="CIN"
                                    variant="outlined"
                                    value={cin}
                                    onChange={(e) => setCin(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Nom"
                                    variant="outlined"
                                    value={nom}
                                    onChange={(e) => setNom(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Prenom"
                                    variant="outlined"
                                    value={prenom}
                                    onChange={(e) => setPrenom(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    variant="outlined"
                                    value={adresse}
                                    onChange={(e) => setAdresse(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Telephone"
                                    variant="outlined"
                                    value={telephone}
                                    onChange={(e) => setTelephone(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MDButton
                                    type="button"
                                    variant="contained"
                                    color="info"
                                    //   fullWidth
                                    onClick={handleUpdate}
                                >
                                    Update
                                </MDButton>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <p>Loading user data...</p>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Container>
    );
};

export default EditUser;
