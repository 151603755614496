import { combineReducers } from 'redux';
import auth from './userReducers';
import users from './usersReducer';
import message from './message';
import clients from './clientReducers';
import piecesReducer from './pieceReducers';
import natureReducer from './natureReducers';
import contactReducer from './contactReducer';
import projectReducer from './projetReducers';
import encaissementReducer from './encaissementReducers';
import permissionReducer from './permissionReducers';
import tachesReducer from './tacheReducers';
import factureReducer from './factureReducers';
import devisReducer from './devisReducer';
import vehiculeReducer from './vehiculeReducers';
import chargesReducer from './chargeReducers';
import materialReducer from './materialReducers';
import holidayReducer from './holidayReducer';
import primesReducer from './primeReducers';
import cotisationsReducer from './cotisationReducers';
import tauxReducer from './tauxReducers';
import requestReducer from './requestReducers';
import fileReducer from './filesReducers';
import stagiaireReducer from './satagiairesReducers';
import polygonsReducer from './polygonReducer';
import operationReducer from './operationsReducers';
import salairesReducer from './salaireReducers';
const rootReducer = combineReducers({
  salaires: salairesReducer,
  operation: operationReducer,
  polygon: polygonsReducer,
  stagiaires: stagiaireReducer, 
  files: fileReducer,
  request: requestReducer,
  taux: tauxReducer,
  cotisation: cotisationsReducer,
  primes: primesReducer,
  holiday: holidayReducer,
  material: materialReducer,
  charges: chargesReducer,
  vehicule: vehiculeReducer,
  devis: devisReducer,
  facture: factureReducer,
  tache: tachesReducer,
  permission: permissionReducer, 
  encaissement: encaissementReducer,
  project: projectReducer,
  commercial: contactReducer,
  piece: piecesReducer,
  nature: natureReducer,
  users,
  clients,
  message,
  auth,

});

export default rootReducer;
