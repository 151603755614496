import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
const TableData = () => {
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            {image}
            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="caption">{email}</MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description, description2 }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
            <br />
            <MDTypography variant="caption">{description2}</MDTypography>
        </MDBox>
    );

    return {
        columns: [
            { Header: "Table", accessor: "table", width: "45%", align: "left" },
            { Header: "Functions", accessor: "functions", align: "left" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "action", accessor: "action", align: "center" },
        ],

        rows: [
            {
                table: <Author image={<FolderCopyIcon />} name="Projets" email="List de Projets" />,
                functions: <Job title="Édition" description="Suppression" description2="Découvrir" />,
                status: (
                    <MDBox ml={-1}>
                        <MDBadge badgeContent="Accessible" color="success" variant="gradient" size="sm" />
                    </MDBox>
                ),
                action: (
                    <MDTypography component={Link} to={'/projects'} variant="caption" color="text" fontWeight="medium">
                        Voir
                    </MDTypography>
                ),
            },
            {
                table: <Author image={<SupervisorAccountIcon />} name="Clients" email="List de Clients" />,
                functions: <Job title="Édition" description="Suppression" description2="Découvrir" />,
                status: (
                    <MDBox ml={-1}>
                        <MDBadge badgeContent="Pas accessible" color="error" variant="gradient" size="sm" />
                    </MDBox>
                ),
                action: (
                    <MDTypography component={Link} to={'/clients'} variant="caption" color="text" fontWeight="medium">
                        Voir
                    </MDTypography>
                ),
            },

        ],
    };
}
const DataTables = () => {
    const { columns, rows } = TableData();

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3} mb={20}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Data Table
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default DataTables;