import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllAvances, deleteAvance, updateAvance } from '../../actions/EncaissementActions';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import colors from "../../assets/theme/base/colors";
import {
  Container,
  Typography,
  useMediaQuery,
  Box,
  Tooltip,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import AvanceFiles from './FilesList';
import { Delete, BorderColor } from '@mui/icons-material';
const AvanceList = ({ currentUser, darkMode }) => {
  const dispatch = useDispatch();
  const avances = useSelector((state) => state.encaissement.encaissements);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedAvance, setSelectedAvance] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAvances, setFilteredAvances] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllAvances());
  }, [dispatch]);

  useEffect(() => {
    if (Array.isArray(avances)) {
      const filteredAvances = avances.filter((avance) => {
        const prix = avance.prix || '';
        const type = avance.type || '';
        const status = avance.status || '';
        const client = avance?.projet?.client?.full_name || '';
        const numero = avance?.projet?.numero || '';
        return (
          prix.toLowerCase().includes(searchTerm.toLowerCase()) ||
          type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          status.toLowerCase().includes(searchTerm.toLowerCase()) ||
          client.toLowerCase().includes(searchTerm.toLowerCase()) ||
          numero.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredAvances(filteredAvances);
    }
  }, [avances, searchTerm]);


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const openValRejModal = (avance) => {
    setSelectedAvance(avance);
    setConfirmationOpen(true);
  };

  const closeValRejModal = () => {
    setSelectedAvance(null);
    setConfirmationOpen(false);
  };

  const openFiles = (avance) => {
    setSelectedAvance(avance);
    setIsDialogOpen(true);
  };

  const closeFiles = () => {
    setSelectedAvance(null);
    setIsDialogOpen(false);
  };

  const handleValider = () => {
    if (selectedAvance) {
      dispatch(updateAvance(selectedAvance.id, { valider: true }));
    }
    closeValRejModal();
  };

  const handleRejeter = () => {
    if (selectedAvance) {
      dispatch(updateAvance(selectedAvance.id, { valider: false }));
    }
    closeValRejModal();
  };

  const openDeleteModal = (material) => {
    setSelectedMaterial(material);
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedMaterial(null);
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    if (selectedMaterial) {
      dispatch(deleteAvance(selectedMaterial.id))
        .then(() => {
          dispatch(getAllAvances());
        });
      setSelectedMaterial(null);
    }
    closeDeleteModal();
  };

  const { white, text, info, secondary } = colors;
  const columns = [
    ...(isMobile
      ? []
      : [{ field: 'id', headerName: 'ID', flex: 0.5 },]
    ),
    // { field: 'id', headerName: 'ID', flex: 0.5 },
    {
      field: 'prix', headerName: 'Prix',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
    },
    {
      field: 'type', headerName: 'Type de paiement',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
    },
    {
      field: 'date', headerName: 'Date',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
    },
    {
      field: 'projet.client.full_name',
      headerName: 'Client',
      ...(isMobile ? { width: 190 } : { flex: 1 }),
      valueGetter: (params) => params.row.projet?.client?.full_name || '',
    },
    {
      field: 'projet.numero',
      headerName: 'Projet',
      ...(isMobile ? { width: 120 } : { flex: 1 }),
      valueGetter: (params) => params.row.projet?.numero || '',
    },
    {
      field: 'status',
      headerName: 'Confirmation Status',
      flex: 1,
      valueGetter: (params) => params.row.status || '-',
      renderCell: (params) => {
        let statusLabel, statusColor;

        switch (params.row.status) {
          case 'en cours':
            statusLabel = 'En cours';
            statusColor = 'orange';
            break;
          case 'oui':
            statusLabel = 'Validée';
            statusColor = 'green';
            break;
          case 'non':
            statusLabel = 'Rejetée';
            statusColor = 'red';
            break;
          default:
            statusLabel = "-----";
            statusColor = 'black';
        }

        return (
          <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      ...(isMobile ? { width: 180 } : { flex: 3 }),
      renderCell: (params) => (
        <>
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
            <MDButton
              onClick={() => openFiles(params.row)}
              variant="contained"
              color="warning"
              size="small"
            >
              Fichiers
            </MDButton>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les encaissements")) && (
            <Tooltip title='Modifier' arrow>
              <IconButton
                component={Link}
                to={`/projects/avances/update/${params.row.id}`}
                variant="contained"
                color="info"
                size="small"
                className='ms-2'
              >
                <BorderColor fontSize='medium' />
              </IconButton>
            </Tooltip>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements")) && (
            <>
              {(params.row?.status === null || params.row?.status === 'en cours') && (!isMobile) &&
                <MDButton
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={() => openValRejModal(params.row)}
                  className='ms-2'
                >Val/Rej</MDButton>
              }
            </>
          )}
          {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les encaissements")) && (
            <Tooltip title='Supprimer' arrow>
              <IconButton
                variant="contained"
                color={"error"}
                size="small"
                onClick={() => openDeleteModal(params.row)}
                className='ms-2 btn btn-success'
              >
                <Delete fontSize='medium' />
              </IconButton>
            </Tooltip>

          )}
        </>
      ),
    },
  ];

  const createButton = (
    <>
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des encaissements")) && (
        <Button
          component={Link}
          variant="contained"
          sx={{
            backgroundColor: '#007bff',
            color: "#fff !important"
          }}
          size='small'
          to={`/finances/projects/avances/create`}
        >
          <ControlPointIcon sx={{ mr: "12px" }} />
          Create
        </Button>
      )}
    </>
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
          || currentUser.permissions.includes("Consulter les encaissements")) ? (
          <Container
            fullWidth
            sx={{ marginBottom: '1rem !important' }}
          >
            <Typography textAlign={'center'} mb={2} variant="h2" >
              Liste des paiements
            </Typography>
            <Box
              height="70vh"
              sx={{
                marginBottom: '1rem !important',
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: '#007bff',
                  borderBottom: "none",
                  color: "#fff !important"
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: '#007bff',
                },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Recherche par client, numéro de projet, statut, prix et type ..."
                onChange={handleSearch}
                value={searchTerm}
                name="searchTerm"
                sx={{ gridColumn: "span 2" }}
              />
              <DataGrid
                rows={filteredAvances}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                style={{ color: darkMode ? "#fff" : "#000" }}
                rowsPerPageOptions={[5, 10, 20]}
                components={{ Toolbar: GridToolbar }}
                autoHeight
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              />
            </Box>
          </Container>
        ) : (
          <>
            <AccessDenied />
          </>
        )}
        <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
          <DialogTitle>Confirmer la Suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer cet Encaissement?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-secondary btn-sm" onClick={closeDeleteModal}>Annuler</button>
            <button className="btn btn-danger btn-sm" onClick={handleDelete}>
              Supprimer
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={isConfirmationOpen} onClose={closeValRejModal}>
          <DialogTitle textAlign={'center'}>Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous confirmer cet Encaissement ou le rejeter?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton size='small' color='error' onClick={handleRejeter}>
              Rejeter
            </MDButton>
            <MDButton size='small' color='success' onClick={handleValider}>
              Confirmer
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDialogOpen}
          onClose={closeFiles}
          fullWidth
          maxWidth="md"
        >
          {selectedAvance && (
            <>
              <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                Liste des fichiers
              </DialogTitle>
              <DialogContent>
                <AvanceFiles projectId={selectedAvance?.projetId} />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeFiles} color="primary">
                  Close
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      </DashboardLayout>
    </>
  );
};

export default AvanceList;

