import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateAvance } from '../../actions/EncaissementActions';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Snackbar,
  Autocomplete,
  MenuItem,
  Alert
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import { fetchAllProjects } from '../../actions/ProjetActions';
import MDButton from '../../components/MDButton';

const AddEncaissement = ({ currentUser }) => {
  const dispatch = useDispatch();
  const [avanceData, setAvanceData] = useState({
    prix: '',
    type: '',
    date: '',
    numero: '',
    status: '',
  });

  const projects = useSelector((state) => state.project.projects);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    dispatch(fetchAllProjects());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAvanceData({
      ...avanceData,
      [name]: value,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    avanceData.status = (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements"))? 'oui' : 'en cours';
    dispatch(CreateAvance(avanceData))
      .then(() => {
        setSnackbarMessage('Avance a été créé avec succès.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setAvanceData({
          prix: '',
          type: '',
          date: '',
          numero: '',
          status: '',
        });
      })
      .catch((error) => {
        setSnackbarMessage('Error creating avance. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.error('Error creating avance:', error);
      });
  };

  const projectOptions = projects.map((projet) => ({
    value: projet.numero,
    label: projet.numero,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des encaissements")) ? (
        <Container maxWidth="sm">
          <Typography variant="h4" textAlign={'center'} gutterBottom>
            Ajouter un avance
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Prix"
                  name="prix"
                  type="text"
                  value={avanceData.prix}
                  onChange={handleChange}
                  variant="outlined"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  label='Mode de paiement'
                  name="type"
                  value={avanceData.type}
                  onChange={handleChange}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Sélectionnez le mode
                  </MenuItem>
                  <MenuItem value="Espèces">Espèces</MenuItem>
                  <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                  <MenuItem value="Chèques">Chèques</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Date"
                  name="date"
                  type="date"
                  value={avanceData.date}
                  onChange={handleChange}
                  variant="outlined"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={projectOptions}
                  value={projectOptions.find((option) => option.label === avanceData.numero) || null}
                  onChange={(event, newValue) => {
                    setAvanceData({
                      ...avanceData,
                      numero: newValue ? newValue.label : '',
                    });
                  }}
                  getOptionLabel={(option) => option.label}
                  isClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Projet"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  type="submit"
                  variant="contained"
                  color="info"
                >
                  Ajouter
                </MDButton>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert variant='filled' severity={snackbarSeverity} onClose={handleCloseSnackbar}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Container>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default AddEncaissement;
