import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getAllCommercials, deleteCommercial, updateCommercial } from '../../actions/ContactActions';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import {
    Typography,
    TextField,
    Modal,
    Box,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import MDButton from '../../components/MDButton';

const Contacts = ({ currentUser }) => {
    const dispatch = useDispatch();
    const commercials = useSelector((state) => state.commercial.commercials);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [commercialToDelete, setCommercialToDelete] = useState(null);
    const [showUpdateDialog, setShowUpdateDialog] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [updatedName, setUpdatedName] = useState('');
    const [updatedPhone, setUpdatedPhone] = useState('');
    const [updatedEmail, setUpdatedEmail] = useState('');

    useEffect(() => {
        dispatch(getAllCommercials());
    }, [dispatch]);

    const handleUpdateClick = (contact) => {
        setSelectedContact(contact);
        setUpdatedName(contact.name);
        setUpdatedPhone(contact.phone);
        setUpdatedEmail(contact.email);
        setShowUpdateDialog(true);
    };

    const handleUpdate = () => {
        if (selectedContact && (updatedName !== '' || updatedPhone !== '' || updatedEmail !== '')) {
            dispatch(updateCommercial(selectedContact.id, {
                name: updatedName || selectedContact.name,
                phone: updatedPhone || selectedContact.phone,
                email: updatedEmail || selectedContact.email,
            }));
            setShowUpdateDialog(false);
            dispatch(getAllCommercials());
        }
    };
    const isMobile = window.innerWidth <= 768;

    const columns = [
        { field: 'name', headerName: 'Nome', flex: 1 },
        ...(isMobile
            ? []
            : [
                { field: 'id', headerName: 'ID', flex: 0.5 },
                { field: 'createdAt', headerName: 'Created At', flex: 1 },
            ]),
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <Box>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les contacts")) && (
                        <EditIcon
                            fontSize='medium'
                            onClick={() => handleUpdateClick(params.row)}
                        />
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les contacts")) && (
                        <DeleteIcon
                            onClick={() => handleDelete(params.id)}
                            sx={{ cursor: 'pointer', marginLeft: '15px' }}
                            color='error'
                            fontSize='medium'
                        />
                    )}
                </Box>
            ),
        },
    ];

    const handleShowConfirmation = (commercialId) => {
        setCommercialToDelete(commercialId);
        setShowConfirmation(true);
    };

    const handleHideConfirmation = () => {
        setShowConfirmation(false);
        setCommercialToDelete(null);
    };

    const handleDelete = (commercialId) => {
        handleShowConfirmation(commercialId);
    };

    const handleConfirmDelete = (commercialId) => {
        if (commercialId) {
            dispatch(deleteCommercial(commercialId))
            .then(()=>{
                dispatch(getAllCommercials());
            });
            handleHideConfirmation();
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts")) ? (
                <Box m="20px">
                    <h2 className='text-center'>Liste des contacts</h2>
                    <Box
                        m="40px 0 0 0"
                        height="90vh"

                    >
                        <DataGrid
                            rows={commercials}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Modal
                open={showConfirmation}
                onClose={handleHideConfirmation}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" component="h2" gutterBottom>
                        Confirmation de suppression
                    </Typography>
                    <Typography sx={{ marginBottom: '1rem' }}>
                        Êtes-vous sûr de vouloir supprimer ce contact ?
                    </Typography>
                    <MDButton
                        onClick={() => {
                            handleHideConfirmation();
                            handleConfirmDelete(commercialToDelete);
                        }}
                        variant="contained"
                        color="error"
                        sx={{ marginRight: '1rem' }}
                    >
                        Supprimer
                    </MDButton>
                    <MDButton onClick={handleHideConfirmation} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
            <Modal
                open={showUpdateDialog}
                onClose={() => setShowUpdateDialog(false)}
                aria-labelledby="update-modal-title"
                aria-describedby="update-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" textAlign={'center'} mb={2} component="h2" gutterBottom>
                        Mise à jour du contact
                    </Typography>
                    <TextField
                        label="Nom"
                        fullWidth
                        value={updatedName}
                        onChange={(e) => setUpdatedName(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <TextField
                        label="Téléphone"
                        fullWidth
                        value={updatedPhone}
                        onChange={(e) => setUpdatedPhone(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <TextField
                        label="Email"
                        fullWidth
                        value={updatedEmail}
                        onChange={(e) => setUpdatedEmail(e.target.value)}
                        sx={{ marginBottom: '1rem' }}
                    />
                    <MDButton
                        onClick={handleUpdate}
                        variant="contained"
                        color="info"
                        sx={{ marginRight: '1rem' }}
                    >
                        Mettre à jour
                    </MDButton>
                    <MDButton onClick={() => setShowUpdateDialog(false)} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
        </DashboardLayout>
    );
};

export default Contacts;
