import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  Add,
  ListAlt,
} from '@mui/icons-material';
import MDBox from '../../components/MDBox';
import MDTypography from '../../components/MDTypography';
import MDButton from '../../components/MDButton';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Settings = ({ darkMode, currentUser }) => {
  const [piecesDialogOpen, setPiecesDialogOpen] = useState(false);
  const [naturesDialogOpen, setNaturesDialogOpen] = useState(false);
  const [contactsDialogOpen, setContactsDialogOpen] = useState(false);
  const [tauxDialogOpen, setTauxDialogOpen] = useState(false);
  const [tachesDialogOpen, setTachesDialogOpen] = useState(false);

  const handleOpenDialog = (dialogName) => {
    switch (dialogName) {
      case 'pieces':
        setPiecesDialogOpen(true);
        break;
      case 'natures':
        setNaturesDialogOpen(true);
        break;
      case 'contacts':
        setContactsDialogOpen(true);
        break;
      case 'taux':
        setTauxDialogOpen(true);
        break;
      case 'taches':
        setTachesDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseDialog = (dialogName) => {
    switch (dialogName) {
      case 'pieces':
        setPiecesDialogOpen(false);
        break;
      case 'natures':
        setNaturesDialogOpen(false);
        break;
      case 'contacts':
        setContactsDialogOpen(false);
        break;
      case 'taux':
        setTauxDialogOpen(false);
        break;
      case 'taches':
        setTachesDialogOpen(false);
        break;
      default:
        break;
    }
  };

  const renderDialog = (dialogName) => {
    switch (dialogName) {
      case 'pieces':
        return (
          <Dialog open={piecesDialogOpen}
            fullWidth
            maxWidth={'md'}
            onClose={() => handleCloseDialog('pieces')}>
            <DialogTitle
              textAlign={'center'}
              style={{ fontSize: '24px' }}
              color='#4caf50 !important'
            >
              Réglage des Pièces
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#4caf50",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/projects/pieces">
                  <ListItemIcon>
                    <ListAlt fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Liste des Pièces" />
                </ListItem>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#4caf50",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/projects/pieces/create">
                  <ListItemIcon>
                    <Add fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Ajouter une Nouvelle Pièce" />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog('pieces')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      case 'natures':
        return (
          <Dialog open={naturesDialogOpen}
            fullWidth
            maxWidth={'md'}
            onClose={() => handleCloseDialog('natures')}>
            <DialogTitle
              textAlign={'center'}
              style={{ fontSize: '24px' }}
              color='#2196f3 !important'
            >
              Natures
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#2196f3",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/projects/natures">
                  <ListItemIcon>
                    <ListAlt fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Liste des Natures" />
                </ListItem>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#2196f3",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/projects/natures/create">
                  <ListItemIcon>
                    <Add fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Ajouter une Nouvelle Nature" />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog('natures')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      case 'contacts':
        return (
          <Dialog open={contactsDialogOpen}
            fullWidth
            maxWidth={'md'}
            onClose={() => handleCloseDialog('contacts')}>
            <DialogTitle
              textAlign={'center'}
              style={{ fontSize: '24px' }}
              color='#ff9800 !important'
            >Contacts</DialogTitle>
            <DialogContent>
              <List>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#ff9800",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/contacts">
                  <ListItemIcon>
                    <ListAlt fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Liste des Contacts" />
                </ListItem>
                <ListItem
                  sx={{
                    padding: "8px",
                    "&:hover": {
                      backgroundColor: "#ff9800",
                      color: '#fff !important',
                      padding: "15px"
                    },
                  }}
                  button component={Link} to="/contacts/create">
                  <ListItemIcon>
                    <Add fontSize='large' />
                  </ListItemIcon>
                  <ListItemText primary="Créer un Contact" />
                </ListItem>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog('contacts')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      case 'taux':
        return (
          <Dialog
            open={tauxDialogOpen}
            onClose={() => handleCloseDialog('taux')}
            fullWidth
            maxWidth={'md'}
          >
            <DialogTitle
              textAlign={'center'}
              style={{ fontSize: '24px' }}
              color='#e91e63 !important'
            >
              Taux
            </DialogTitle>
            <DialogContent>
              <Box
                component={Link}
                to={'/finances/taux/list'}
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                sx={{
                  padding: "12px",
                  backgroundColor: "#CCD1D1",
                  color: "#e91e63",
                  "&:hover": {
                    backgroundColor: "#e91e63",
                    color: '#fff !important',
                    padding: "15px"
                  },
                }}
              >
                <Typography variant="h6" color={'inherit'}>
                  La liste des taux inclut la mise à jour
                </Typography>

                <Box display="flex" alignItems="center" mr={2}>
                  <VisibilityIcon />&nbsp;Voir
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseDialog('taux')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Typography variant="h2" textAlign={'center'}>Paramètre</Typography>
      <Grid item xs={12} lg={8}>
        <Card>
          <Box p={2} lineHeight={0}>
            <Typography variant="body1" color="text" fontWeight="regular">
              Cette page est destinée à faire la configuration des éléments de votre tableau de bord.
            </Typography>
          </Box>
          <MDBox p={2}>
            <Grid container spacing={3}>
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || (currentUser.permissions.includes("Ajouter des pièces"))
                || (currentUser.permissions.includes("Consulter les pièces"))) && (
                  <Grid item xs={12} sm={6} >
                    <MDButton
                      variant="gradient"
                      color="success"
                      fullWidth
                      onClick={() => handleOpenDialog('pieces')}
                    >
                      Pièces
                    </MDButton>
                  </Grid>
                )}
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || (currentUser.permissions.includes("Consulter les natures"))
                || (currentUser.permissions.includes("Ajouter des natures"))) && (
                  <Grid item xs={12} sm={6} >
                    <MDButton
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={() => handleOpenDialog('natures')}
                    >
                      Natures
                    </MDButton>
                  </Grid>
                )}
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                || (currentUser.permissions.includes("Consulter les contacts"))
                || (currentUser.permissions.includes("Ajouter des contacts"))) && (
                  <Grid item xs={12} sm={6} >
                    <MDButton
                      variant="gradient"
                      color="warning"
                      fullWidth
                      onClick={() => handleOpenDialog('contacts')}
                    >
                      Contacts
                    </MDButton>
                  </Grid>
                )}
              {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                <Grid item xs={12} sm={6} >
                  <MDButton
                    variant="gradient"
                    color="error"
                    fullWidth
                    onClick={() => handleOpenDialog('taux')}
                  >
                    Taux
                  </MDButton>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>
      </Grid>
      {renderDialog('pieces')}
      {renderDialog('natures')}
      {renderDialog('contacts')}
      {renderDialog('taux')}
    </DashboardLayout>
  );
};

export default Settings;
