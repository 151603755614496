import DevisService from '../services/devisServices';
import {
    CREATE_DEVIS_SUCCESS,
    CREATE_DEVIS_FAILURE,
    UPDATE_DEVIS_SUCCESS,
    UPDATE_DEVIS_FAILURE,
    GET_DEVIS_SUCCESS,
    GET_DEVIS_FAILURE,
    DELETE_DEVIS_SUCCESS,
    DELETE_DEVIS_FAILURE,
    GET_ALL_DEVIS_SUCCESS,
    GET_ALL_DEVIS_FAILURE,
} from './types';

export const createDevis = (devisData) => {
  return (dispatch) => {
    return DevisService.createDevis(devisData)
      .then((response) => {
        dispatch({
          type: CREATE_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllDevis = () => {
  return (dispatch) => {
    return DevisService.getAllDevis()
      .then((response) => {
        dispatch({
          type: GET_ALL_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateDevis = (devisId, updatedDevis) => {
  return (dispatch) => {
    return DevisService.updateDevis(devisId, updatedDevis)
      .then((response) => {
        dispatch({
          type: UPDATE_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateDevisStatut = (devisId, updatedDevis) => {
  return (dispatch) => {
    return DevisService.updateDevisStatut(devisId, updatedDevis)
      .then((response) => {
        dispatch({
          type: UPDATE_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getDevis = (devisId) => {
  return (dispatch) => {
    return DevisService.fetchDevis(devisId)
      .then((response) => {
        dispatch({
          type: GET_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteDevis = (devisId) => {
  return (dispatch) => {
    return DevisService.deleteDevis(devisId)
      .then((response) => {
        dispatch({
          type: DELETE_DEVIS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_DEVIS_FAILURE,
          payload: error.message,
        });
      });
  };
};
