import { useEffect, useCallback } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { logout } from "../../actions/UserActions";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import SidenavCollapse from "./SidenavCollapse";
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { getAllPendingAvances } from '../../actions/EncaissementActions';
import { fetchAllPendingCharges } from "../../actions/ChargesActions";
import { getPendingHolidays } from '../../actions/HolidaysActions';
import { getAllPendingRequests } from '../../actions/RequestActions';
import { getPendingTaches } from '../../actions/TacheActions';

function Sidenav({ currentUser, color, brand, brandName, routes, ...rest }) {
  const dispatch1 = useDispatch();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");
  const charges = useSelector((state) => state.charges.charges);
  const avances = useSelector((state) => state.encaissement.encaissements);
  const holidays = useSelector((state) => state.holiday.holidays);
  const requests = useSelector((state) => state.request.requests);
  const tasks = useSelector((state) => state.tache.taches) ?? [];

  useEffect(() => {
    dispatch1(fetchAllPendingCharges());
    dispatch1(getAllPendingAvances());
    dispatch1(getPendingHolidays());
    dispatch1(getAllPendingRequests());
  }, [dispatch1]);

  useEffect(() => {
    dispatch1(getPendingTaches(currentUser.id));
  }, [dispatch1]);

  let count = 0;
  count = (charges.count || 0) + (avances.count || 0) + (holidays.holidayCount || 0) + (requests.count || 0);

  let countUser = 0;
  countUser = (tasks.taskCount || 0);
  let avacesCount = 0;
  let chargesCount = 0;
  let holidaysCount = 0;
  let requestsCount = 0;
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider des encaissements")) {
    avacesCount = (avances.count || 0);
  } else {
    avacesCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les charges")) {
    chargesCount = (charges.count || 0);
  } else {
    chargesCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les congés")) {
    holidaysCount = (holidays.holidayCount || 0);
  } else {
    holidaysCount = 0;
  }
  if (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les avances de salaires")) {
    requestsCount = (requests.count || 0);
  } else {
    requestsCount = 0;
  }
  const adminCount = count + countUser;
  const notificationsCount = avacesCount + chargesCount + holidaysCount + requestsCount + countUser;
  
  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const logOut = useCallback(() => {
    dispatch1(logout());
  }, [dispatch]);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (key === "notifications") {
      name = (
        <>
          {name}
          {/* {(currentUser && currentUser.roles.includes("ROLE_ADMIN")) ? ( */}
            <>
              {notificationsCount > 0 && (
                <span style={{ marginLeft: '0.5rem', background: 'red', borderRadius: '50%', padding: '0.25rem 0.5rem', color: 'white' }}>
                  {notificationsCount}
                </span>
              )}
            </>
          {/* ) : (
            <>
              {countUser > 0 && (
                <span style={{ marginLeft: '0.5rem', background: 'red', borderRadius: '50%', padding: '0.25rem 0.5rem', color: 'white' }}>
                  {countUser}
                </span>
              )}
            </>
          )} */}

        </>
      );
    }

    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />

        </Link>
      ) : (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <CloseFullscreenIcon sx={{ fontWeight: "bold" }} />
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox
            component="img"
            src={brand}
            alt="Brand"
            height="4rem"
            width="10rem"
            loading="lazy"
            style={{
              resizeMode: 'contain',
              objectFit: "contain",
              aspectRatio: 1.5,
            }}
          />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          href="/"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
          onClick={() => logOut()}
        >
          LogOut
        </MDButton>
      </MDBox>
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
