import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CreateAvance } from '../../actions/EncaissementActions';
import {
  Container,
  Typography,
  TextField,
  Grid,
  Snackbar,
  MenuItem,
  Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';

const AddAdvance = ({currentUser, numero }) => {
  const dispatch = useDispatch();
  const [avanceData, setAvanceData] = useState({
    prix: '',
    type: '',
    date: '',
    numero: '',
    status: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    setAvanceData((prevAvanceData) => ({
      ...prevAvanceData,
      numero: numero,
    }));
  }, [numero]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAvanceData({
      ...avanceData,
      [name]: value,
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    avanceData.status = currentUser.roles.includes("ROLE_ADMIN") ? 'oui' : 'en cours';
    dispatch(CreateAvance(avanceData))
      .then((response) => {
        setSnackbarMessage('Avance a été créé avec succès.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setAvanceData({
          prix: '',
          type: '',
          date: '',
          numero: '', 
          status: '',
        });
      })
      .catch((error) => {
        setSnackbarMessage(error.response.data.message);
        setSnackbarSeverity('error');
        console.log('error', error.response.data.message);
        setSnackbarOpen(true);
        console.error('Error creating avance:', error);
      });
  };

  return (
    <>
      <Container maxWidth="sm">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Prix"
                name="prix"
                type="text"
                value={avanceData.prix}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Mode de paiement"
                name="type"
                value={avanceData.type}
                onChange={handleChange}
                required
                fullWidth
              >
                <MenuItem value="" disabled>
                  Sélectionnez le mode
                </MenuItem>
                <MenuItem value="Espèces">Espèces</MenuItem>
                <MenuItem value="Virement bancaire">Virement bancaire</MenuItem>
                <MenuItem value="Chèques">Chèques</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Date"
                name="date"
                type="date"
                value={avanceData.date}
                onChange={handleChange}
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDButton type="submit" variant="contained" color="info">
                Ajouter
              </MDButton>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert variant='filled' severity={snackbarSeverity} onClose={handleCloseSnackbar}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default AddAdvance;

