export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_ALL_USERS  = "GET_ALL_USERS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const ONE_USER = "ONE_USER";
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';
export const UPDATE_USER_PERMISSION_SUCCESS = 'UPDATE_USER_PERMISSION_SUCCESS';
export const UPDATE_USER_PERMISSION_FAILURE = 'UPDATE_USER_PERMISSION_FAILURE';
export const UPLOAD_USER_FILES_SUCCESS = 'UPLOAD_USER_FILES_SUCCESS';
export const UPLOAD_USER_FILES_FAILURE = 'UPLOAD_USER_FILES_FAILURE';
export const GET_USER_FILES_SUCCESS = 'GET_USER_FILES_SUCCESS';
export const GET_USER_FILES_FAILURE = 'GET_USER_FILES_FAILURE';

// ------------------------- ROLES ---------------------------
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const GET_ALL_ROLES_FAILURE = 'GET_ALL_ROLES_FAILURE';

// ------------------------- MESSAGE  --------------------------
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// ----------------------- NATURE TYPES --------------------------

export const CREATE_STAGIAIRE_SUCCESS = 'CREATE_STAGIAIRE_SUCCESS';
export const CREATE_STAGIAIRE_FAILURE = 'CREATE_STAGIAIRE_FAILURE';
export const UPDATE_STAGIAIRE_SUCCESS = 'UPDATE_STAGIAIRE_SUCCESS';
export const UPDATE_STAGIAIRE_FAILURE = 'UPDATE_STAGIAIRE_FAILURE';
export const GET_STAGIAIRE_SUCCESS = 'GET_STAGIAIRE_SUCCESS';
export const GET_STAGIAIRE_FAILURE = 'GET_STAGIAIRE_FAILURE';
export const DELETE_STAGIAIRE_SUCCESS = 'DELETE_STAGIAIRE_SUCCESS';
export const DELETE_STAGIAIRE_FAILURE = 'DELETE_STAGIAIRE_FAILURE';
export const GET_ALL_STAGIAIRES_SUCCESS = 'GET_ALL_STAGIAIRES_SUCCESS';
export const GET_ALL_STAGIAIRES_FAILURE = 'GET_ALL_STAGIAIRES_FAILURE';

// ------------------------- CLIENT  ---------------------------
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAIL = "CREATE_CLIENT_FAIL";
export const RETRIEVE_CLIENT = "RETRIEVE_CLIENT";
export const GET_CLIENT = "GET_CLIENT";
export const RETRIEVE_CLIENTS = "RETRIEVE_CLIENTS";
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const RETRIEVE_CLIENT_BY_TYPE = "RETRIEVE_CLIENT_BY_TYPE";

// ----------------------- FILE TYPES --------------------------
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAILURE = 'GET_FILES_FAILURE';

// ----------------------- NATURE TYPES --------------------------

export const CREATE_NATURE_SUCCESS = 'CREATE_NATURE_SUCCESS';
export const CREATE_NATURE_FAILURE = 'CREATE_NATURE_FAILURE';
export const UPDATE_NATURE_SUCCESS = 'UPDATE_NATURE_SUCCESS';
export const UPDATE_NATURE_FAILURE = 'UPDATE_NATURE_FAILURE';
export const GET_NATURE_SUCCESS = 'GET_NATURE_SUCCESS';
export const GET_NATURE_FAILURE = 'GET_NATURE_FAILURE';
export const DELETE_NATURE_SUCCESS = 'DELETE_NATURE_SUCCESS';
export const DELETE_NATURE_FAILURE = 'DELETE_NATURE_FAILURE';
export const GET_ALL_NATURES_SUCCESS = 'GET_ALL_NATURES_SUCCESS';
export const GET_ALL_NATURES_FAILURE = 'GET_ALL_NATURES_FAILURE';

// ----------------------- PERMISSION TYPES --------------------------

export const CREATE_PERMISSION_SUCCESS = 'CREATE_PERMISSION_SUCCESS';
export const CREATE_PERMISSION_FAILURE = 'CREATE_PERMISSION_FAILURE';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAILURE = 'UPDATE_PERMISSION_FAILURE';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAILURE = 'GET_ALL_PERMISSIONS_FAILURE';

// -------------------------- PIECE TYPES ---------------------

export const CREATE_PIECES_SUCCESS = 'CREATE_PIECES_SUCCESS';
export const CREATE_PIECES_FAILURE = 'CREATE_PIECES_FAILURE';
export const UPDATE_PIECES_SUCCESS = 'UPDATE_PIECES_SUCCESS';
export const UPDATE_PIECES_FAILURE = 'UPDATE_PIECES_FAILURE';
export const GET_PIECES_SUCCESS = 'GET_PIECES_SUCCESS';
export const GET_PIECES_FAILURE = 'GET_PIECES_FAILURE';
export const DELETE_PIECES_SUCCESS = 'DELETE_PIECES_SUCCESS';
export const DELETE_PIECES_FAILURE = 'DELETE_PIECES_FAILURE';
export const GET_ALL_PIECESS_SUCCESS = 'GET_ALL_PIECESS_SUCCESS';
export const GET_ALL_PIECESS_FAILURE = 'GET_ALL_PIECESS_FAILURE';

// ----------------------- COMMERCIAL TYPES --------------------------

export const CREATE_COMMERCIAL_SUCCESS = 'CREATE_COMMERCIAL_SUCCESS';
export const CREATE_COMMERCIAL_FAILURE = 'CREATE_COMMERCIAL_FAILURE';
export const UPDATE_COMMERCIAL_SUCCESS = 'UPDATE_COMMERCIAL_SUCCESS';
export const UPDATE_COMMERCIAL_FAILURE = 'UPDATE_COMMERCIAL_FAILURE';
export const GET_COMMERCIAL_SUCCESS = 'GET_COMMERCIAL_SUCCESS';
export const GET_COMMERCIAL_FAILURE = 'GET_COMMERCIAL_FAILURE';
export const DELETE_COMMERCIAL_SUCCESS = 'DELETE_COMMERCIAL_SUCCESS';
export const DELETE_COMMERCIAL_FAILURE = 'DELETE_COMMERCIAL_FAILURE';
export const GET_ALL_COMMERCIALS_SUCCESS = 'GET_ALL_COMMERCIALS_SUCCESS';
export const GET_ALL_COMMERCIALS_FAILURE = 'GET_ALL_COMMERCIALS_FAILURE';

// ----------------------- PROJECTS TYPES --------------------------

export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";
export const FETCH_ALL_PROJECTS_REQUEST = "FETCH_ALL_PROJECTS_REQUEST";
export const FETCH_ALL_PROJECTS_SUCCESS = "FETCH_ALL_PROJECTS_SUCCESS";
export const FETCH_ALL_PROJECTS_FAILURE = "FETCH_ALL_PROJECTS_FAILURE";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
export const FETCH_PROJECT_COUNT_REQUEST = "FETCH_PROJECT_COUNT_REQUEST";
export const FETCH_PROJECT_COUNT_SUCCESS = "FETCH_PROJECT_COUNT_SUCCESS";
export const FETCH_PROJECT_COUNT_FAILURE = "FETCH_PROJECT_COUNT_FAILURE";
export const GET_PROJECTS_BY_STATUS_REQUEST = "GET_PROJECTS_BY_STATUS_REQUEST";
export const GET_PROJECTS_BY_STATUS_SUCCESS = "GET_PROJECTS_BY_STATUS_SUCCESS";
export const GET_PROJECTS_BY_STATUS_FAILURE = "GET_PROJECTS_BY_STATUS_FAILURE";
export const GET_PROJECTS_BY_CLIENT_ID_REQUEST = "GET_PROJECTS_BY_CLIENT_ID_REQUEST";
export const GET_PROJECTS_BY_CLIENT_ID_SUCCESS = "GET_PROJECTS_BY_CLIENT_ID_SUCCESS";
export const GET_PROJECTS_BY_CLIENT_ID_FAILURE = "GET_PROJECTS_BY_CLIENT_ID_FAILURE";
export const COUNT_PROJECTS_LAST_MONTH_SUCCESS = 'COUNT_PROJECTS_LAST_MONTH_SUCCESS';
export const COUNT_PROJECTS_LAST_MONTH_FAILURE = 'COUNT_PROJECTS_LAST_MONTH_FAILURE';
export const COUNT_PROJECTS_LAST_MONTH = 'COUNT_PROJECTS_LAST_MONTH';
export const FETCH_PROJECT_COUNT_BY_MONTH_REQUEST = 'FETCH_PROJECT_COUNT_BY_MONTH_REQUEST';
export const FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS = 'FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS';
export const FETCH_PROJECT_COUNT_BY_MONTH_FAILURE = 'FETCH_PROJECT_COUNT_BY_MONTH_FAILURE';
export const UPLOAD_PROJECT_FILES_SUCCESS = 'UPLOAD_PROJECT_FILES_SUCCESS';
export const UPLOAD_PROJECT_FILES_FAILURE = 'UPLOAD_PROJECT_FILES_FAILURE';
export const GET_PROJECT_FILES_SUCCESS = 'GET_PROJECT_FILES_SUCCESS';
export const GET_PROJECT_FILES_FAILURE = 'GET_PROJECT_FILES_FAILURE';

// -------------------------- TACHE TYPES ---------------------

export const CREATE_TACHE_SUCCESS = 'CREATE_TACHE_SUCCESS';
export const CREATE_TACHE_FAILURE = 'CREATE_TACHE_FAILURE';
export const RESSIGN_TACHE_SUCCESS = 'RESSIGN_TACHE_SUCCESS';
export const RESSIGN_TACHE_FAILURE = 'RESSIGN_TACHE_FAILURE';
export const UPDATE_TACHE_SUCCESS = 'UPDATE_TACHE_SUCCESS';
export const UPDATE_TACHE_FAILURE = 'UPDATE_TACHE_FAILURE';
export const CONFIRM_TACHE_SUCCESS = 'CONFIRM_TACHE_SUCCESS';
export const CONFIRM_TACHE_FAILURE = 'CONFIRM_TACHE_FAILURE';
export const GET_TACHE_SUCCESS = 'GET_TACHE_SUCCESS';
export const GET_TACHE_FAILURE = 'GET_TACHE_FAILURE';
export const GET_TACHE_BY_USER_SUCCESS = 'GET_TACHE_BY_USER_SUCCESS';
export const GET_TACHE_BY_USER_FAILURE = 'GET_TACHE_BY_USER_FAILURE';
export const GET_TACHE_BY_PROJECTID_SUCCESS = 'GET_TACHE_BY_PROJECTID_SUCCESS';
export const GET_TACHE_BY_PROJECTID_FAILURE = 'GET_TACHE_BY_PROJECTID_FAILURE';
export const DELETE_TACHE_SUCCESS = 'DELETE_TACHE_SUCCESS';
export const DELETE_TACHE_FAILURE = 'DELETE_TACHE_FAILURE';
export const GET_ALL_TACHES_SUCCESS = 'GET_ALL_TACHES_SUCCESS';
export const GET_ALL_TACHES_FAILURE = 'GET_ALL_TACHES_FAILURE';
export const GET_USER_TACHES_SUCCESS = 'GET_USER_TACHES_SUCCESS';
export const GET_USER_TACHES_FAILURE = 'GET_USER_TACHES_FAILURE';

// ----------------------- ENCAISEMENT TYPES --------------------------

export const CREATE_AVANCE_SUCCESS = 'CREATE_AVANCE_SUCCESS';
export const CREATE_AVANCE_FAILURE = 'CREATE_AVANCE_FAILURE';
export const UPDATE_AVANCE_SUCCESS = 'UPDATE_AVANCE_SUCCESS';
export const UPDATE_AVANCE_FAILURE = 'UPDATE_AVANCE_FAILURE';
export const GET_AVANCE_SUCCESS = 'GET_AVANCE_SUCCESS';
export const GET_AVANCE_FAILURE = 'GET_AVANCE_FAILURE';
export const DELETE_AVANCE_SUCCESS = 'DELETE_AVANCE_SUCCESS';
export const DELETE_AVANCE_FAILURE = 'DELETE_AVANCE_FAILURE';
export const GET_ALL_AVANCES_SUCCESS = 'GET_ALL_AVANCES_SUCCESS';
export const GET_ALL_AVANCES_FAILURE = 'GET_ALL_AVANCES_FAILURE';
export const GET_PROJECT_AVANCES_SUCCESS = 'GET_PROJECT_AVANCES_SUCCESS';
export const GET_PROJECT_AVANCES_FAILURE = 'GET_PROJECT_AVANCES_FAILURE';

// ----------------------- FACTURE TYPES --------------------------
export const CREATE_FACTURE_SUCCESS = 'CREATE_FACTURE_SUCCESS';
export const CREATE_FACTURE_FAILURE = 'CREATE_FACTURE_FAILURE';
export const UPDATE_FACTURE_SUCCESS = 'UPDATE_FACTURE_SUCCESS';
export const UPDATE_FACTURE_FAILURE = 'UPDATE_FACTURE_FAILURE';
export const GET_FACTURE_SUCCESS = 'GET_FACTURE_SUCCESS';
export const GET_FACTURE_FAILURE = 'GET_FACTURE_FAILURE';
export const DELETE_FACTURE_SUCCESS = 'DELETE_FACTURE_SUCCESS';
export const DELETE_FACTURE_FAILURE = 'DELETE_FACTURE_FAILURE';
export const GET_ALL_FACTURES_SUCCESS = 'GET_ALL_FACTURES_SUCCESS';
export const GET_ALL_FACTURES_FAILURE = 'GET_ALL_FACTURES_FAILURE';
export const GET_CLIENT_FACTURES_SUCCESS = 'GET_CLIENT_FACTURES_SUCCESS';
export const GET_CLIENT_FACTURES_FAILURE = 'GET_CLIENT_FACTURES_FAILURE';

// ----------------------- Devis TYPES --------------------------
export const CREATE_DEVIS_SUCCESS = 'CREATE_DEVIS_SUCCESS';
export const CREATE_DEVIS_FAILURE = 'CREATE_DEVIS_FAILURE';
export const UPDATE_DEVIS_SUCCESS = 'UPDATE_DEVIS_SUCCESS';
export const UPDATE_DEVIS_FAILURE = 'UPDATE_DEVIS_FAILURE';
export const GET_DEVIS_SUCCESS = 'GET_DEVIS_SUCCESS';
export const GET_DEVIS_FAILURE = 'GET_DEVIS_FAILURE';
export const DELETE_DEVIS_SUCCESS = 'DELETE_DEVIS_SUCCESS';
export const DELETE_DEVIS_FAILURE = 'DELETE_DEVIS_FAILURE';
export const GET_ALL_DEVIS_SUCCESS = 'GET_ALL_DEVIS_SUCCESS';
export const GET_ALL_DEVIS_FAILURE = 'GET_ALL_DEVIS_FAILURE';

// ---------------------- VEHICULE ------------------------

export const CREATE_VEHICULE_SUCCESS = 'CREATE_VEHICULE_SUCCESS';
export const CREATE_VEHICULE_FAILURE = 'CREATE_VEHICULE_FAILURE';
export const UPDATE_VEHICULE_SUCCESS = 'UPDATE_VEHICULE_SUCCESS';
export const UPDATE_VEHICULE_FAILURE = 'UPDATE_VEHICULE_FAILURE';
export const GET_VEHICULE_SUCCESS = 'GET_VEHICULE_SUCCESS';
export const GET_VEHICULE_FAILURE = 'GET_VEHICULE_FAILURE';
export const DELETE_VEHICULE_SUCCESS = 'DELETE_VEHICULE_SUCCESS';
export const DELETE_VEHICULE_FAILURE = 'DELETE_VEHICULE_FAILURE';
export const GET_ALL_VEHICULES_SUCCESS = 'GET_ALL_VEHICULES_SUCCESS';
export const GET_ALL_VEHICULES_FAILURE = 'GET_ALL_VEHICULES_FAILURE';
export const GET_USER_VEHICULES_SUCCESS = 'GET_USER_VEHICULES_SUCCESS';
export const GET_USER_VEHICULES_FAILURE = 'GET_USER_VEHICULES_FAILURE';

// ---------------------- INDEMNITE ------------------------

export const CREATE_INDEMNITE_SUCCESS = 'CREATE_INDEMNITE_SUCCESS';
export const CREATE_INDEMNITE_FAILURE = 'CREATE_INDEMNITE_FAILURE';
export const UPDATE_INDEMNITE_SUCCESS = 'UPDATE_INDEMNITE_SUCCESS';
export const UPDATE_INDEMNITE_FAILURE = 'UPDATE_INDEMNITE_FAILURE';
export const GET_INDEMNITE_SUCCESS = 'GET_INDEMNITE_SUCCESS';
export const GET_INDEMNITE_FAILURE = 'GET_INDEMNITE_FAILURE';
export const DELETE_INDEMNITE_SUCCESS = 'DELETE_INDEMNITE_SUCCESS';
export const DELETE_INDEMNITE_FAILURE = 'DELETE_INDEMNITE_FAILURE';
export const GET_ALL_INDEMNITES_SUCCESS = 'GET_ALL_INDEMNITES_SUCCESS';
export const GET_ALL_INDEMNITES_FAILURE = 'GET_ALL_INDEMNITES_FAILURE';
export const GET_ALL_PENDING_INDEMNITES_SUCCESS = 'GET_ALL_PENDING_INDEMNITES_SUCCESS';
export const GET_ALL_PENDING_INDEMNITES_FAILURE = 'GET_ALL_PENDING_INDEMNITES_FAILURE';
export const FETCH_USER_INDEMNITES_SUCCESS = 'FETCH_USER_INDEMNITES_SUCCESS';
export const FETCH_USER_INDEMNITES_FAILURE = 'FETCH_USER_INDEMNITES_FAILURE';

// ---------------------- CARBURANT ------------------------

export const CREATE_CARBURANT_SUCCESS = 'CREATE_CARBURANT_SUCCESS';
export const CREATE_CARBURANT_FAILURE = 'CREATE_CARBURANT_FAILURE';
export const UPDATE_CARBURANT_SUCCESS = 'UPDATE_CARBURANT_SUCCESS';
export const UPDATE_CARBURANT_FAILURE = 'UPDATE_CARBURANT_FAILURE';
export const GET_CARBURANT_SUCCESS = 'GET_CARBURANT_SUCCESS';
export const GET_CARBURANT_FAILURE = 'GET_CARBURANT_FAILURE';
export const DELETE_CARBURANT_SUCCESS = 'DELETE_CARBURANT_SUCCESS';
export const DELETE_CARBURANT_FAILURE = 'DELETE_CARBURANT_FAILURE';
export const GET_ALL_CARBURANTS_SUCCESS = 'GET_ALL_CARBURANTS_SUCCESS';
export const GET_ALL_CARBURANTS_FAILURE = 'GET_ALL_CARBURANTS_FAILURE';
export const GET_ALL_PENDING_CARBURANTS_SUCCESS = 'GET_ALL_PENDING_CARBURANTS_SUCCESS';
export const GET_ALL_PENDING_CARBURANTS_FAILURE = 'GET_ALL_PENDING_CARBURANTS_FAILURE';

// ----------------------- CHARGE TYPES --------------------------
export const CREATE_CHARGE_SUCCESS = 'CREATE_CHARGE_SUCCESS';
export const CREATE_CHARGE_FAILURE = 'CREATE_CHARGE_FAILURE';
export const UPDATE_CHARGE_SUCCESS = 'UPDATE_CHARGE_SUCCESS';
export const UPDATE_CHARGE_FAILURE = 'UPDATE_CHARGE_FAILURE';
export const GET_CHARGE_SUCCESS = 'GET_CHARGE_SUCCESS';
export const GET_CHARGE_FAILURE = 'GET_CHARGE_FAILURE';
export const GET_PENDING_CHARGES_SUCCESS = 'GET_PENDING_CHARGES_SUCCESS';
export const GET_PENDING_CHARGES_FAILURE = 'GET_PENDING_CHARGES_FAILURE';
export const DELETE_CHARGE_SUCCESS = 'DELETE_CHARGE_SUCCESS';
export const DELETE_CHARGE_FAILURE = 'DELETE_CHARGE_FAILURE';
export const GET_ALL_CHARGES_SUCCESS = 'GET_ALL_CHARGES_SUCCESS';
export const GET_ALL_CHARGES_FAILURE = 'GET_ALL_CHARGES_FAILURE';

// ----------------------- MATERIAL TYPES --------------------------
export const CREATE_MATERIAL_SUCCESS = 'CREATE_MATERIAL_SUCCESS';
export const CREATE_MATERIAL_FAILURE = 'CREATE_MATERIAL_FAILURE';
export const UPDATE_MATERIAL_SUCCESS = 'UPDATE_MATERIAL_SUCCESS';
export const UPDATE_MATERIAL_FAILURE = 'UPDATE_MATERIAL_FAILURE';
export const GET_MATERIAL_SUCCESS = 'GET_MATERIAL_SUCCESS';
export const GET_MATERIAL_FAILURE = 'GET_MATERIAL_FAILURE';
export const DELETE_MATERIAL_SUCCESS = 'DELETE_MATERIAL_SUCCESS';
export const DELETE_MATERIAL_FAILURE = 'DELETE_MATERIAL_FAILURE';
export const GET_ALL_MATERIALS_SUCCESS = 'GET_ALL_MATERIALS_SUCCESS';
export const GET_ALL_MATERIALS_FAILURE = 'GET_ALL_MATERIALS_FAILURE';

// ----------------------- MATERIAL TYPES --------------------------
export const CREATE_OPERATION_SUCCESS = 'CREATE_OPERATION_SUCCESS';
export const CREATE_OPERATION_FAILURE = 'CREATE_OPERATION_FAILURE';
export const UPDATE_OPERATION_SUCCESS = 'UPDATE_OPERATION_SUCCESS';
export const UPDATE_OPERATION_FAILURE = 'UPDATE_OPERATION_FAILURE';
export const GET_OPERATION_SUCCESS = 'GET_OPERATION_SUCCESS';
export const GET_OPERATION_FAILURE = 'GET_OPERATION_FAILURE';
export const DELETE_OPERATION_SUCCESS = 'DELETE_OPERATION_SUCCESS';
export const DELETE_OPERATION_FAILURE = 'DELETE_OPERATION_FAILURE';
export const GET_ALL_OPERATIONS_SUCCESS = 'GET_ALL_OPERATIONS_SUCCESS';
export const GET_ALL_OPERATIONS_FAILURE = 'GET_ALL_OPERATIONS_FAILURE';

// ------------------------- HOLIDAYS ---------------------------
export const SEND_HOLIDAY_REQUEST_SUCCESS = 'SEND_HOLIDAY_REQUEST_SUCCESS';
export const SEND_HOLIDAY_REQUEST_FAILURE = 'SEND_HOLIDAY_REQUEST_FAILURE';
export const GET_ALL_PENDING_HOLIDAYS_SUCCESS = 'GET_ALL_PENDING_HOLIDAYS_SUCCESS';
export const GET_ALL_PENDING_HOLIDAYS_FAILURE = 'GET_ALL_PENDING_HOLIDAYS_FAILURE';
export const FETCH_HOLIDAY_SUCCESS = 'FETCH_HOLIDAY_SUCCESS';
export const FETCH_HOLIDAY_FAILURE = 'FETCH_HOLIDAY_FAILURE';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';
export const GET_ALL_HOLIDAYS_SUCCESS = 'GET_ALL_HOLIDAYS_SUCCESS';
export const GET_ALL_HOLIDAYS_FAILURE = 'GET_ALL_HOLIDAYS_FAILURE';
export const GET_ALL_USERHOLIDAYS_SUCCESS = 'GET_ALL_USERHOLIDAYS_SUCCESS';
export const GET_ALL_USERHOLIDAYS_FAILURE = 'GET_ALL_USERHOLIDAYS_FAILURE';

// -------------------------- PRIMES TYPES ---------------------

export const CREATE_PRIME_SUCCESS = 'CREATE_PRIME_SUCCESS';
export const CREATE_PRIME_FAILURE = 'CREATE_PRIME_FAILURE';
export const UPDATE_PRIME_SUCCESS = 'UPDATE_PRIME_SUCCESS';
export const UPDATE_PRIME_FAILURE = 'UPDATE_PRIME_FAILURE';
export const GET_PRIME_SUCCESS = 'GET_PRIME_SUCCESS';
export const GET_PRIME_FAILURE = 'GET_PRIME_FAILURE';
export const DELETE_PRIME_SUCCESS = 'DELETE_PRIME_SUCCESS';
export const DELETE_PRIME_FAILURE = 'DELETE_PRIME_FAILURE';
export const GET_ALL_PRIMES_SUCCESS = 'GET_ALL_PRIMES_SUCCESS';
export const GET_ALL_PRIMES_FAILURE = 'GET_ALL_PRIMES_FAILURE';
export const GET_ALL_USER_PRIMES_SUCCESS = 'GET_ALL_USER_PRIMES_SUCCESS';
export const GET_ALL_USER_PRIMES_FAILURE = 'GET_ALL_USER_PRIMES_FAILURE';
export const UPDATE_USER_PRIME_SUCCESS = 'UPDATE_USER_PRIME_SUCCESS';
export const UPDATE_USER_PRIME_FAILURE = 'UPDATE_USER_PRIME_FAILURE';

// -------------------------- COTISATION TYPES ---------------------

export const CREATE_COTISATION_SUCCESS = 'CREATE_COTISATION_SUCCESS';
export const CREATE_COTISATION_FAILURE = 'CREATE_COTISATION_FAILURE';
export const UPDATE_COTISATION_SUCCESS = 'UPDATE_COTISATION_SUCCESS';
export const UPDATE_COTISATION_FAILURE = 'UPDATE_COTISATION_FAILURE';
export const GET_COTISATION_SUCCESS = 'GET_COTISATION_SUCCESS';
export const GET_COTISATION_FAILURE = 'GET_COTISATION_FAILURE';
export const GET_USER_COTISATION_SUCCESS = 'GET_USER_COTISATION_SUCCESS';
export const GET_USER_COTISATION_FAILURE = 'GET_USER_COTISATION_FAILURE';
export const DELETE_COTISATION_SUCCESS = 'DELETE_COTISATION_SUCCESS';
export const DELETE_COTISATION_FAILURE = 'DELETE_COTISATION_FAILURE';
export const GET_ALL_COTISATIONS_SUCCESS = 'GET_ALL_COTISATIONS_SUCCESS';
export const GET_ALL_COTISATIONS_FAILURE = 'GET_ALL_COTISATIONS_FAILURE';

// -------------------------- COTISATION TYPES ---------------------

export const CREATE_SALAIRE_SUCCESS = 'CREATE_SALAIRE_SUCCESS';
export const CREATE_SALAIRE_FAILURE = 'CREATE_SALAIRE_FAILURE';
export const UPDATE_SALAIRE_SUCCESS = 'UPDATE_SALAIRE_SUCCESS';
export const UPDATE_SALAIRE_FAILURE = 'UPDATE_SALAIRE_FAILURE';
export const GET_SALAIRE_SUCCESS = 'GET_SALAIRE_SUCCESS';
export const GET_SALAIRE_FAILURE = 'GET_SALAIRE_FAILURE';
export const GET_USER_SALAIRE_SUCCESS = 'GET_USER_SALAIRE_SUCCESS';
export const GET_USER_SALAIRE_FAILURE = 'GET_USER_SALAIRE_FAILURE';
export const DELETE_SALAIRE_SUCCESS = 'DELETE_SALAIRE_SUCCESS';
export const DELETE_SALAIRE_FAILURE = 'DELETE_SALAIRE_FAILURE';
export const GET_ALL_SALAIRES_SUCCESS = 'GET_ALL_SALAIRES_SUCCESS';
export const GET_ALL_SALAIRES_FAILURE = 'GET_ALL_SALAIRES_FAILURE';

// -------------------------- CONSTANT TYPES ---------------------

export const CREATE_CONSTANT_SUCCESS = 'CREATE_CONSTANT_SUCCESS';
export const CREATE_CONSTANT_FAILURE = 'CREATE_CONSTANT_FAILURE';
export const UPDATE_CONSTANT_SUCCESS = 'UPDATE_CONSTANT_SUCCESS';
export const UPDATE_CONSTANT_FAILURE = 'UPDATE_CONSTANT_FAILURE';
export const GET_CONSTANT_SUCCESS = 'GET_CONSTANT_SUCCESS';
export const GET_CONSTANT_FAILURE = 'GET_CONSTANT_FAILURE';
export const GET_USER_CONSTANT_SUCCESS = 'GET_USER_CONSTANT_SUCCESS';
export const GET_USER_CONSTANT_FAILURE = 'GET_USER_CONSTANT_FAILURE';
export const DELETE_CONSTANT_SUCCESS = 'DELETE_CONSTANT_SUCCESS';
export const DELETE_CONSTANT_FAILURE = 'DELETE_CONSTANT_FAILURE';
export const GET_ALL_CONSTANTS_SUCCESS = 'GET_ALL_CONSTANTS_SUCCESS';
export const GET_ALL_CONSTANTS_FAILURE = 'GET_ALL_CONSTANTS_FAILURE';

// -------------------------- PRIMES TYPES ---------------------

export const CREATE_TAUX_SUCCESS = 'CREATE_TAUX_SUCCESS';
export const CREATE_TAUX_FAILURE = 'CREATE_TAUX_FAILURE';
export const UPDATE_TAUX_SUCCESS = 'UPDATE_TAUX_SUCCESS';
export const UPDATE_TAUX_FAILURE = 'UPDATE_TAUX_FAILURE';
export const GET_TAUX_SUCCESS = 'GET_TAUX_SUCCESS';
export const GET_TAUX_FAILURE = 'GET_TAUX_FAILURE';
export const DELETE_TAUX_SUCCESS = 'DELETE_TAUX_SUCCESS';
export const DELETE_TAUX_FAILURE = 'DELETE_TAUX_FAILURE';
export const GET_ALL_TAUXS_SUCCESS = 'GET_ALL_TAUXS_SUCCESS';
export const GET_ALL_TAUXS_FAILURE = 'GET_ALL_TAUXS_FAILURE';

// ----------------------- REQUEST TYPES --------------------------
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE';
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILURE = 'UPDATE_REQUEST_FAILURE';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const GET_PENDING_REQUESTS_SUCCESS = 'GET_PENDING_REQUESTS_SUCCESS';
export const GET_PENDING_REQUESTS_FAILURE = 'GET_PENDING_REQUESTS_FAILURE';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAILURE = 'DELETE_REQUEST_FAILURE';
export const GET_ALL_REQUESTS_SUCCESS = 'GET_ALL_REQUESTS_SUCCESS';
export const GET_ALL_REQUESTS_FAILURE = 'GET_ALL_REQUESTS_FAILURE';
export const GET_ALL_USER_REQUESTS_SUCCESS = 'GET_ALL_USER_REQUESTS_SUCCESS';
export const GET_ALL_USER_REQUESTS_FAILURE = 'GET_ALL_USER_REQUESTS_FAILURE';

// ----------------------- REQUEST TYPES --------------------------
export const CREATE_PROJECT_POLYGON_SUCCESS = 'CREATE_PROJECT_POLYGON_SUCCESS';
export const CREATE_PROJECT_POLYGON_FAILURE = 'CREATE_PROJECT_POLYGON_FAILURE';
