import {
  CREATE_CHARGE_SUCCESS,
  CREATE_CHARGE_FAILURE,
  UPDATE_CHARGE_SUCCESS,
  UPDATE_CHARGE_FAILURE,
  GET_CHARGE_SUCCESS,
  GET_CHARGE_FAILURE,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAILURE,
  GET_ALL_CHARGES_SUCCESS,
  GET_ALL_CHARGES_FAILURE,
  GET_PENDING_CHARGES_SUCCESS,
  GET_PENDING_CHARGES_FAILURE,
} from '../actions/types';
const initialState = {
  charge: null,
  charges: [],
  error: null,
};

const chargesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHARGE_SUCCESS:
      return {
        ...state,
        charge: action.payload,
        error: null,
      };
    case CREATE_CHARGE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_CHARGE_SUCCESS:
      return {
        ...state,
        charge: action.payload,
        error: null,
      };
    case UPDATE_CHARGE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_CHARGE_SUCCESS:
      return {
        ...state,
        charge: action.payload,
        error: null,
      };
    case GET_CHARGE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_CHARGE_SUCCESS:
      return {
        ...state,
        charge: null,
        error: null,
      };
    case DELETE_CHARGE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload,
        error: null,
      };
    case GET_ALL_CHARGES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PENDING_CHARGES_SUCCESS:
      return {
        ...state,
        charges: action.payload,
        error: null,
      };
    case GET_PENDING_CHARGES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default chargesReducer;
