import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewTache } from '../../actions/TacheActions';
import { getAllUsers } from '../../actions/UserActions';
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
    Select,
    MenuItem,
    Snackbar,
    SnackbarContent,
    IconButton,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { Close as CloseIcon } from '@mui/icons-material';


const CreateTache = () => {
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users) || [];

    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');

    useEffect(() => {
        // Fetch users when the component mounts
        dispatch(getAllUsers());
    }, [dispatch]);

    const [tacheData, setTacheData] = useState({
        name: '',
        deadline: '',
        note: '',
        projectNumber: '',
        userId: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTacheData({
            ...tacheData,
            [name]: value,
        });
    };

    const handleSuccessSnackbarClose = () => {
        setSuccessSnackbarOpen(false);
    };

    const handleErrorSnackbarClose = () => {
        setErrorSnackbarOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(createNewTache(tacheData))
            .then(() => {
                setSnackbarType('success');
                setSnackbarMessage('Task created successfully');
                setSuccessSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarType('error');
                setSnackbarMessage(`Error creating task: ${error.message}`);
                setErrorSnackbarOpen(true);
            });
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h4" gutterBottom>
                Create a New Task
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Task Name"
                            name="name"
                            value={tacheData.name}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Deadline"
                            type="date"
                            name="deadline"
                            value={tacheData.deadline}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Notes"
                            name="note"
                            value={tacheData.note}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Project Number"
                            name="projectNumber"
                            value={tacheData.projectNumber}
                            onChange={handleInputChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            label="User"
                            name="userId"
                            value={tacheData.userId}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        >
                            <MenuItem value="" disabled>
                                Select User
                            </MenuItem>
                            {users?.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                    {user?.nom}&nbsp;{user?.prenom}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary">
                        Create Task
                    </Button>
                </Box>
            </form>

            <Snackbar
                open={successSnackbarOpen || errorSnackbarOpen}
                autoHideDuration={6000}
                onClose={
                    snackbarType === 'success'
                        ? handleSuccessSnackbarClose
                        : handleErrorSnackbarClose
                }
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={{
                        backgroundColor:
                            snackbarType === 'success' ? 'green' : 'red', // Customize colors
                    }}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={
                                snackbarType === 'success'
                                    ? handleSuccessSnackbarClose
                                    : handleErrorSnackbarClose
                            }
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </DashboardLayout>
    );
};

export default CreateTache;
