import React, { useEffect, useState } from "react";
import PolylineServices from '../../services/polylineServices';
import {
    Typography,
    Card,
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import ShowPolylines from "./ShowPolylines";
import AddPolyline from "./AjouterPolyline";

const PolylineIndex = ({ projectId, currentUser }) => {
    const [polylines, setPolylines] = useState([]);
    const [isPolylineProjectOpen, setIsPolylineProjectOpen] = useState(false);

    useEffect(() => {
        fetchPolylines();
    }, [projectId]);


    const fetchPolylines = async () => {
        try {
            const response = await PolylineServices.getPolylinesByProjectId(projectId);
            setPolylines(response.data.polylines);
        } catch (error) {
            console.error('Error fetching polygons:', error);
        }
    };


    return (
        <>
            {polylines.length > 0 ? (
                <>
                    <ShowPolylines polylines={polylines} />
                </>
            ) : (
                <>
                    <Grid item xs={12} lg={8}>
                        <Card>
                            <Box p={2} lineHeight={0}>
                                <Typography variant="body1">
                                    Aucun emplacement ou polyligne trouvé, veuillez importer votre polyligne dxf
                                </Typography>
                            </Box>
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) &&
                                <Box p={2}>
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        fullWidth
                                        onClick={() => setIsPolylineProjectOpen(true)}
                                    >
                                        Importer DXF
                                    </MDButton>
                                </Box>
                            }
                        </Card>
                    </Grid>
                </>
            )}
            <Dialog
                open={isPolylineProjectOpen}
                onClose={() => setIsPolylineProjectOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Importer DXF
                </DialogTitle>
                <DialogContent>
                    <AddPolyline projectId={projectId} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPolylineProjectOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default PolylineIndex;