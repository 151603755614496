import API_URL from './apiUrl';

class ChargeService {
  createCharge(chargeData) {
    return API_URL.post("/charge/create", chargeData);
  }

  getAllCharges() {
    return API_URL.get("/charge");
  }

  getAllPendingCharges() {
    return API_URL.get("/charge/pending/charges");
  }

  updateCharge(chargeId, updatedCharge) {
    return API_URL.put(`/charge/update/${chargeId}`, updatedCharge);
  }

  fetchCharge(chargeId) {
    return API_URL.get(`/charge/${chargeId}`);
  }
  deleteCharge(chargeId) {
    return API_URL.delete(`/charge/delete/${chargeId}`);
  }

  fetchEnumValues() {
    return API_URL.get("/charge/designation/designation-enum-values");
  }

  addEnumValues(newEnumValue) {
    return API_URL.post(`/charge/add/designation/enum/value`, newEnumValue);
  }

  updateEnumValue(enumValue) {
    return API_URL.post(`/charge/designation/update-designation-enum-value`, enumValue);
  }

  deleteEnumValue(enumValueToDelete) {
    return API_URL.delete(`/charge/designation/delete-designation-enum-value/${enumValueToDelete}`);
  }

  fetchTotaleCharges() {
    return API_URL.get("/charge/charges/totale/all");
  }

  getTotalChargesByDesignation() {
    return API_URL.get("/charge/charges/totale/ByDesignation");
  }

  getTotalChargesForVehicules() {
    return API_URL.get("/charge//charges/totale/ofVehicules");
  }

  getTotalChargesForEveryVehicule() {
    return API_URL.get("/charge//charges/totale/ofEveryVehicules");
  }

  getTotalChargesForUsers() {
    return API_URL.get("/charge//charges/totale/forUser");
  }

  getTotalChargesByYear() {
    return API_URL.get("/charge//charges/totale/byYears");
  }

  getCurrentMonthCharges() {
    return API_URL.get("/charge/charges/totale/currentMonth");
  }

  fetchChargesByUserId(userId) {
    return API_URL.get(`/charge/charges/by/user/${userId}`);
  }

  uploadFilesToCharge = (chargeId, formData) => {
    return API_URL.post(`/charge/charges/${chargeId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getAllChargeFiles = (chargeId) => {
    return API_URL.get(`/charge/charges/${chargeId}/files`);
  }

  getFileContent = (chargeId, fileName) => {
    return API_URL.get(`/charge/charges/${chargeId}/files/${fileName}`);
  }

  downloadFile = (chargeId, fileName) => {
    return API_URL.get(`/charge/charges/${chargeId}/download/${fileName}`, {
      responseType: 'blob',
    });
  };

  deleteFile = (chargeId, fileName) => {
    return API_URL.delete(`/charge/charges/${chargeId}/delete/${fileName}`);
  }

}

export default new ChargeService();
