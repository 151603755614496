import AvanceService from '../services/encaissementsServices';
import {
  CREATE_AVANCE_SUCCESS,
  CREATE_AVANCE_FAILURE,
  UPDATE_AVANCE_SUCCESS,
  UPDATE_AVANCE_FAILURE,
  GET_AVANCE_SUCCESS,
  GET_AVANCE_FAILURE,
  DELETE_AVANCE_SUCCESS,
  DELETE_AVANCE_FAILURE,
  GET_ALL_AVANCES_SUCCESS,
  GET_ALL_AVANCES_FAILURE,
  GET_PROJECT_AVANCES_SUCCESS,
  GET_PROJECT_AVANCES_FAILURE,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
} from './types';

export const uploadFiles = (projectId, formData) => {
  return async (dispatch) => {
      try {
          const response = await AvanceService.uploadFilesToAvance(projectId, formData);
          dispatch({
              type: UPLOAD_FILES_SUCCESS,
              payload: response.data,
          });
          return response;
      } catch (error) {
          dispatch({
              type: UPLOAD_FILES_FAILURE,
              payload: error.response ? error.response.data : 'An error occurred',
          });
          throw error;
      }
  };
};

export const fetchAvanceFiles = (projectId) => {
  return (dispatch) => {
      return AvanceService.getAllAvanceFiles(projectId)
          .then((response) => {
              dispatch({
                  type: GET_FILES_SUCCESS,
                  payload: response.data,
              });
              return response;
          })
          .catch((error) => {
              dispatch({
                  type: GET_FILES_FAILURE,
                  payload: error.message,
              });
              throw error;
          });
  };
};

export const fetchFileContent = (projectId, fileName) => {
  return async (dispatch) => {
      try {
          const response = await AvanceService.getFileContent(projectId, fileName);
          return response;
      } catch (error) {
          console.error('Error fetching file content:', error);
          throw error;
      }
  };
};

export const downloadFile = (projectId, fileName) => {
  return async (dispatch) => {
      try {
          const response = await AvanceService.downloadFile(projectId, fileName);

          if (response.data instanceof Blob) {
              const blob = new Blob([response.data], { type: response.headers['content-type'] });

              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;

              document.body.appendChild(link);
              link.click();

              document.body.removeChild(link);
          } else {
              console.error('Invalid file content in the response.');
          }
      } catch (error) {
          console.error('Error downloading file:', error);
      }
  };
};

export const deleteFile = (projectId, fileName) => {
  return async (dispatch) => {
      try {
          const response = await AvanceService.deleteFile(projectId, fileName);
          return response;
      } catch (error) {
          console.error('Error deleting file:', error);
          throw error;
      }
  };
};

export const CreateAvance = (avanceData) => {
  return (dispatch) => {
    return AvanceService.addAvance(avanceData)
      .then((response) => {
        dispatch({
          type: CREATE_AVANCE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: CREATE_AVANCE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAllAvances = () => {
  return (dispatch) => {
    return AvanceService.getAllAvances()
      .then((response) => {
        dispatch({
          type: GET_ALL_AVANCES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_AVANCES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getAllPendingAvances = () => {
  return (dispatch) => {
    return AvanceService.fetchPendingAvances()
      .then((response) => {
        dispatch({
          type: GET_ALL_AVANCES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_AVANCES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateAvance = (avanceId, updatedAvance) => {
  return (dispatch) => {
    return AvanceService.updateAvance(avanceId, updatedAvance)
      .then((response) => {
        dispatch({
          type: UPDATE_AVANCE_SUCCESS,
          payload: response.data,
        });
        return response;
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_AVANCE_FAILURE,
          payload: error.message,
        });
        throw error;
      });
  };
};

export const getAvance = (avanceId) => {
  return (dispatch) => {
    return AvanceService.fetchAvance(avanceId)
      .then((response) => {
        dispatch({
          type: GET_AVANCE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_AVANCE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const deleteAvance = (avanceId) => {
  return (dispatch) => {
    return AvanceService.deleteAvance(avanceId)
      .then((response) => {
        dispatch({
          type: DELETE_AVANCE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_AVANCE_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const getProjectAdvances = (projectId) => {
  return (dispatch) => {
    return AvanceService.fetchProjectAdvnaces(projectId)
      .then((response) => {
        dispatch({
          type: GET_PROJECT_AVANCES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_PROJECT_AVANCES_FAILURE,
          payload: error.message,
        });
      });
  };
};