import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUserRequests, deleteRequest } from "../../actions/RequestActions";
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import { useMaterialUIController } from "../../context";
import MDButton from "../../components/MDButton";
import { Delete, BorderColor } from "@mui/icons-material";

const UserRequests = ({ currentUser, userId, salaire }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.request.requests);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [pageSize, setPageSize] = useState(25);


    useEffect(() => {
        dispatch(getUserRequests(userId));
    }, [dispatch, userId]);

    const openDeleteModal = (item) => {
        setSelectedItem(item);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedItem(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedItem) {
            dispatch(deleteRequest(selectedItem.id))
                .then(() => {
                    dispatch(getUserRequests(currentUser.id));
                })
                .catch((error) => {
                    console.error('Error delete Request:', error);
                });
            setSelectedItem(null);
        }
        closeDeleteModal();
    };
    const columns = [
        { field: 'prix', headerName: 'Cout', flex: 1 },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        { field: 'createdAt', headerName: 'Date de creation', flex: 1 },
        { field: 'note', headerName: 'Note', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <div style={{ overflow: 'visible !important' }}>
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Supprimer les avances sur salaires"))) && (
                            <Tooltip title='Supprimer' arrow>
                                <IconButton
                                    size='small'
                                    color='error'
                                    className="btn btn-sm"
                                    onClick={() => openDeleteModal(params.row)}
                                    style={{ marginLeft: "8px" }}
                                >
                                    <Delete fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        )}
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || (currentUser.permissions.includes("Modifier les avances sur salaires"))) && (
                            <Tooltip title='Modifier' arrow>
                                <IconButton
                                    to={`/requests/update/${params.row.id}`}
                                    component={Link}
                                    size='small'
                                    color='success'
                                    className="btn btn-sm"
                                    style={{ marginLeft: "8px" }}
                                >
                                    <BorderColor fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                </div>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <>
            <Container maxWidth="md">
                <Typography variant="body1" textAlign={"center"} mb={1} gutterBottom>
                    total des avances actuelles: {requests?.total}&nbsp;
                    <Typography component="span" variant="body2" color="success"
                        sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                        MAD
                    </Typography>
                </Typography>
                <Typography variant="body1" textAlign={"center"} mb={1} gutterBottom>
                    Salaire de mois actuelles: {salaire}&nbsp;
                    <Typography component="span" variant="body2" color="success"
                        sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                        MAD
                    </Typography>
                </Typography>
                <Typography variant="body1" textAlign={"center"} mb={5} gutterBottom>
                    Rest: {(salaire - requests?.total) || 0}&nbsp;
                    <Typography component="span" variant="body2" color="success"
                        sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}>
                        MAD
                    </Typography>
                </Typography>
                {requests?.length === 0 ? (
                    <>
                        <Typography variant="body1">No data available.</Typography>
                    </>
                ) : (
                    <Box
                        m="0 0 0 0"
                        height="90vh"
                    >
                        <DataGrid
                            rows={requests?.requests || []}
                            columns={columns}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            className={`${darkMode ? "text-light" : "text-dark"}`}
                            components={{ Toolbar: GridToolbar }}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </Box>
                )}
            </Container>
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle className={darkMode ? "text-light" : "text-dark"}>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: darkMode ? "#fff" : "#000" }}>
                        Are you sure you want to delete this holiday?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton className="btn btn-sm" onClick={closeDeleteModal}>
                        Cancel
                    </MDButton>
                    <MDButton color='error' className="btn btn-sm" onClick={handleDelete}>
                        Delete
                    </MDButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserRequests;
