import React, { useState, useEffect } from 'react';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    Autocomplete,
    MenuItem,
    Select,
    Snackbar,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject, updateProject } from '../../actions/ProjetActions';
import { getAllClients } from '../../actions/ClientActions';
import { useParams } from 'react-router-dom';
import { getAllNatures } from '../../actions/NatureActions';
import { getAllPieces } from '../../actions/PieceActions';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import { getAllCommercials } from '../../actions/ContactActions';
import { decodeId } from '../../actions/Crypte';
const UpdateProject = ({ currentUser }) => {
    const { id } = useParams();
    const projectId = decodeId(id);
    const dispatch = useDispatch();
    const commercials = useSelector((state) => state.commercial.commercials);
    const project = useSelector((state) => state.project.project);
    const clients = useSelector((state) => state.clients);
    const natures = useSelector((state) => state.nature.natures);
    const pieces = useSelector((state) => state.piece.pieces);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updatedProject, setUpdatedProject] = useState({
        reference_fonciere: '',
        situation: '',
        prix: '',
        statut: '',
        prop_dit: '',
        clientId: project?.clientId || '',
        contactId: project?.contactId || '',
        latitude: '',
        longitude: '',
        observation: '',
        facture: '',
        userId: currentUser.id,
        naturesIds: [],
        piecesIds: [],
    });

    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        dispatch(fetchProject(projectId));
        dispatch(getAllNatures());
        dispatch(getAllPieces());
        dispatch(getAllClients());
        dispatch(getAllCommercials());

        if (updateSuccess) {
            dispatch(fetchProject(projectId));
            setUpdateSuccess(false);
        }
    }, [dispatch, projectId, updateSuccess]);

    useEffect(() => {
        if (project) {
            const { geometrie, nature, pieces, ...projectData } = project;
            const latitude = geometrie && geometrie.coordinates[1] ? geometrie.coordinates[1].toString() : '';
            const longitude = geometrie && geometrie.coordinates[0] ? geometrie.coordinates[0].toString() : '';

            setUpdatedProject({
                ...projectData,
                geometrie: {
                    type: 'Point',
                    coordinates: [longitude, latitude],
                },
                latitude,
                longitude,
                naturesIds: nature?.map((nature) => nature.id),
                piecesIds: pieces?.map((piece) => piece.id),
            });
        }
    }, [project]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'latitude' || name === 'longitude') {
            setUpdatedProject((prevState) => ({
                ...prevState,
                geometrie: {
                    ...prevState.geometrie,
                    coordinates: [
                        name === 'longitude' ? parseFloat(value) : prevState.geometrie.coordinates[0],
                        name === 'latitude' ? parseFloat(value) : prevState.geometrie.coordinates[1],
                    ],
                },
                [name]: value,
            }));
        } else {
            setUpdatedProject((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleNatureChange = (event) => {
        const { value, checked } = event.target;
        setUpdatedProject((prevState) => {
            const newNaturesIds = checked
                ? [...prevState.naturesIds, parseInt(value)]
                : prevState.naturesIds.filter((id) => id !== parseInt(value));
            return {
                ...prevState,
                naturesIds: newNaturesIds,
            };
        });
    };

    const handlePiecesChange = (event) => {
        const { value, checked } = event.target;
        setUpdatedProject((prevState) => {
            const newPiecesIds = checked
                ? [...prevState.piecesIds, parseInt(value)]
                : prevState.piecesIds.filter((id) => id !== parseInt(value));
            return {
                ...prevState,
                piecesIds: newPiecesIds,
            };
        });
    };

    const handleContactChange = (event, selectedOption) => {
        setUpdatedProject((prevState) => ({
            ...prevState,
            contactId: selectedOption ? selectedOption.value : null,
        }));
    };

    const handleClientChange = (event, selectedOption) => {
        setUpdatedProject((prevState) => ({
            ...prevState,
            clientId: selectedOption ? selectedOption.value : null,
        }));
    };


    const handleUpdate = (event) => {
        event.preventDefault();

        const updatedProjectWithUserId = {
            ...updatedProject,
            userId: currentUser.id,
        };

        dispatch(updateProject(projectId, updatedProjectWithUserId))
            .then(() => {
                setUpdateSuccess(true);
                setSuccessMessage('Projet mis à jour avec succès.');
                setTimeout(() => {
                    setSuccessMessage('');
                }, 5000);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (!project || !natures || !pieces) {
        return <div>Loading...</div>;
    }

    const contactsOptions = commercials?.map((contact) => ({
        value: contact.id,
        label: contact.name,
    }));

    const clientsOptions = clients?.map((client) => ({
        value: client.id,
        label: client.full_name,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les projets")) ? (
                <Container >
                    <Typography variant="h2" textAlign="center" mb={2}>
                        Modifier le projet
                    </Typography>
                    <Typography variant="h4" textAlign="center" mb={1}>
                        {updatedProject.numero}
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center" mb={4}>
                        {updatedProject.client?.full_name}
                    </Typography>
                    {successMessage && (
                        <Snackbar
                            open={true}
                            autoHideDuration={5000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <Alert variant='filled' severity={'success'}>
                                {successMessage}
                            </Alert>
                        </Snackbar>
                    )}
                    <form onSubmit={handleUpdate}>
                        <div className="row">
                            <div className="col-md-6">
                                <TextField
                                    label="Reference Fonciere"
                                    name="reference_fonciere"
                                    value={updatedProject.reference_fonciere}
                                    onChange={handleInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                />
                                <TextField
                                    select
                                    label="Statut"
                                    name="statut"
                                    fullWidth
                                    value={updatedProject.statut}
                                    onChange={handleInputChange}
                                    sx={{ marginBottom: 3, }}
                                >
                                    <MenuItem value="" disabled>Select...</MenuItem>
                                    <MenuItem value="preparation">Preparation</MenuItem>
                                    <MenuItem value="terrain">Terrain</MenuItem>
                                    <MenuItem value="bureau">Bureau</MenuItem>
                                    <MenuItem value="controle">Controle</MenuItem>
                                    <MenuItem value="livraison">Livraison</MenuItem>
                                    <MenuItem value="cloture">Cloture</MenuItem>
                                </TextField>
                                <>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les prix des projets")) && (
                                        <TextField
                                            label="Prix"
                                            name="prix"
                                            value={updatedProject.prix}
                                            onChange={handleInputChange}
                                            fullWidth
                                            sx={{ marginBottom: 3 }}
                                        />
                                    )}
                                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                        <TextField
                                            label={`Paiement (${project?.paiement?.toUpperCase()})`}
                                            name="paiement"
                                            disabled
                                        >
                                            <MenuItem>{project?.paiement?.toUpperCase()}</MenuItem>
                                        </TextField>
                                    </FormControl>
                                    <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                        <InputLabel htmlFor="facture">Facture</InputLabel>
                                        <Select
                                            label="Facture"
                                            name="facture"
                                            value={updatedProject.facture}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="">Select...</MenuItem>
                                            <MenuItem value="oui">Oui</MenuItem>
                                            <MenuItem value="non">Non</MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            </div>
                            <div className="col-md-6">
                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier le client de projets")) && (
                                    <Autocomplete
                                        sx={{ marginBottom: '1rem' }}
                                        options={clientsOptions}
                                        value={clientsOptions?.find((option) => option.value === updatedProject?.clientId) || null}
                                        onChange={handleClientChange}
                                        isSearchable
                                        renderInput={(params) => <TextField {...params} label="Filter Contact by name..." />}
                                    />

                                )}
                                <TextField
                                    label="Situation"
                                    name="situation"
                                    value={updatedProject.situation}
                                    onChange={handleInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                />
                                <FormControl fullWidth sx={{ marginBottom: 3 }}>
                                    <Autocomplete
                                        options={contactsOptions}
                                        value={contactsOptions?.find((option) => option.value === updatedProject?.contactId) || null}
                                        onChange={handleContactChange}
                                        isSearchable
                                        renderInput={(params) => <TextField {...params} label="Filter Contact by name..." />}
                                    />
                                </FormControl>
                                <TextField
                                    label="Propriété dite"
                                    name="prop_dit"
                                    value={updatedProject.prop_dit}
                                    onChange={handleInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Latitude"
                                    name="latitude"
                                    value={updatedProject.latitude}
                                    onChange={handleInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField
                                    label="Longitude"
                                    name="longitude"
                                    value={updatedProject.longitude}
                                    onChange={handleInputChange}
                                    fullWidth
                                    sx={{ marginBottom: 3 }}
                                />
                            </div>
                            <div className="col-md-12">

                                <TextField
                                    label="Observation"
                                    name="observation"
                                    value={updatedProject.observation}
                                    onChange={handleInputChange}
                                    multiline
                                    fullWidth
                                    rows={4}
                                    sx={{ marginBottom: 3 }}
                                />
                            </div>
                            <div className="col-md-6">
                                <Typography>Natures</Typography>
                                <FormGroup>
                                    {natures && natures?.map((nature) => (
                                        <FormControlLabel
                                            key={nature.id}
                                            control={<Checkbox checked={updatedProject.naturesIds?.includes(nature.id)} />} // Set the checked prop here
                                            label={nature.name}
                                            value={nature.id}
                                            onChange={handleNatureChange}
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <Typography>Pieces</Typography>
                                <FormGroup>
                                    {pieces && pieces?.map((piece) => (
                                        <FormControlLabel
                                            key={piece.id}
                                            control={<Checkbox checked={updatedProject.piecesIds?.includes(piece.id)} />} // Set the checked prop here
                                            label={piece.piece}
                                            value={piece.id}
                                            onChange={handlePiecesChange}
                                        />
                                    ))}
                                </FormGroup>
                            </div>
                            <div className="mt-3 form-group">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#3e4396",
                                        color: "#e0e0e0",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                    }}
                                >
                                    Modifier le Projet
                                </Button>
                            </div>
                        </div>
                    </form>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout>
    );
};

export default UpdateProject;
