import React, { useState } from 'react';
import { Button, Alert, FormControl, InputLabel, MenuItem, Select, Snackbar, TextField } from '@mui/material';
import PointService from '../../services/pointServices';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';

const PointImport = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedProjection, setSelectedProjection] = useState('26191'); 
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleProjectionChange = (event) => {
        setSelectedProjection(event.target.value);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleImport = async () => {
        if (!selectedFile) {
            setSnackbarMessage('Please select a CSV file.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        const formData = new FormData();
        // formData.append('csv', selectedFile);
        formData.append('csvFile', selectedFile);
        formData.append('selectedProjection', selectedProjection);

        try {
            const response = await PointService.addPointsFromCSV(formData);
            setSnackbarMessage(response.data.message);
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Error importing points. Please check your CSV file.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="projection-label">Select Projection Zone</InputLabel>
                    <Select
                        labelId="projection-label"
                        id="projection-select"
                        value={selectedProjection}
                        onChange={handleProjectionChange}
                    >
                        <MenuItem value="26191">Zone 1</MenuItem>
                        <MenuItem value="26192">Zone 2</MenuItem>
                        <MenuItem value="26194">Zone 3</MenuItem>
                        <MenuItem value="26195">Zone 4</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="file-label">Select CSV File</InputLabel>
                    <TextField
                        type="file"
                        id="file-input"
                        onChange={handleFileChange}
                    />
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleImport}>
                    Import Points
                </Button>

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
        </DashboardLayout>
    );
};

export default PointImport;
