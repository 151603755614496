import UserServices from '../services/userSrvices';
import * as types from './types';

export const uploadUserFiles = (userId, formData) => {
  return async (dispatch) => {
    try {
      const response = await UserServices.uploadFilesToUser(userId, formData);
      
      dispatch({
        type: types.UPLOAD_USER_FILES_SUCCESS,
        payload: response.data, 
      });
    } catch (error) {
      dispatch({
        type: types.UPLOAD_USER_FILES_FAILURE,
        payload: error.response ? error.response.data : "An error occurred",
      });
      throw error; 
    }
  };
};

export const fetchUserFiles = (userId) => {
  return (dispatch) => {
    return UserServices.getAllUserFiles(userId)
      .then((response) => {
        dispatch({
          type: types.GET_USER_FILES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_USER_FILES_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const fetchFileContent = (userId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await UserServices.getFileContent(userId, fileName);
      return response; 
    } catch (error) {
      console.error('Error fetching file content:', error);
      throw error;
    }
  };
};

export const downloadFile = (userId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await UserServices.downloadFile(userId, fileName);

      // Check if the response contains file content
      if (response.data instanceof Blob) {
        // Create a Blob from the file content
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
      } else {
        console.error('Invalid file content in the response.');
      }
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
};

export const deleteFile = (userId, fileName) => {
  return async (dispatch) => {
    try {
      const response = await UserServices.deleteFile(userId, fileName);
      return response; 
    } catch (error) {
      console.error('Error deleting file:', error);
      throw error;
    }
  };
};

export const register = (userData) => (dispatch) => {
  return UserServices.register(userData).then(
    (response) => {
      dispatch({
        type: types.REGISTER_SUCCESS,
      });

      dispatch({
        type: types.SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: types.REGISTER_FAIL,
      });

      dispatch({
        type: types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {
  return UserServices.login(username, password).then(
    (data) => {
      const token = data.accessToken;
      const timestamp = new Date().getTime();
      localStorage.setItem("token", token);
      localStorage.setItem("tokenTimestamp", timestamp);
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: types.LOGIN_FAIL,
      });

      dispatch({
        type: types.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

function isTokenExpired() {
  const tokenTimestamp = localStorage.getItem("tokenTimestamp");
  if (tokenTimestamp) {
    const currentTime = new Date().getTime();
    return currentTime - parseInt(tokenTimestamp, 10) > 7200000; // 7200000 milliseconds (2 hours)
  }
  return true; // No token or token timestamp found, consider it expired
}

export const logout = () => (dispatch) => {
  UserServices.logout();

  dispatch({
    type: types.LOGOUT,
  });
};

export const getAllUsers = () => (dispatch) => {
  return UserServices.getAllUsers().then(
    (response) => {
      dispatch({
        type: types.GET_ALL_USERS,
        payload: response.data,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: types.GET_ALL_USERS,
        payload: message,
      });
    }
  );
};

export const deleteUser = (id) => (dispatch) => {
  return UserServices.deleteUser(id).then(
    (response) => {
      dispatch({
        type: types.SET_MESSAGE,
        payload: response.data.message,
      });
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: types.SET_MESSAGE,
        payload: message,
      });
    }
  );
};

export const getUser = (id) => (dispatch) => {
  return UserServices.getUser(id)
    .then((response) => {
      dispatch({
        type: types.ONE_USER,
        payload: response.data,
      });
    })
    .catch((error) => {
      // Handle error if needed
      console.log(error);
    });
};

export const updateUserPermissions = (id, permissionIds) => {
  return (dispatch) => {
    return UserServices.updateUserPermissions(id, permissionIds)
      .then((response) => {
        dispatch({
          type: types.UPDATE_USER_PERMISSION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_USER_PERMISSION_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateUser = (userId, Data) => {
  return (dispatch) => {
    return UserServices.updateUser(userId, Data)
      .then((response) => {
        dispatch({
          type: types.UPDATE_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_USER_FAILURE,
          payload: error.message,
        });
      });
  };
};

export const updateUserPasswordSuccess = (data) => ({
  type: types.UPDATE_USER_PASSWORD_SUCCESS,
  payload: data
});

export const updateUserPasswordFailure = (error) => ({
  type: types.UPDATE_USER_PASSWORD_FAILURE,
  payload: error
});

export const updateUserPassword = (id, data) => {
  return (dispatch) => {
    return UserServices.updateUserPassword(id, data)
      .then((response) => {
        dispatch(updateUserPasswordSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateUserPasswordFailure(error.message));
      });
  };
};
