import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser, updateUser } from "../../actions/UserActions";
import { getAllRoles } from "../../actions/RolesActions";
import { useParams } from "react-router-dom";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import {
  Container, Typography, Grid, TextField, MenuItem, Snackbar, Select, Alert, FormControl,
  InputLabel,
} from '@mui/material';
import MDButton from "../../components/MDButton";

const UpdateUser = ({ currentUser }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [cin, setCin] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [contart, setContrat] = useState("");
  const [situation, setSituation] = useState("");
  const [nbEnf, setNbEnf] = useState("");
  const [declaration, setDeclaration] = useState("");
  const [ncnss, setNcnss] = useState("");
  const [rib, setRib] = useState("");
  const [dateRecrutement, setDateRecrutement] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephone, setTelephone] = useState("");
  const [salaire, setSalaire] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const user = useSelector((state) => state.users.user);
  const roles = useSelector((state) => state.users.roles);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    dispatch(getUser(id));
    dispatch(getAllRoles());
  }, [dispatch, id]);

  useEffect(() => {
    if (user) {
      setUsername(user?.username);
      setEmail(user?.email);
      setCin(user?.cin);
      setNom(user?.nom);
      setDateRecrutement(user?.date_recrutement)
      setPrenom(user?.prenom);
      setContrat(user?.contart);
      setAdresse(user?.adresse);
      setTelephone(user?.telephone);
      setSalaire(user?.salaire);
      setSituation(user?.situation_fam);
      setNbEnf(user?.nb_enf);
      setDeclaration(user?.decraltion_cnss);
      setNcnss(user?.ncnss);
      setRib(user?.rib);
      setSelectedRoles(user.roles);
    }
  }, [user]);

  const handleUpdate = () => {
    const updatedUser = {
      id: id,
      username,
      cin,
      email,
      nom,
      prenom,
      contart,
      date_recrutement: dateRecrutement,
      adresse,
      telephone,
      situation_fam: situation || '',
      nb_enf: nbEnf || '',
      decraltion_cnss: declaration || '',
      ncnss,
      rib,
      salaire,
      roles: selectedRoles?.map((role) => role.id),
    };

    dispatch(updateUser(id, updatedUser))
      .then(() => {
        openSnackbar("User updated successfully", "success");
      })
      .catch((error) => {
        openSnackbar("Failed to update user", "error");
        console.log(error);
      });
  };

  const openSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log('selectedRoles', selectedRoles);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les personnel")) ? (
        <Container maxWidth="md">
          {user ? (
            <div>
              <Typography variant="h5" component="div" align="center" mt={3}>
                Mettre à jour l'utilisateur
              </Typography>
              <Typography variant="h6" component="div" align="center" mt={1} mb={3}>
                {nom} {prenom}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Username"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="CIN"
                    fullWidth
                    value={cin}
                    onChange={(e) => setCin(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nom"
                    fullWidth
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Prenom"
                    fullWidth
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address"
                    fullWidth
                    value={adresse}
                    onChange={(e) => setAdresse(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Telephone"
                    fullWidth
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Salair"
                    fullWidth
                    value={salaire}
                    onChange={(e) => setSalaire(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="RIB"
                    fullWidth
                    value={rib}
                    onChange={(e) => setRib(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                    <InputLabel id="contrat-label">Contrat</InputLabel>
                    <Select
                      labelId="contrat-label"
                      name="contrat"
                      value={contart}
                      onChange={(e) => setContrat(e.target.value)}
                    >
                      <MenuItem value="CDI">CDI</MenuItem>
                      <MenuItem value="CDD">CDD</MenuItem>
                      <MenuItem value="Contrat Projet">Contrat Projet</MenuItem>
                      <MenuItem value="Contrat Stage">Contrat Stage</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Date Recrutment"
                    type="date"
                    fullWidth
                    value={dateRecrutement}
                    onChange={(e) => setDateRecrutement(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                    <InputLabel id="declaration-label">Declaration CNSS</InputLabel>
                    <Select
                      labelId="declaration-label"
                      name="declaration"
                      value={declaration}
                      onChange={(e) => setDeclaration(e.target.value)}
                    >
                      <MenuItem value="oui">Oui</MenuItem>
                      <MenuItem value="non">Non</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {declaration === 'oui' && (
                  <Grid item xs={6}>
                    <TextField
                      label="Numero CNSS"
                      fullWidth
                      value={ncnss}
                      onChange={(e) => setNcnss(e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ '& .MuiSelect-select': { padding: '14px' } }}>
                    <InputLabel id="situation-label">Situation Fa</InputLabel>
                    <Select
                      labelId="situation-label"
                      name="situation"
                      value={situation}
                      onChange={(e) => setSituation(e.target.value)}
                    >
                      <MenuItem value="celibataire">Célibataire</MenuItem>
                      <MenuItem value="marie">Marié(e)</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {situation === 'marie' && (
                  <Grid item xs={6}>
                    <TextField
                      label="Nombre d'enfants"
                      fullWidth
                      value={nbEnf}
                      onChange={(e) => setNbEnf(e.target.value)}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <label className="form-label fw-bolder">Role:</label>
                  <select
                    className="form-control"
                    multiple
                    value={selectedRoles?.map((role) => role.id)}
                    onChange={(e) => {
                      const selectedRoleIds = Array.from(e.target.selectedOptions, (option) => option.value);
                      const updatedRoles = roles?.filter((role) => selectedRoleIds.includes(String(role.id)));
                      setSelectedRoles(updatedRoles);
                    }}
                  >
                    {roles?.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>

                </Grid>
              </Grid>
              <MDButton
                variant="contained"
                color="info"
                className='mt-4'
                // fullWidth
                mt={2}
                onClick={handleUpdate}
              >
                Update
              </MDButton>

            </div>
          ) : (
            <Typography>Loading user data...</Typography>
          )}
        </Container>
      ) : (
        <AccessDenied />
      )}
      {/* {snackbarMessage && ( */}
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {/* )} */}

    </DashboardLayout>
  );
};

export default UpdateUser;
