import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRequest, getRequest } from "../../actions/RequestActions";
import {
    Container,
    Typography,
    TextField,
    Button,
    MenuItem,
    Box,
    Snackbar,
} from "@mui/material";
import { Alert } from "@mui/material";
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import { useParams } from 'react-router-dom';
import MDButton from "../../components/MDButton";

const UpdateRequest = ({ currentUser }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const request = useSelector(state => state.request.request);

    const [type, setType] = useState("");
    const [mois, setMois] = useState("");
    const [cout, setCout] = useState("");
    const [note, setNote] = useState("");

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    console.log(request);
    useEffect(() => {
        dispatch(getRequest(id));
    }, [dispatch, id]); 

    useEffect(() => {
        if (request) {
            setType(request?.type);
            setMois(formatDateForMonthPicker(request?.mois));
            setCout(request?.prix);
            setNote(request?.note);
            // setPrenom(user.prenom);
            // setAdresse(user.adresse);
            // setTelephone(user.telephone);
        }
    }, [request]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedRequestData = {
            type,
            mois,
            prix: cout,
            note,
            userId: currentUser.id,
        };

        try {
            await dispatch(updateRequest(id, updatedRequestData));
            setSnackbarMessage("Request updated successfully.");
            setSnackbarSeverity("success");
            setOpenSnackbar(true);
        } catch (error) {
            setSnackbarMessage("Error updating Request.");
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
            console.error("Error updating Request:", error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const formatDateForMonthPicker = (dateString) => {
        if (!dateString) return "";
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        return `${year}-${month}`;
    };
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="sm">
                <Box p={3}>
                    <Typography variant="h2" align="center" gutterBottom>
                        Update Request
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <TextField
                                select
                                label="Designation"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                variant="outlined"
                                fullWidth
                                required
                            >
                                <MenuItem value="Avance Sur Salaire">
                                    Avance Sur Salaire
                                </MenuItem>
                                <MenuItem value="Prime">Prime</MenuItem>
                                <MenuItem value="Indemnité">Indemnité</MenuItem>
                                <MenuItem value="Request sur Vehicule">
                                    Request sur Vehicule
                                </MenuItem>
                            </TextField>

                            {(type === 'Avance Sur Salaire' || type === 'Prime' || type === 'Indemnité') && (
                                <>
                                    <TextField
                                        label="Mois"
                                        type="month"
                                        value={mois}
                                        onChange={(e) => setMois(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        label="Cout"
                                        type="number"
                                        value={cout}
                                        onChange={(e) => setCout(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                </>
                            )}

                            <TextField
                                fullWidth
                                label="Note"
                                multiline
                                rows={5}
                                variant="outlined"
                                margin="normal"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                            <MDButton
                                type="submit"
                                variant="contained"
                                color="info"
                                // fullWidth
                                sx={{ mt: 3 }}
                            >
                                Update Request
                            </MDButton>
                        </div>
                    </form>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{ mt: 2 }}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </DashboardLayout>
    );
};

export default UpdateRequest;
