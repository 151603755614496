import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllPieces, deletePieces } from '../../actions/PieceActions';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Pagination,
  Typography,
  Box,
  Alert,
  Snackbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import AccessDenied from '../Errors/AccessDenied';
const Pieces = ({ currentUser }) => {
  const dispatch = useDispatch();
  const pieces = useSelector((state) => state.piece.pieces);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    dispatch(getAllPieces());
  }, [dispatch]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedNature, setSelectedNature] = useState(null);

  const handleOpenModal = (nature) => {
    setSelectedNature(nature);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setSelectedNature(null);
    setOpenModal(false);
  };

  const handleDelete = () => {
    if (selectedNature) {
      dispatch(deletePieces(selectedNature.id))
        .then(() => {
          setSnackbarMessage('Nature Deleted successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        })
        .catch((error) => {
          setSnackbarMessage('Failed to delete nature');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
      handleCloseModal();
      dispatch(getAllPieces());
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Pagination
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les pièces")) ? (
        <>
          <h2 className='text-center'>Liste des pièces</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Pièce</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pieces.slice(startIndex, endIndex).map((nature) => (
                  <TableRow key={nature.id}>
                    <TableCell>{nature.piece}</TableCell>
                    <TableCell>
                      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les pièces")) && (
                        <Button variant="contained" size="small" component={Link} to={`/pieces/update/${nature.id}`} color="success">
                          Modifier
                        </Button>
                      )}
                      {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les pièces")) && (
                        <IconButton variant="contained" size="small" color='error' onClick={() => handleOpenModal(nature)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination
            count={Math.ceil(pieces.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
            color="primary"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
          />

          {/* Delete Modal */}
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                  <Typography variant="h6" gutterBottom>
                    Confirmation de la suppression
                  </Typography>
                  <Typography paragraph>
                    Êtes-vous sûr de vouloir supprimer cette pièce ?
                  </Typography>
                  <MDButton onClick={handleDelete} variant="contained" color="error" style={{ marginRight: '10px' }}>
                    Supprimer
                  </MDButton>
                  <MDButton onClick={handleCloseModal} variant="contained" color="white">
                    Annuler
                  </MDButton>
                </Paper>
              </Box>
            </Fade>
          </Modal>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </>
      ) : (
        <>
          <AccessDenied />
        </>
      )}
    </DashboardLayout>
  );
};

export default Pieces;
