import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjectsByStatus } from '../../actions/ProjetActions';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { encodeId } from '../../actions/Crypte';
import ProjectsReview from './ProjectsReview';

const ProjectsByStatus = ({ currentUser, darkMode }) => {
    const { statut } = useParams();
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch();
    const projectsByStatus = useSelector((state) => state.project.projectsByStatus);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(fetchProjectsByStatus(statut));
    }, [dispatch, statut]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const filteredProjects = projectsByStatus.filter((project) => {
            const reference = project.reference_fonciere || '';
            const numero = project.numero || '';
            const client = project.client?.full_name || '';

            return (
                reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredProjects(filteredProjects);
    }, [projectsByStatus, searchTerm]);

    const openDialog = (project) => {
        setSelectedProject(project);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedProject(null);
        setIsDialogOpen(false);
    };
    let projectStatut = '';

    switch (statut) {
        case 'preparation':
            projectStatut = 'Préparation';
            break;
        case 'terrain':
            projectStatut = 'Terrain';
            break;
        case 'bureau':
            projectStatut = 'Bureau';
            break;
        case 'controle':
            projectStatut = 'Contrôle';
            break;
        default:
            projectStatut = '-';
    }

    const columns = [
        { field: 'numero', headerName: 'Numero', flex: 1 },
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.client?.full_name || '',
        },
        {
            field: 'reference_fonciere', headerName: 'Reference',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'date_creation', headerName: 'Created At',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'situation', headerName: 'Situation',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },

        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <MDButton
                        className='me-1 ms-1'
                        size='small'
                        color='warning'
                        onClick={() => openDialog(params.row)}>
                        <OpenInFullIcon />
                    </MDButton>
                    <MDButton
                        component={Link}
                        className='me-1 ms-1'
                        size='small'
                        color='success'
                        to={`/projects/project/${encodeId(params.id)}`}>
                        <VisibilityIcon />
                    </MDButton>

                    <MDButton
                        component={Link}
                        className='mS-1'
                        size='small'
                        color='info'
                        to={`/projects/update/${encodeId(params.id)}`}
                    >
                        <BorderColorIcon />
                    </MDButton>

                </>
            ),
            flex: 2,
            cellClassName: 'actions-cell',
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography
                sx={{
                    fontSize: '36px',
                    textAlign: 'center',
                    marginTop: '12px',
                    fontWeight: 'bold',
                    color: '#3e4396',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
                variant="h2"
                gutterBottom
            >
                Project : {projectStatut.toLocaleUpperCase()}
            </Typography>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
                <Box>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Search Project"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: "leaderboard",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "secondary",
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "primary",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: "secondary",
                            },
                            "& .MuiCheckbox-root": {
                                color: `leaderboard !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `primary !important`,
                            },
                        }}
                    >
                        <DataGrid
                            rows={filteredProjects}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            style={{ color: darkMode ? "#fff" : "#000" }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}

            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle textAlign={'center'}>Détails de projet</DialogTitle>
                <DialogContent>
                    {selectedProject && (
                        <ProjectsReview project={selectedProject} currentUser={currentUser} darkMode={darkMode} />

                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </DashboardLayout>
    );
};

export default ProjectsByStatus;

