import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MDBox from "./components/MDBox";
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";
import theme from "./assets/theme";
import themeDark from "./assets/theme-dark";
import "bootstrap/dist/js/bootstrap.min.js";
import './App.css';
import rtlPlugin from "stylis-plugin-rtl";
import { SnackbarProvider } from 'notistack';
import createCache from "@emotion/cache";
import routes from "./routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "./context";
import logoWhite from "./assets/images/1.png";
import logoDark from "./assets/images/2.png";
import SettingsIcon from '@mui/icons-material/Settings';
import SignIn from "./layouts/authentication/sign-in";
import SignUp from "./layouts/authentication/sign-up";
import { useDispatch, useSelector } from "react-redux";
import Dashboard from "./layouts/dashboard";
import AddClient from "./pages/clients/CreateClient";
import ClientsList from "./pages/clients/Clients";
import UpdateClient from "./pages/clients/UpdateClient"
import CreateNature from "./pages/narures/CreateNature";
import Natures from "./pages/narures/Natures";
import CreatePiece from "./pages/pieces/CreatePiece";
import Pieces from "./pages/pieces/Pieces";
import CreateContact from "./pages/contact/CreateContact";
import Contacts from "./pages/contact/Contacts";
import CreateProjeect from "./pages/projet/CreateProjeect";
import UpdateProject from "./pages/projet/UpdateProject";
import ProjectList from "./pages/projet/Projects";
import AddEncaissement from "./pages/encaissements/CreateEncaissement";
import AvanceList from "./pages/encaissements/Encaissements";
import UpdateAvance from "./pages/encaissements/UpdateEncaissement";
import AddUser from "./pages/personnel/CreatePersonnel";
import ProjectsByStatus from "./pages/projet/ProjectsByStatut";
import CreatePermission from "./pages/permissions/CreatePermission";
import Personnels from "./pages/personnel/Personnels";
import PersonnelInfo from "./pages/personnel/PersonnelInformation";
import PersonnelPermissions from "./pages/personnel/PersonnelPermission";
import MapOfProjects from "./pages/projet/MapOfProjects";
import ClientProjects from "./pages/projet/ClientProjects";
import ProjectInfo from "./pages/projet/ProjectInfo";
import UserPendingTasksList from "./pages/taches/PendingTachesOfUsers";
import CreateFacture from "./pages/factures/CreateFacture";
import FactureList from "./pages/factures/Factures";
import UpdateFacture from "./pages/factures/UpdateFacture";
import CreateDevis from "./pages/devis/CreateDevis";
import Quotes from "./pages/devis/Devis";
import UpdateDevis from "./pages/devis/UpdateDevis";
import AddVehicule from "./pages/vehicule/AddVehicule";
import VehiculesList from "./pages/vehicule/Vehicules";
import UpdateVehicule from "./pages/vehicule/UpdateVehicule";
import MaterialsTables from "./pages/tables/Materials";
import CreateCharge from "./pages/charges/CreateCharges";
import Charges from "./pages/charges/Charges";
import UpdateCharge from "./pages/charges/UpdateCharges";
import CreateMaterial from "./pages/mateirals/CreateMaterials";
import MaterialList from "./pages/mateirals/Materials";
import UpdateMaterial from "./pages/mateirals/UpdateMaterial";
import FinanceTables from "./pages/tables/Finance";
import SendHolidayRequest from "./pages/holidays/SendHolidayRequest";
import PendingHolidaysList from "./pages/holidays/PendingHolidaysRaquests";
import HolidaysList from "./pages/holidays/Holidays";
import UpdateHoliday from "./pages/holidays/UpdateHoliday";
import UserHolidaysList from "./pages/holidays/UserHolidays";
import PendingCharges from "./pages/charges/PendingCharges";
import AmountByPaiementAndMonth from "./pages/statistics/AmountByPaiementAndMonth";
import ProjectsAmountByFacture from "./pages/statistics/ProjectsAmountByFacture";
import AmountByFactureAndMonth from "./pages/statistics/AmountByFactureAndMonth";
import ProjectsAmountByMonth from "./pages/statistics/ProjectsAmountByMonth";
import ProjectsAmountByPaiement from "./pages/statistics/ProjectsAmount";
import TasksList from "./pages/taches/Taches";
import CreateTache from "./pages/taches/CreateTask";
import Profile from "./pages/profile/Profile";
import UpdateUser from "./pages/personnel/UpdatePersonnel";
import CreatePrime from "./pages/primes/CreatePrime";
import PrimesList from "./pages/primes/Primes";
import UpdatePrime from "./pages/primes/UpdatePrimes";
import CreateConstant from "./pages/constant/CreateConstant";
import CreateCotisation from "./pages/cotisation/CreateCotisation";
import CreateTaux from "./pages/taux/CreateTaux";
import DataTables from "./pages/tables/Tables";
import Statistics from "./pages/tables/Statistics";
import UpdateUserPrimes from "./pages/primes/UpdateUserPrimes";
import AddPrimesToUsers from "./pages/primes/SetPrimeToUser";
import CotisationsList from "./pages/cotisation/Cotisations";
import ChargesByUser from "./pages/charges/UserCharges";
import HumainsTables from "./pages/tables/RessourcesHumaines";
import PendingAvanceList from "./pages/encaissements/PendingAvances";
import SendRequest from "./pages/requests/SendRequest";
import RequestsList from "./pages/requests/Requests";
import PendingRequestsList from "./pages/requests/RequestsPendingList";
import AdminNotification from "./pages/notifications/AdminNotifications";
import UpdateRequest from "./pages/requests/UpdateRequest";
import UserRequests from "./pages/requests/UserRequests";
import { logout } from "./actions/UserActions";
import { isTokenExpired } from './actions/authUtils';
import AddStagiaire from "./pages/stagiaires/AddStagiaires";
import StagairesList from "./pages/stagiaires/Stagaires"
import UpdateStagiaire from "./pages/stagiaires/UpdateStagiaire";
import ChargesByDesignation from "./pages/statistics/ChargesByDesingnation";
import TotalChargesByMonthAndYear from "./pages/statistics/TotalCharges";
import TotalChargesForVehicles from "./pages/statistics/TotalChargesForVehicles";
import TotalChargesForEveryVehicle from "./pages/statistics/TotalChargesForEveryVehicle";
import TotalChargesForUsers from "./pages/statistics/TotalChargesForUsers";
import TotalChargesByYear from "./pages/statistics/TotalChargesByYear";
import RevenueByContact from "./pages/statistics/RevenueByContact";
import Top10ClientsByAmount from "./pages/statistics/Top10ClientsByAmount";
import UserPerformanceIndex from "./pages/statistics/UserPerformanceIndex";
import Settings from "./pages/tables/Settings";
import TauxList from "./pages/taux/TauxList";
import UserInfo from "./pages/personnel/UserInfo";
import ProjectsMap from "./pages/survey/AddProjectPolygon";
import Bureau from "./pages/tables/Bureau";
import AddPolygon from "./pages/geometry/AddPolygon";
import ProjectPolygons from "./pages/geometry/ShowPolygon";
import AddPolline from "./pages/geometry/AddPolyLine";
import PointImport from "./pages/geometry/ImportPointsFromCsv";
import PointsMap from "./pages/geometry/PointsRat";
import AddCharge from "./pages/charges/AddCharge";
import MaterialsByType from "./pages/mateirals/MaterialsByType";
import AddOperation from "./pages/operations/AddOperations";
import OperationsList from "./pages/operations/Operations";
import UpdateOperation from "./pages/operations/UpdateOperation";
import EditCharge from "./pages/charges/EditCharge";
import CreateUserRequest from "./pages/requests/CreateRequest";
import UsersSchedule from "./pages/taches/Programme";
import ProjectsStatistics from "./pages/analyses/projects/Index";
import ChargesStatistics from "./pages/analyses/charges/Index";
import RecettesStatistics from "./pages/analyses/recettes/Index";
import RecouvrementStatistics from "./pages/analyses/recouvrement/Index";
import PerformanceStatistics from "./pages/analyses/performance/Index";
import UserTasks from "./pages/taches/UserTasks";
import SalaireList from "./pages/salaires/Salaires";
import Notifications from "./pages/notifications/Index";
import NotFound from "./pages/Errors/NotFound";
import Login from "./pages/auth/Login";
import SurveyMap from "./pages/survey/SurverMap";

export default function App() {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const dispatch1 = useDispatch();
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired()) {
        dispatch1(logout());
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 60000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <SettingsIcon fontSize="small" color="inherit">
        settings
      </SettingsIcon>
    </MDBox>
  );

  return (
    <>
      <SnackbarProvider>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {currentUser ? (
            <>
              {layout === "dashboard" && (
                <>
                  <Sidenav
                    color={sidenavColor}
                    currentUser={currentUser}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? logoWhite : logoDark}
                    brandName="SysManager"
                    routes={routes(currentUser.roles, currentUser.permissions)}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                  />
                  <Configurator />
                  {configsButton}
                </>
              )}
              {layout === "vr" && <Configurator currentUser={currentUser} />}
              <Routes>
                <Route path="/login" element={<Dashboard />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/tables" element={<DataTables currentUser={currentUser}/>} />
                <Route path="/ressources-humaines" element={<HumainsTables currentUser={currentUser} />} />
                <Route path="/profile" element={<Profile currentUser={currentUser} />} />
                {/* ------------------------- clients ------------------------- */}

                <Route path="/clients/create" element={<AddClient currentUser={currentUser}/>} />
                <Route path="/clients" element={<ClientsList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/clients/update/:id" element={<UpdateClient currentUser={currentUser}/>} />

                <Route path="/projects/natures/create" element={<CreateNature currentUser={currentUser}/>} />
                <Route path="/projects/natures" element={<Natures currentUser={currentUser}/>} />
                <Route path="/projects/pieces/create" element={<CreatePiece currentUser={currentUser}/>} />
                <Route path="/projects/pieces" element={<Pieces currentUser={currentUser} />} />
                <Route path="/contacts/create" element={<CreateContact currentUser={currentUser} />} />
                <Route path="/contacts" element={<Contacts currentUser={currentUser} />} />
                <Route path="/projects/create" element={<CreateProjeect currentUser={currentUser}/>} />
                <Route path="/projects" element={<ProjectList currentUser={currentUser} />} />
                <Route path="/projects/locations" element={<MapOfProjects />} />
                {/* <Route path="/projects/locations" element={<SurveyMap defaultZone={'26192'} />} /> */}
                <Route path="/projects/project/:id" element={<ProjectInfo currentUser={currentUser} />} />
                <Route path="/projects/client/:id" element={<ClientProjects currentUser={currentUser} />} />
                <Route path="/projects/taches" element={<UserPendingTasksList userId={currentUser.id} />} />
                <Route path="/projects/update/:id" element={<UpdateProject currentUser={currentUser} />} />
                <Route path="/projects/avances/create" element={<AddEncaissement currentUser={currentUser} />} />
                <Route path="/projects/avances/update/:id" element={<UpdateAvance currentUser={currentUser} />} />
                <Route path="/projects/avances" element={<AvanceList currentUser={currentUser} darkMode={darkMode} />} />
                {/* <Route path="/projects/history/tasks" element={<TasksList currentUser={currentUser} darkMode={darkMode} />} /> */}
                <Route path="/tasks/create" element={<CreateTache currentUser={currentUser} />} />
                {/* <Route path="/tasks/history" element={<UserTasks currentUser={currentUser} darkMode={darkMode} />} /> */}
                <Route
                  path="/projects/history/tasks"
                  element={
                    (currentUser && currentUser.roles.includes("ROLE_ADMIN") 
                    || currentUser.permissions.includes("Consulter les tâches")
                    ) ? (
                      <TasksList currentUser={currentUser} darkMode={darkMode} />
                    ) : (
                      <UserTasks currentUser={currentUser} darkMode={darkMode} />
                    )
                  }
                />

                <Route path="/ressources-humaines/personnel/create" element={<AddUser currentUser={currentUser} />} />
                <Route path="/projects/statut/:statut" element={<ProjectsByStatus currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/ressources-humaines/personnel/permissions/create" element={<CreatePermission />} />
                <Route path="/ressources-humaines/personnels/update/:id" element={<UpdateUser currentUser={currentUser} />} />
                <Route path="/ressources-humaines/personnel" element={<Personnels currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/ressources-humaines/personnel/user/info/:id" element={<UserInfo currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/ressources-humaines/personnel/user/:id" element={<PersonnelInfo currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/ressources-humaines/personnel/user/update/permessions/:id" element={<PersonnelPermissions currentUser={currentUser} />} />
                <Route path="/ressources-humaines/holidays/send/request" element={<SendHolidayRequest currentUser={currentUser} />} />
                <Route path="/ressources-humaines/holidays/pending/requests" element={<PendingHolidaysList currentUser={currentUser} />} />
                <Route path="/ressources-humaines/holidays" element={<HolidaysList currentUser={currentUser} />} />
                <Route path="/ressources-humaines/holidays/update/:id" element={<UpdateHoliday currentUser={currentUser} />} />
                <Route path="/ressources-humaines/holidays/user" element={<UserHolidaysList currentUser={currentUser} />} />
                <Route path="/ressources-humaines/satagiaires" element={<StagairesList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/ressources-humaines/satagiaires/ajouter" element={<AddStagiaire currentUser={currentUser} />} />
                <Route path="/ressources-humaines/satagiaires/update/:id" element={<UpdateStagiaire currentUser={currentUser} />} />

                {/* -------------------------- moyens-materiels ------------------------- */}
                <Route path="/operations-bancaire/create" element={<AddOperation currentUser={currentUser} />} />
                <Route path="/operations-bancaire" element={<OperationsList currentUser={currentUser} />} />
                <Route path="/operations-bancaire/update/:id" element={<UpdateOperation currentUser={currentUser} />} />

                {/* -------------------------- moyens-materiels ------------------------- */}
                <Route path="/moyens-materiels/materiels/create" element={<CreateMaterial currentUser={currentUser} />} />
                <Route path="/moyens-materiels" element={<MaterialsTables currentUser={currentUser} />} />
                <Route path="/moyens-materiels/materiels/:type" element={<MaterialsByType currentUser={currentUser} />} />
                <Route path="/moyens-materiels/vehicules/add" element={<AddVehicule currentUser={currentUser} />} />
                <Route path="/moyens-materiels/vehicules" element={<VehiculesList currentUser={currentUser} />} />
                <Route path="/moyens-materiels/vehicules/update/:id" element={<UpdateVehicule currentUser={currentUser} />} />
                <Route path="/moyens-materiels/materiels" element={<MaterialList currentUser={currentUser} />} />
                <Route path="/moyens-materiels/materiels/update/:id" element={<UpdateMaterial currentUser={currentUser} />} />

                {/* -------------------------- moyens-materiels ------------------------- */}

                <Route path="/bureau" element={<Bureau darkMode={darkMode} currentUser={currentUser}/>} />
                <Route path="/projects/survey/add/polygon" element={<AddPolygon currentUser={currentUser} />} />
                <Route path="/projects/survey/add/polyline" element={<AddPolline currentUser={currentUser} />} />
                <Route path="/projects/survey/import/points" element={<PointImport currentUser={currentUser} />} />
                <Route path="/test/map" element={<PointsMap zone={'26192'} />} />
                <Route path="/test" element={<SurveyMap defaultZone={'26192'} />} />
                {/* <Route path="/test" element={<ProjectPolygons />} /> */}
                <Route path="/schedule" element={<UsersSchedule userId={currentUser?.id} />} />

                {/* -------------------------- Survey ------------------------- */}
                <Route path="/map" element={<ProjectsMap currentUser={currentUser} />} />
                {/* --------------------------- Requests ------------------------- */}
                <Route path="/demandes/envoyer/avance-salaire" element={<CreateUserRequest currentUser={currentUser} />} />

                {/* -------------------------- Finances ------------------------- */}
                <Route path="/finances" element={<FinanceTables currentUser={currentUser} />} />
                <Route path={`/charges/user/${currentUser.nom}`} element={<ChargesByUser currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/charges/create" element={<CreateCharge currentUser={currentUser} />} />
                <Route path="/charges/ajouter" element={<AddCharge currentUser={currentUser} />} />
                <Route path="/charges" element={<Charges currentUser={currentUser} />} />
                <Route path="/charges/pendinglist" element={<PendingCharges currentUser={currentUser} />} />
                <Route path="/charges/update/:id" element={<UpdateCharge currentUser={currentUser} />} />
                <Route path="/charges/modifier/:id" element={<EditCharge currentUser={currentUser} />} />

                <Route path="/finances/projects/avances/pendinglist" element={<PendingAvanceList currentUser={currentUser} />} />
                <Route path="/finances/factures/create" element={<CreateFacture currentUser={currentUser} />} />
                <Route path="/finances/factures" element={<FactureList currentUser={currentUser} />} />
                <Route path="/finances/factures/update/:id" element={<UpdateFacture currentUser={currentUser} />} />
                <Route path="/finances/devis/create" element={<CreateDevis currentUser={currentUser} />} />
                <Route path="/finances/devis" element={<Quotes currentUser={currentUser} />} />
                <Route path="/finances/devis/update/:id" element={<UpdateDevis currentUser={currentUser} />} />
                <Route path="/finances/salaires/create" element={<CreateCotisation currentUser={currentUser} darkMode={darkMode} />} />
                {/* <Route path="/finances/salaires" element={<CotisationsList currentUser={currentUser} darkMode={darkMode} />} /> */}
                <Route path="/finances/salaires" element={<SalaireList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/finances/primes/create" element={<CreatePrime currentUser={currentUser} />} />
                <Route path="/finances/primes" element={<PrimesList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/finances/primes/update/:id" element={<UpdatePrime currentUser={currentUser} />} />
                <Route path="/finances/projects/avances/create" element={<AddEncaissement currentUser={currentUser} />} />
                <Route path="/finances/projects/avances/update/:id" element={<UpdateAvance currentUser={currentUser} />} />
                <Route path="/finances/projects/avances" element={<AvanceList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/finances/taux/list" element={<TauxList currentUser={currentUser} darkMode={darkMode} />} />

                {/* -------------------------- Primes ------------------------- */}

                <Route path="/primes/create" element={<CreatePrime currentUser={currentUser} />} />
                <Route path="/primes" element={<PrimesList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/primes/update/:id" element={<UpdatePrime currentUser={currentUser} />} />
                <Route path="/constant/indemnites/create" element={<CreateConstant currentUser={currentUser} />} />
                <Route path="/salaires/create" element={<CreateCotisation currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/salaires" element={<CotisationsList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/taux/create" element={<CreateTaux currentUser={currentUser} />} />
                {/* <Route path="/primes/addto/user/:userId" element={<SetNewPrimesToUser currentUser={currentUser} />} /> */}
                <Route path="/primes/update/user/:userId" element={<UpdateUserPrimes currentUser={currentUser} />} />
                <Route path="/primes/set" element={<AddPrimesToUsers currentUser={currentUser} />} />
                <Route path="/projects/avances/create" element={<AddEncaissement currentUser={currentUser} />} />
                <Route path="/projects/avances/update/:id" element={<UpdateAvance currentUser={currentUser} />} />
                <Route path="/projects/avances" element={<AvanceList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/requests/send" element={<SendRequest currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/requests" element={<RequestsList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/requests/pendingList" element={<PendingRequestsList currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/requests/update/:id" element={<UpdateRequest currentUser={currentUser} darkMode={darkMode} />} />
                <Route path={`/requests/user/${currentUser.nom}`} element={<UserRequests currentUser={currentUser} darkMode={darkMode} />} />

                {/* --------------------------- statistics ------------------------- */}
                <Route path="/statistics/projects/AmountByPaiementAndMonth" element={<AmountByPaiementAndMonth currentUser={currentUser} />} />
                <Route path="/statistics/projects/ProjectsAmountByFacture" element={<ProjectsAmountByFacture currentUser={currentUser} />} />
                <Route path="/statistics/projects/AmountByFactureAndMonth" element={<AmountByFactureAndMonth currentUser={currentUser} />} />
                <Route path="/statistics/projects/ProjectsAmountByMonth" element={<ProjectsAmountByMonth currentUser={currentUser} />} />
                <Route path="/statistics/projects/ProjectsAmountByPaiement" element={<ProjectsAmountByPaiement currentUser={currentUser} />} />
                <Route path="/statistics" element={<Statistics currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="/statistics/charges/chargesByDesignation" element={<ChargesByDesignation currentUser={currentUser} />} />
                <Route path="/statistics/charges/totale" element={<TotalChargesByMonthAndYear currentUser={currentUser} />} />
                <Route path="/statistics/charges/forVihecule" element={<TotalChargesForVehicles currentUser={currentUser} />} />
                <Route path="/statistics/charges/forEveryVihecule" element={<TotalChargesForEveryVehicle currentUser={currentUser} />} />
                <Route path="/statistics/charges/ofUsers" element={<TotalChargesForUsers currentUser={currentUser} />} />
                <Route path="/statistics/charges/byYear" element={<TotalChargesByYear currentUser={currentUser} />} />
                <Route path="/statistics/projects/total/byContact" element={<RevenueByContact currentUser={currentUser} />} />
                <Route path="/statistics/projects/total/byTopclients" element={<Top10ClientsByAmount currentUser={currentUser} />} />
                <Route path="/statistics/personnel/performanceIndex" element={<UserPerformanceIndex currentUser={currentUser} />} />

                <Route path="/statistics/projects" element={<ProjectsStatistics currentUser={currentUser} />} />
                <Route path="/statistics/charges" element={<ChargesStatistics currentUser={currentUser} />} />
                <Route path="/statistics/recettes" element={<RecettesStatistics currentUser={currentUser} />} />
                <Route path="/statistics/recouvrement" element={<RecouvrementStatistics currentUser={currentUser} />} />
                <Route path="/statistics/performance" element={<PerformanceStatistics currentUser={currentUser} />} />

                {/* --------------------------- settings ------------------------- */}
                <Route path="/settings" element={<Settings currentUser={currentUser} darkMode={darkMode} />} />

                {/* --------------------------- statistics ------------------------- */}
                <Route path="/notifications" element={<Notifications currentUser={currentUser} darkMode={darkMode} />} />
                <Route path="*" element={<NotFound currentUser={currentUser} darkMode={darkMode} />} />

              </Routes>
            </>
          ) : (
            <Routes>
              <Route path="/register/user" element={<SignUp />} />
              <Route path="/dashboard" element={<SignIn />} />
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<SignIn />} />
              <Route path="*" element={<SignIn />} />
            </Routes>
          )}
        </ThemeProvider>
      </SnackbarProvider>
    </>
  );
}
