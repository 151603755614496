import API_URL from './apiUrl';

class PointService {
    addPointsFromCSV(data) {
        return API_URL.post(`/survey/points/add/csv`, data
            ,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
    }
    fetchPointsByZone(zone) {
        return API_URL.get(`/survey/points/${zone}`);
    }

    addPoint(data) {
        return API_URL.post(`/survey/points/add`, data);
    }

    deletePoint(pointId) {
        return API_URL.delete(`/survey/points/delete/${pointId}`);
    }

}

export default new PointService();