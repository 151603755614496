import React, { useState, } from 'react';
import { login } from '../../../actions/UserActions';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import MDBox from '../../../components/MDBox';
import MDTypography from '../../../components/MDTypography';
import MDInput from '../../../components/MDInput';
import MDButton from '../../../components/MDButton';
import BasicLayout from '../components/BasicLayout';
import bgImage from '../../../assets/images/3.png';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  CircularProgress,
  useMediaQuery,
  Card,
} from "@mui/material";
import { useSnackbar } from 'notistack';

function Basic() {
  const isMobile = useMediaQuery('(max-width:600px)');
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    dispatch(login({ username, password }))
      .then(() => {
        enqueueSnackbar('Connexion réussie!', { variant: 'success' });
        navigate("/");
      })
      .catch(() => {
        const errorMessage = message ? message : 'Login failed. Please check your credentials.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
        setLoading(false);
      });

  };

  if (currentUser) {
    return <Navigate to="/" />;
  }
  return (
    <BasicLayout image={bgImage}>
      <Card className='mt-5'>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Se Connecter
          </MDTypography>
          <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="https://syscamp.ma" variant="body1" color="white">
                <LanguageIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <YouTubeIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Nom d'utilisateur"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Mot de passe"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleLogin}
              >
                Se Connecter
              </MDButton>
            </MDBox>
            {loading && <CircularProgress size={20} />}
            <MDBox mt={3} mb={1} textAlign="center">
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
