import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import { Divider } from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
// import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import ProfileInfoCard from "./ProfileInfoCard";
import Header from "./Header";
import EditUser from "./EditUser";
import UpdatePassword from "./EditPassword";
function Profile({ currentUser }) {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox mb={2} />
            <Header currentUser={currentUser} selectedTab={selectedTab} handleTabChange={handleTabChange}>
                {selectedTab === 0 && (
                    <MDBox mt={5} mb={3}>
                        <Grid container spacing={1}>
                            <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                            <ProfileInfoCard
                                title="profile information"
                                info={{
                                    Name: `${currentUser.nom} ${currentUser.prenom}`,
                                    CIN: `${currentUser.cin}`,
                                    mobile: `${currentUser.telephone}`,
                                    Email: `${currentUser.email}`,
                                    adresse: `${currentUser.adresse}`,
                                }}
                                userId={currentUser?.id}
                                // action={{ route: "", tooltip: "Edit Profile" }}
                                shadow={false}
                            />
                            <Divider orientation="vertical" sx={{ mx: 0 }} />
                        </Grid>
                    </MDBox>
                )}
                {selectedTab === 1 && (
                    <EditUser userId={currentUser.id} />
                )}
                {selectedTab === 2 && (
                    <>
                        <UpdatePassword userId={currentUser.id} />
                    </>
                )}
            </Header>
            <Footer />
        </DashboardLayout>
    );
}

export default Profile;
