import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllClients, deleteClient } from '../../actions/ClientActions';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {
    Container,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Button,
    Pagination,
    Modal,
    Box,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    Alert,
} from '@mui/material';
import FileUpload from './UploadFiles';
import ClientFilesList from './ClientFileList';
import ClientInfo from './ClientInfo';
import { encodeId } from '../../actions/Crypte';

const ClientsList = ({ currentUser, clients, getAllClients, darkMode }) => {
    const [currentClient, setCurrentClient] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogProfOpen, setDialogProfOpen] = useState(false);
    const clientsPerPage = window.innerWidth <= 768 ? 8 : 15;
    const [searchTerm, setSearchTerm] = useState('');
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getAllClients();
    }, [getAllClients, deleteSuccess]);

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const openDialogProf = () => {
        setDialogProfOpen(true);
    };

    const closeDialogProf = () => {
        setDialogProfOpen(false);
    };

    const handleConfirmDelete = () => {
        if (currentClient) {
            dispatch(deleteClient(currentClient.id))
                .then(() => {
                    setDeleteSuccess(true);
                    getAllClients();
                    setCurrentClient(null);
                    setCurrentIndex(-1);
                    closeDeleteModal();
                });
        }
    };

    const handleSetActiveTutorial = (client, index) => {
        setCurrentClient(client);
        setCurrentIndex(index);
    };

    const handleSearch = (e) => {
        setCurrentPage(1);
        setSearchTerm(e.target.value);
    };

    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const filteredClients = clients.filter((client) =>
        client.full_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const currentClients = filteredClients.slice(indexOfFirstClient, indexOfLastClient);

    const renderClients = () => {
        return (
            <List>
                {currentClients.map((client, index) => (
                    <ListItem
                        button
                        key={index}
                        selected={index === currentIndex}
                        onClick={() => handleSetActiveTutorial(client, index)}
                    >
                        <ListItemText primary={client.full_name} />
                    </ListItem>
                ))}
            </List>
        );
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

        return (
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                color="info"
                className="mt-3"
            />
        );
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter des clients")) ? (
                <Container maxWidth="md">
                    <div id="clients-list" style={{ color: darkMode ? "#fff" : "#000" }}>
                        <div className="row">
                            <h2 className='text-center'
                                style={{
                                    fontSize: '36px',
                                    fontWeight: 'bold',
                                    color: '#3e4396',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                }}
                            >
                                Clients
                            </h2>
                            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des clients")) && (
                                <div className='float-end'>
                                    <MDButton
                                        component={Link}
                                        className="text-decoration-none float-end"
                                        to={`/clients/create`}
                                        color={'info'}
                                        variant="contained"
                                        sx={{ color: "light" }}
                                    >
                                        <ControlPointIcon sx={{ mr: "10px" }} />
                                        Ajouter
                                    </MDButton>
                                </div>
                            )}
                            <div className="col-md-6">
                                <Typography variant="h4" gutterBottom>
                                    Liste des clients
                                </Typography>
                                <div className="search-container mb-4">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        label="Chercher un client"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>
                                {renderClients()}
                                {filteredClients.length > clientsPerPage && renderPagination()}
                            </div>
                            <div className="col-md-6" >
                                {currentClient ? (
                                    <>
                                        <div className={`client-info ${window.innerWidth <= 768 ? 'mt-4' : ''}`}>
                                            <Typography variant="h4" gutterBottom>
                                                Informations de Client
                                            </Typography>
                                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter tous les informations de clients")) && (
                                                <>
                                                    <div className="mt-4">
                                                        <label>
                                                            <strong>ID:</strong>
                                                        </label>{' '}
                                                        {currentClient.id}
                                                    </div>
                                                    {currentClient.type !== 'personne physique' &&
                                                        <div className="mt-1">
                                                            <label>
                                                                <strong>ICE:</strong>
                                                            </label>{' '}
                                                            {currentClient.ice}
                                                        </div>
                                                    }
                                                    {(currentClient.type !== 'personne physique' && currentClient.type !== 'organisme public') &&
                                                        <div className="mt-1">
                                                            <label>
                                                                <strong>RC:</strong>
                                                            </label>{' '}
                                                            {currentClient.rc}
                                                        </div>
                                                    }
                                                    {(currentClient.type !== 'personne physique' && currentClient.type !== 'organisme public') &&
                                                        <div className="mt-1">
                                                            <label>
                                                                <strong>IF:</strong>
                                                            </label>{' '}
                                                            {currentClient.ifc}
                                                        </div>
                                                    }
                                                </>
                                            )
                                            }
                                            <div className="mt-1">
                                                <label>
                                                    <strong>Type:</strong>
                                                </label>{' '}
                                                {currentClient.type}
                                            </div>
                                            {currentClient.type !== 'organisme public' &&
                                                <div className="mt-1">
                                                    <label>
                                                        <strong>CIN:</strong>
                                                    </label>{' '}
                                                    {currentClient.cin}
                                                </div>
                                            }
                                            <div className="mt-1">
                                                <label>
                                                    <strong>Nom & Prenom:</strong>
                                                </label>{' '}
                                                {currentClient.full_name}
                                            </div>
                                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter tous les informations de clients")) && (
                                                <>
                                                    <div className="mt-1">
                                                        <label>
                                                            <strong>Telephone:</strong>
                                                        </label>{' '}
                                                        {currentClient.telephone}
                                                    </div>
                                                    <div className="mt-1">
                                                        <label>
                                                            <strong>Adresse:</strong>
                                                        </label>{' '}
                                                        {currentClient.adresse}
                                                    </div>
                                                </>
                                            )
                                            }
                                            <div className="mt-2">
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les clients")) && (
                                                    <MDButton
                                                        onClick={openDeleteModal}
                                                        rel="noreferrer"
                                                        variant="gradient"
                                                        color="error"
                                                        className="ms-2 mt-2"
                                                    >
                                                        Supprimer
                                                    </MDButton>
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les clients")) && (
                                                    <MDButton
                                                        component={Link}
                                                        to={`/clients/update/${currentClient.id}`}
                                                        variant="contained"
                                                        color="info"
                                                        className="ms-2 mt-2 btn-sm"
                                                    >
                                                        Modifier
                                                    </MDButton>
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) && (
                                                    <MDButton
                                                        component={Link}
                                                        to={`/projects/client/${currentClient.id}`}
                                                        variant="contained"
                                                        color="success"
                                                        className="ms-2 mt-2 btn-sm"
                                                    >
                                                        Projets
                                                    </MDButton>
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers") || currentUser.permissions.includes("Importer les fichiers")) && (
                                                    <MDButton
                                                        variant="contained"
                                                        color="warning"
                                                        className="ms-2 mt-2 btn-sm"
                                                        onClick={openDialog}
                                                    >
                                                        Fichiers
                                                    </MDButton>
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers") || currentUser.permissions.includes("Importer les fichiers")) && (
                                                    <MDButton
                                                        variant="contained"
                                                        color="primary"
                                                        className="ms-2 mt-2 btn-sm"
                                                        onClick={openDialogProf}
                                                    >
                                                        Carte Client
                                                    </MDButton>
                                                )}
                                            </div>
                                        </div>
                                        <Dialog
                                            open={dialogOpen}
                                            onClose={closeDialog}
                                            fullWidth maxWidth="md"
                                        >
                                            <DialogTitle
                                                textAlign={'center'}
                                            >
                                                Liste des fichiers
                                            </DialogTitle>
                                            <DialogContent>
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                                                    <div className='mb-2'>
                                                        <Typography variant="h6">Fichiers:</Typography>
                                                        <ClientFilesList clientId={currentClient?.id} />
                                                    </div>
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) && (
                                                    <>
                                                        <Typography variant="h6">Importer:</Typography>
                                                        <FileUpload clientId={currentClient?.id} />
                                                    </>
                                                )}
                                                <div className='mt-4 d-flex float-end'>
                                                    <MDButton
                                                        variant="contained"
                                                        // color="secondary"
                                                        className="ms-2 btn-sm"
                                                        onClick={closeDialog}
                                                    >
                                                        Fermer
                                                    </MDButton>
                                                </div>
                                            </DialogContent>
                                        </Dialog>
                                    </>
                                ) : (
                                    <div>
                                        <br />
                                        <Typography className={`client-info ${window.innerWidth <= 768 ? 'mt-4' : ''}`} >
                                            Veuillez cliquer sur un client...
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Modal
                        open={isDeleteModalOpen}
                        onClose={closeDeleteModal}
                        aria-labelledby="delete-modal-title"
                        aria-describedby="delete-modal-description"
                    >
                        <Box sx={{ ...modalStyle }}>
                            <Typography id="delete-modal-title" variant="h5" component="h2" gutterBottom>
                                Confirmation de suppression
                            </Typography>
                            <Typography id="delete-modal-description" sx={{ marginBottom: '1rem' }}>
                                Êtes-vous sûr de vouloir supprimer ce client ?
                            </Typography>
                            <MDButton onClick={handleConfirmDelete} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
                                Supprimer
                            </MDButton>
                            <MDButton onClick={closeDeleteModal} variant="contained">
                                Annuler
                            </MDButton>
                        </Box>
                    </Modal>
                    <Dialog
                        open={dialogProfOpen}
                        onClose={closeDialogProf}
                        fullWidth
                        maxWidth="md"
                    >
                        {currentClient && (
                            <>
                                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                                    Détails de la Client
                                </DialogTitle>
                                <DialogContent>
                                    <ClientInfo client={currentClient} />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={closeDialogProf} color="primary">
                                        Close
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        clients: state.clients,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllClients: () => dispatch(getAllClients()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);

