import React from 'react';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
const DevisPreview = ({ devis }) => {
    const prixHT = devis?.items.length > 0
        ? devis?.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
        : "-";
    const tva = 0.2 * (devis?.items.length > 0
        ? devis?.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
        : "-");
    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    let statusLabel, statusColor;

    switch (devis?.valider) {
        case 'oui':
            statusLabel = 'Confirmer';
            statusColor = 'green';
            break;
        case 'non':
            statusLabel = 'Rejeter';
            statusColor = 'red';
            break;
        case 'en cours':
            statusLabel = 'En cours';
            statusColor = 'orange';
            break;
        default:
            statusLabel = '-';
            statusColor = 'dark';
    }

    return (
        <Container>
            <Typography variant="h4" textAlign="center" mt={4} mb={2}>
                Devis: {devis?.numero}&nbsp;
                <Typography
                    component="span"
                    variant="body2"
                    sx={{
                        backgroundColor: `${statusColor} !important`,
                        verticalAlign: 'top', fontSize: '0.8rem'
                    }}
                >
                    {statusLabel}
                </Typography>
            </Typography>

            <div className="mb-2">
                <Typography variant="h5" textAlign="center" mb={1}>
                    {devis?.client?.full_name.toUpperCase()}
                </Typography>
            </div>

            <div className="mb-2">
                <Typography variant="subtitle1" textAlign="end">
                    Date: {formatDate(devis?.date)}
                </Typography>
            </div>

            <div className="text-muted">
                <Typography variant="body1" mb={1}>
                    <PhoneIcon /> : &nbsp;{devis?.client?.telephone}
                </Typography>
                <Typography variant="body1" mb={1}>
                    <LocationOnIcon /> : &nbsp;{devis?.client?.adresse || '-'}
                </Typography>
                <Typography variant="body1">
                    <ApartmentIcon /> : &nbsp;{devis?.client?.ice || '-'}
                </Typography>
            </div>

            <hr className="my-4" />

            <div className="row">
                <div className="col-sm-6">
                    <div className="text-muted">
                        <Typography variant="h5" mb={2}>
                            Objet: {devis?.objet}
                        </Typography>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="text-muted text-sm-end">
                        <div className="d-flex justify-content-end">
                            <Typography variant="h5" mb={1}>
                                Reference: {devis?.reference || '-'}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-2">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "70px" }}>N°</TableCell>
                                <TableCell>DESIGNATION</TableCell>
                                <TableCell>P.U</TableCell>
                                <TableCell>Quantite</TableCell>
                                <TableCell className="text-end" style={{ width: "120px" }}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {devis?.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <Typography variant="h5" className="text-truncate" mb={1}>
                                            {item?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{item?.prix_unitaire}</TableCell>
                                    <TableCell>{item?.quantity}</TableCell>
                                    <TableCell className="text-end">{item?.price}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Prix Total (H.T)</TableCell>
                                <TableCell className="text-end">{prixHT}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Montant de la TVA (20%)</TableCell>
                                <TableCell className="text-end">{tva.toFixed()}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Montant Total TTC</TableCell>
                                <TableCell className="text-end">
                                    <Typography variant="h4" fontWeight="bold" margin="0">
                                        {devis?.montant_ht * 1.2}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="d-print-none mt-4">
                    <div className="float-end">
                        <Tooltip title="Modifier" arrow>
                            <IconButton
                                component={Link}
                                to={`/finances/devis/update/${devis?.id}`}
                                variant="contained"
                                color="info"
                            >
                                <BorderColorIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default DevisPreview;

