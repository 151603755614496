import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    Container,
    List,
    ListItem,
    Paper,
    Divider,
    useMediaQuery,
    Grid,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import burceMars from "../../assets/images/profile.jpg";
import { fetchUserFiles, fetchFileContent, uploadUserFiles, getUser } from "../../actions/UserActions";
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { decodeId } from '../../actions/Crypte';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from 'react-router-dom';
import FileUploadToUser from './UploadFiles';
import UserFilesList from './FilesList';
import UserSalairesList from '../salaires/UserSalaires';
import CalculUserSalaire from '../salaires/CalculSalaire';
import SetPrimeTouser from '../primes/SetPrimeToUser';
import UserPrimesList from '../primes/UserPrimes';
import UserAttestation from './Attestation';
import UserPendingTaches from './PendingTaches';
import UserSchedule from './UserSchedule';
import AddSalaire from '../salaires/AddSalaire';
import CreateUserRequest from '../requests/CreateRequest';
import UserRequests from '../requests/UserRequests';
const UserInfo = ({ currentUser, darkMode }) => {
    const { id } = useParams();
    const [tabValue, setTabValue] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAvanceOpen, setIsAvanceOpen] = useState(false);
    const [isAvanceListOpen, setIsAvanceListOpen] = useState(false);
    const [isAddSalaireDialogOpen, setIsAddSalaireDialogOpen] = useState(false);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);
    const [isSalairesDialogOpen, setIsSalairesDialogOpen] = useState(false);
    const [isCalculDialogOpen, setIsCalculDialogOpen] = useState(false);
    const [isAddPrimeDialogOpen, setIsAddPrimeDialogOpen] = useState(false);
    const [isPrimeDialogOpen, setIsPrimeDialogOpen] = useState(false);
    const userId = decodeId(id);
    const [profileImage, setProfileImage] = useState(burceMars);
    const user = useSelector((state) => state.users.user);
    const files = useSelector((state) => state.users.files);
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser(userId));
        dispatch(fetchUserFiles(userId));
    }, [dispatch, userId]);

    useEffect(() => {
        const file = files.find(file => file?.startsWith(`${user?.nom}`));

        if (file) {
            dispatch(fetchFileContent(userId, file))
                .then(response => {
                    const image = `data:image/png;base64,${response.data}`;
                    setProfileImage(image);
                })
                .catch(error => {
                    console.error('Error fetching profile image:', error);
                });
        }
    }, [files, dispatch, userId, user?.nom]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getTitleFromPermission = (permissionName) => {
        if (permissionName.includes('projets')) {
            return 'projets';
        } else if (permissionName.includes('fichiers')) {
            return 'fichiers';
        } else if (permissionName.includes('matériels')) {
            return 'matériels';
        } else if (permissionName.includes('factures')) {
            return 'factures';
        } else if (permissionName.includes('véhicules')) {
            return 'véhicules';
        } else if (permissionName.includes('congés')) {
            return 'congés';
        } else if (permissionName.includes('encaissements')) {
            return 'encaissements';
        } else if (permissionName.includes('devis')) {
            return 'devis';
        } else if (permissionName.includes('primes')) {
            return 'primes';
        } else if (permissionName.includes('salaires')) {
            return 'salaires';
        } else if (permissionName.includes('contacts')) {
            return 'contacts';
        } else if (permissionName.includes('charges')) {
            return 'charges';
        }
        else if (permissionName.includes('clients')) {
            return 'clients';
        }
        return 'Other';
    };

    const permissionsByTitle = {};
    user?.permissions?.forEach((permission) => {
        const title = getTitleFromPermission(permission.name);
        if (!permissionsByTitle[title]) {
            permissionsByTitle[title] = [];
        }
        permissionsByTitle[title].push(permission);
    });

    const handleFileChange = async (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            const formData = new FormData();
            const fileExtension = files[0].name.split('.').pop().toLowerCase();

            const fileName = `${user?.nom}.${fileExtension}`;
            formData.append('files', new File([files[0]], fileName));

            try {
                setUploadProgress(0);
                setUploadError(null);

                const response = await dispatch(uploadUserFiles(userId, formData, (progressEvent) => {
                    const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                    setUploadProgress(progress);
                })) || {};

                const responseData = response.data || [];
                if (Array.isArray(responseData)) {
                    responseData.forEach(item => {
                        console.log(item);
                    });
                }

                setUploadProgress(0);
                setUploadSuccess(true);
                setTimeout(() => setUploadSuccess(false), 3000);
                dispatch(getUser(userId));
            } catch (error) {
                setUploadError('An error occurred during the upload.');
            }
        }
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container
                sx={{
                    padding: "3%",
                    marginTop: "3%",
                    marginBottom: "3%",
                    borderRadius: "0.5rem",
                    background: "#fff",
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Paper
                            sx={{
                                textAlign: "center",
                                padding: "2%",
                                textAlign: "center",
                                "& img": {
                                    width: "70%",
                                    height: "100%",
                                },
                                "& .file": {
                                    position: "relative",
                                    overflow: "hidden",
                                    marginTop: "-20%",
                                    width: "100%",
                                    border: "none",
                                    borderRadius: 0,
                                    fontSize: "15px",
                                    background: "#212529b8",
                                },
                                "& .file input": {
                                    position: "absolute",
                                    opacity: 0,
                                    right: 0,
                                    top: 0,
                                },
                            }}
                        >
                            <Box className="profile-img me-4 p-2">
                                <img
                                    src={profileImage}
                                    alt={user?.nom}
                                    style={{ width: "100%", height: '80%', borderRadius: "10px" }}
                                />
                                <Box className="file btn btn-primary">
                                    <PhotoCameraIcon />
                                    &nbsp;Change Photo
                                    <input
                                        onChange={handleFileChange}
                                        type="file"
                                    />
                                </Box>
                                <Typography variant="h5">Gestion de fichiers</Typography>
                                <List>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsFilesDialogOpen(true)}
                                            >
                                                Liste des fichiers
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsUploadDialogOpen(true)}
                                            >
                                                Importer des fichiers
                                            </Button>
                                        </ListItem>
                                    )}
                                    <Typography variant="h5" mt={2}>
                                        Gestion des salaires
                                    </Typography>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les primes")) && (
                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsPrimeDialogOpen(true)}
                                            >
                                                Liste des primes
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter les primes")) && (
                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsAddPrimeDialogOpen(true)}
                                            >
                                                Donner des primes
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les salaires")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsSalairesDialogOpen(true)}
                                            >
                                                Liste des salaires
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des salaires")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsCalculDialogOpen(true)}
                                            >
                                                Calculer Salaire
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des avances sur salaires")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsAvanceOpen(true)}
                                            >
                                                ajouter un avance sur salaire
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter des avances sur salaires")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsAvanceListOpen(true)}
                                            >
                                                Liste des avances
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des salaires")) && (

                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsAddSalaireDialogOpen(true)}
                                            >
                                                ajouter un salaire manuellement
                                            </Button>
                                        </ListItem>
                                    )}

                                    <Typography variant="h5" mt={2}>
                                        Gestion des tâches
                                    </Typography>
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les tâches")) && (
                                        <ListItem>
                                            <Button
                                                sx={{
                                                    fontSize: "0.9rem",
                                                    width: "100%",
                                                    color: darkMode ? "#fff !important" : "#000 !important",
                                                }}
                                                component={Button}
                                                onClick={() => setIsDialogOpen(true)}
                                            >
                                                Tâches actuelles
                                            </Button>
                                        </ListItem>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (

                                        <ListItem className={isMobile ? `mt-2` : 'mt-5'}>
                                            <UserAttestation user={user} />
                                        </ListItem>
                                    )}

                                </List>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box
                            sx={{
                                "& h5": {
                                    color: "#333",
                                },
                                "& h6": {
                                    color: "#0062cc",
                                },
                                "& .proile-rating": {
                                    fontSize: "12px",
                                    color: "#818182",
                                    marginTop: "5%",
                                    "& span": {
                                        color: "#495057",
                                        fontSize: "15px",
                                        fontWeight: 600,
                                    },
                                },
                                "& .profile-head .nav-tabs": {
                                    marginBottom: "5%",
                                },
                                "& .profile-head .nav-tabs .nav-link": {
                                    fontWeight: 600,
                                    border: "none",
                                },
                                "& .profile-head .nav-tabs .nav-link.active": {
                                    border: "none",
                                    borderBottom: "2px solid #0062cc",
                                },
                                "& .profile-edit-btn": {
                                    border: "none",
                                    borderRadius: "1.5rem",
                                    width: "70%",
                                    padding: "2%",
                                    fontWeight: 600,
                                    color: "#6c757d",
                                    cursor: "pointer",
                                },
                            }}
                        >
                            <div className="profile-head">
                                <Typography variant="h4">
                                    {user?.nom?.toLocaleUpperCase()}&nbsp;{user?.prenom?.toLocaleUpperCase()}
                                </Typography>
                                <Divider
                                    style={{
                                        margin: "0px !important",
                                        width: "50%",
                                        height: "4px",
                                        backgroundColor: "rgba(18, 104, 255, 1)",
                                    }}
                                />
                                {user?.roles &&
                                    user.roles.map((role, index) => (
                                        <div key={index} className="d-flex">
                                            <Typography variant="h5">Poste:&nbsp;</Typography>
                                            <Typography variant="h6">{role.name}</Typography>
                                        </div>
                                    ))}
                                <p
                                    className="proile-rating"
                                    style={{ marginTop: "14px !important" }}
                                >
                                    DATE DE RECRUTEMENT: <span>{user?.date_recrutement}</span>
                                </p>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    id="myTab"
                                    role="tablist"
                                >
                                    <Tab
                                        label="Informations"
                                        id="home-tab"
                                        aria-controls="home"
                                        aria-selected={tabValue === 0}
                                    />
                                    <Tab
                                        label="Permissions"
                                        id="profile-tab"
                                        aria-controls="profile"
                                        aria-selected={tabValue === 1}
                                    />
                                    <Tab
                                        label="Programme"
                                        id="taches-tab"
                                        aria-controls="taches"
                                        aria-selected={tabValue === 2}
                                    />
                                </Tabs>
                                {tabValue === 0 && (
                                    <div>
                                        <Grid item xs={12}>
                                            <div
                                                style={{ display: "flex" }}
                                                className={
                                                    darkMode ? "text-light" : "text-dark"
                                                }
                                            >
                                                <div style={{ flex: "1", marginTop: "12px" }}>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                        color={"inherit"}
                                                    >
                                                        <strong>Id:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Nom & Prénom:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Email:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Username:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Telephone:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Adresse:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Salaire:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Contrat:</strong>
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Situation Familiale:</strong>
                                                    </Typography>
                                                    {user?.situation_fam === "marié" && (
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                color: darkMode
                                                                    ? "#fff !important"
                                                                    : "#000 !important",
                                                            }}
                                                        >
                                                            <strong>Nombre d'enfants:</strong>
                                                        </Typography>
                                                    )}
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>Décraltion CNSS:</strong>
                                                    </Typography>
                                                    {user?.decraltion_cnss === "oui" && (
                                                        <Typography
                                                            variant="h6"
                                                            sx={{
                                                                color: darkMode
                                                                    ? "#fff !important"
                                                                    : "#000 !important",
                                                            }}
                                                        >
                                                            <strong>N° CNSS:</strong>
                                                        </Typography>
                                                    )}
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            color: darkMode
                                                                ? "#fff !important"
                                                                : "#000 !important",
                                                        }}
                                                    >
                                                        <strong>RIB:</strong>
                                                    </Typography>
                                                </div>
                                                <div style={{ flex: "1", marginTop: "12px" }}>
                                                    <Typography variant="h6">
                                                        {user?.id || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.nom}&nbsp;{user?.prenom}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.email || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.username || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.telephone || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.adresse || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.salaire || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.contart || "-"}
                                                    </Typography>
                                                    <Typography variant="h6">
                                                        {user?.situation_fam || "-"}
                                                    </Typography>
                                                    {user?.situation_fam === "marié" && (
                                                        <Typography variant="h6">
                                                            {user?.nb_enf || "-"}
                                                        </Typography>
                                                    )}
                                                    <Typography variant="h6">
                                                        {user?.decraltion_cnss || "-"}
                                                    </Typography>
                                                    {user?.decraltion_cnss === "oui" && (
                                                        <Typography variant="h6">
                                                            {user?.ncnss || "-"}
                                                        </Typography>
                                                    )}
                                                    <Typography variant="h6">
                                                        {user?.rib || "-"}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </Grid>
                                    </div>
                                )}
                                {tabValue === 1 && (
                                    <>
                                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                                            <div>
                                                {Object.entries(permissionsByTitle).map(
                                                    ([title, permissions], index) => (
                                                        <div key={index}>
                                                            <Typography variant="h5" mt={2} gutterBottom>
                                                                {title?.toLocaleUpperCase()}
                                                            </Typography>
                                                            <Grid container spacing={2}>
                                                                {permissions.map((permission, innerIndex) => (
                                                                    <Grid item xs={6} key={innerIndex}>
                                                                        <FileDownloadDoneIcon color="info" />
                                                                        &nbsp;{permission.name}
                                                                        {innerIndex < permissions.length - 1 && (
                                                                            <Divider
                                                                                sx={{ margin: "0px !important" }}
                                                                            />
                                                                        )}
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </div>
                                                    )
                                                )}
                                                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")) && (
                                                    <Button
                                                        variant="contained"
                                                        className="me-1 float-end"
                                                        component={Link}
                                                        to={`/ressources-humaines/personnel/user/update/permessions/${user?.id}`}
                                                        color="info"
                                                    >
                                                        <BorderColorIcon />
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                {tabValue === 2 && (
                                    <div className='mt-2'>
                                        <UserSchedule userId={userId} />
                                    </div>
                                )}
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les personnel")) && (
                            <Button
                                className="profile-edit-btn"
                                name="btnAddMore"
                                variant="contained"
                                sx={{
                                    color: "#fff !important",
                                }}
                                component={Link}
                                to={`/ressources-humaines/personnels/update/${userId}`}
                            >
                                <ChangeCircleIcon />
                                &nbsp;Modifier
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Dialog
                    open={isUploadDialogOpen}
                    onClose={() => setIsUploadDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Importer de fichiers
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <FileUploadToUser userId={userId} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsUploadDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isFilesDialogOpen}
                    onClose={() => setIsFilesDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Liste de fichiers
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <UserFilesList userId={userId} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsFilesDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isSalairesDialogOpen}
                    onClose={() => setIsSalairesDialogOpen(false)}
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Liste de salaires
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <UserSalairesList darkMode={darkMode} userId={userId} user={user} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsSalairesDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isCalculDialogOpen}
                    onClose={() => setIsCalculDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Calculer salaire
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <CalculUserSalaire user={user} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsCalculDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isAddPrimeDialogOpen}
                    onClose={() => setIsAddPrimeDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Donner des primes
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <SetPrimeTouser userId={userId} currentUser={currentUser} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAddPrimeDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isPrimeDialogOpen}
                    onClose={() => setIsPrimeDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Liste des primes
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <UserPrimesList primes={user?.primes} darkMode={darkMode} currentUser={currentUser} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsPrimeDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Liste des tâches actuelles
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <UserPendingTaches userId={userId} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isAddSalaireDialogOpen}
                    onClose={() => setIsAddSalaireDialogOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Ajout d'un salaire
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <AddSalaire userId={userId} currentUser={currentUser} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAddSalaireDialogOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isAvanceOpen}
                    onClose={() => setIsAvanceOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Ajout d'un avance sur salaire
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <CreateUserRequest userId={userId} currentUser={currentUser} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAvanceOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isAvanceListOpen}
                    onClose={() => setIsAvanceListOpen(false)}
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
                        Ajout d'un avance sur salaire
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <UserRequests userId={userId} currentUser={currentUser} salaire={user?.totalSalaire} />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsAvanceListOpen(false)} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </DashboardLayout>
    );
};

export default UserInfo;
