import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline as LeafletPolyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import 'proj4leaflet';
import { EditControl } from 'react-leaflet-draw';
import PointServices from '../../services/pointServices';
import { Icon } from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { FeatureGroup } from 'react-leaflet';
import { Dialog, DialogTitle, DialogActions, Alert, Snackbar, TextField, Button, DialogContent } from '@mui/material';
import { useSelector } from 'react-redux';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import PolylineServices from '../../services/polylineServices';
import {
    Paper,
    Grid,
    Typography,
    Box,
} from "@mui/material";
import L from 'leaflet';
import { MyLocation, AddLocationAlt } from '@mui/icons-material';

const markerIcon = new Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

const SurveyMap = ({ defaultZone }) => {
    const [points, setPoints] = useState([]);
    const [convertedCoordinates, setConvertedCoordinates] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [selectedPointId, setSelectedPointId] = useState(null);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [reference, setRefence] = useState("");
    const [nature, setNature] = useState("");
    const [name, setName] = useState("");
    const [from, setFrom] = useState(false);
    const [X, setX] = useState(0);
    const [Y, setY] = useState(0);
    const [igt, setIgt] = useState('');
    const [note, setNote] = useState('');
    const { user: currentUser } = useSelector((state) => state.auth);
    // let fullName = `${currentUser?.nom} ${currentUser?.prenom}`;
    const [convertedPolylines, setConvertedPolylines] = useState([]);
    const [initialZoom, setInitialZoom] = useState(10);
    const [polylines, setPolylines] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedPolyline, setSelectedPolyline] = useState(null);

    const mapRef = useRef(null);

    useEffect(() => {
        fetchPolylines();
    }, []);

    const fetchPolylines = async () => {
        try {
            const response = await PolylineServices.getPolylines();
            setPolylines(response.data.polylines);
        } catch (error) {
            console.error('Error fetching polylines:', error);
        }
    };

    useEffect(() => {
        const fetchPoints = async () => {
            try {
                const response = await PointServices.fetchPointsByZone(defaultZone);
                setPoints(response.data.points);
            } catch (error) {
                console.error('Error fetching points:', error);
            }
        };

        fetchPoints();
    }, [defaultZone]);

    useEffect(() => {
        if (points.length === 0) return;
        let sourceCRS;

        switch (defaultZone) {
            case '26191':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
                break;
            case '26192':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
                break;
            case '26194':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
                break;
            case '26195':
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
                break;
            default:
                sourceCRS =
                    '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
        const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

        proj4.defs('EPSG:3857', targetCRS);
        proj4.defs(`EPSG:${defaultZone}`, sourceCRS);

        const converted = points.map((point) => {
            const [x, y] = proj4(sourceCRS, targetCRS, point.geometry.coordinates);
            return [y, x];
        });

        setConvertedCoordinates(converted);
    }, [points, defaultZone]);

    const targetCRS = '+proj=longlat +datum=WGS84 +no_defs';

    useEffect(() => {
        if (polylines?.length === 0) return;

        const converted = polylines?.map((polyline) => {
            const zone = polyline.zone;
            const sourceCRS = getSourceCRS(zone);

            proj4.defs('EPSG:3857', targetCRS);
            proj4.defs(`EPSG:${zone}`, sourceCRS);

            return polyline.geometry.coordinates.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });
        });

        setConvertedPolylines(converted);
        // setSelectedPolylineInfo({
        //     locations: converted,
        //     info: polylines,
        // });
    }, [polylines]);

    const handleSearch = () => {
        setInitialZoom(19);
        const filteredPolyline = polylines.find(
            (polyline) =>
                polyline.projet.numero === searchQuery || polyline.projet.reference_fonciere === searchQuery
        );

        if (filteredPolyline) {
            const zone = filteredPolyline.zone;
            const sourceCRS = getSourceCRS(zone);

            const converted = filteredPolyline.geometry.coordinates.map((coord) => {
                const [x, y] = proj4(sourceCRS, targetCRS, coord);
                return [y, x];
            });

            setConvertedPolylines([converted]);
            setSelectedPolyline(filteredPolyline);

            mapRef.current.flyTo(converted[0], 18);
        } else {
            setConvertedPolylines([]);
            setSelectedPolyline(null);
        }
    };

    const getSourceCRS = (zone) => {
        switch (zone) {
            case '26191':
                return '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=31';
            case '26192':
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
            case '26194':
                return '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=34';
            case '26195':
                return '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=35';
            default:
                return '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +towgs84=31,146,47,0,0,0,0 +units=m +no_defs +zone=32';
        }
    };

    const Map = () => {
        const map = useMap();
        mapRef.current = map;

        return null;
    };

    const handlePolygonSubmit = () => {
        if (X && Y && name && nature) {
            PointServices.addPoint({
                name: name,
                nature: nature,
                zone: defaultZone,
                X,
                Y,
                createdBy: igt,
                reference: reference,
                note,
            })
                .then((response) => {
                    console.log('Point added successfully:', response.data);
                    handleSnackbarOpen('Point added successfully', 'success');
                })
                .catch((error) => {
                    console.error('Error adding point:', error);
                    handleSnackbarOpen('Error adding point', 'error');
                });

            setRefence('');
            setName('');
            setNature('');
            setIgt('');
            setNote('');
            setX(0);
            setY(0);
            // setFrom(false);
        } else {
            handleSnackbarOpen('Please fill in all the form fields', 'error');
        }
    };

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleDeleteDialogOpen = (pointId) => {
        setSelectedPointId(pointId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteDialogClose = () => {
        setSelectedPointId(null);
        setDeleteDialogOpen(false);
    };

    const handleDeletePoint = () => {
        if (selectedPointId) {
            PointServices.deletePoint(selectedPointId)
                .then((response) => {
                    console.log('Point deleted successfully:', response.data);
                    handleSnackbarOpen(`Point deleted successfully: ${response.data.message}`, 'success');
                    handleDeleteDialogClose();
                })
                .catch((error) => {
                    console.error('Error deleting point:', error);
                    handleSnackbarOpen('Error deleting point', 'error');
                });
        }
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const [X, Y] = proj4('EPSG:3857', `EPSG:${defaultZone}`, [longitude, latitude]);
                    // setUserLocation({ X, Y });
                    mapRef.current.flyTo([latitude, longitude], 18);

                    const popupContent = `<div>Ceci est votre emplacement</div>`;
                    L.popup().setLatLng([latitude, longitude]).setContent(popupContent).openOn(mapRef.current);
                },
                (error) => {
                    console.error('Error getting user location:', error);
                    handleSnackbarOpen('Error getting user location', 'error');
                }
            );
        } else {
            handleSnackbarOpen("Geolocation is not supported by your browser", 'error');
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                <Grid container spacing={2} mb={2} mt={2}>
                    <Grid item xs={12} className='d-flex'>
                        <TextField
                            label="Recherche par numéro de projet ou référence"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            variant="standard"
                            fullWidth
                            sx={{
                                marginRight: '1rem'
                            }}
                        // margin="normal"
                        />
                        <MDButton
                            variant="gradient"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Afficher
                        </MDButton>
                    </Grid>
                </Grid>
            </Box>
            {(convertedCoordinates.length > 0 || convertedPolylines.length > 0) && (
                <>
                    <Grid container spacing={2} mb={2} mt={2}>
                        <Grid item xs={12} sm={6}>

                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    color: '#fff !important',
                                    marginBottom: '12px',
                                }}
                                // onClick={() => setFrom(true)}
                                onClick={() => setAddDialogOpen(true)}
                            >
                                <AddLocationAlt />&nbsp;
                                Ajouter Un point
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{
                                    color: '#fff !important',
                                    marginBottom: '12px',
                                }}
                                onClick={handleGetCurrentLocation}
                            >
                                <MyLocation />&nbsp;
                                Obtenir ma position
                            </Button>
                        </Grid>
                    </Grid>
                    <MapContainer
                        center={[30.39341198110206, -9.565342191279383]}
                        zoom={initialZoom}
                        style={{ height: '80vh', width: '100%' }}
                    >
                        <Map />
                        <TileLayer
                            url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
                            maxZoom={21}
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            attribution='<a href="https://www.google.com/maps">Google Maps</a>'
                        />
                        <MarkerClusterGroup>
                            {convertedCoordinates.map((coord, index) => (
                                <Marker key={index} position={coord} icon={markerIcon}>
                                    <Popup>
                                        <div>Id: {points[index].id}</div>
                                        <div>Name: {points[index].name}</div>
                                        <div>Nature: {points[index].nature}</div>
                                        <div>Reference: {points[index].reference}</div>
                                        <div>X: {points[index]?.geometry?.coordinates[0]}</div>
                                        <div>Y: {points[index]?.geometry?.coordinates[1]}</div>
                                        <MDButton
                                            variant="contained"
                                            color="error"
                                            size='small'
                                            className='mt-2'
                                            onClick={() => handleDeleteDialogOpen(points[index].id)}
                                        >
                                            Supprimer
                                        </MDButton>
                                    </Popup>
                                </Marker>
                            ))}
                        </MarkerClusterGroup>
                        <LeafletPolyline
                            positions={convertedPolylines}
                            pathOptions={{ color: 'red' }}
                        />
                        {selectedPolyline && (
                            <Popup position={convertedPolylines[0][0]} autoPan={true}>
                                <Box>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            background: '#1A73E8',
                                            color: '#fff',
                                            p: 2,
                                            borderRadius: '8px',
                                            width: '300px',
                                        }}
                                    >
                                        <Typography sx={{ color: '#fff', }} variant="h5" fontWeight="bold">
                                            Informations de Projet
                                        </Typography>
                                    </Paper>
                                    <Box mt={2} p={1}>
                                        <Typography>
                                            <strong>Numero:</strong> {selectedPolyline?.projet?.numero}
                                        </Typography>
                                        <Typography>
                                            <strong>Reference Fonciere:</strong>{' '}
                                            {selectedPolyline?.projet?.reference_fonciere}
                                        </Typography>
                                        <Typography>
                                            <strong>Nature:</strong>{' '}
                                            {selectedPolyline?.projet?.nature?.map((item, index) => (
                                                <span key={index}>
                                                    {item?.name}
                                                    {index < selectedPolyline?.projet?.nature?.length - 1 && ', '}
                                                </span>
                                            )) || ''}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Popup>
                        )}
                    </MapContainer>
                </>
            )}
            <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose}>Annuler</Button>
                    <Button onClick={handleDeletePoint} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className='text-center' bgcolor='#ffc107' style={{ fontSize: '29px' }}>
                    Ajouter un point
                </DialogTitle>
                <DialogContent>
                    <TextField
                        label="Nom du point"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Nature du point"
                        value={nature}
                        onChange={(e) => setNature(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Référence"
                        value={reference}
                        onChange={(e) => setRefence(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="X"
                        type='number'
                        value={X}
                        onChange={(e) => setX(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Y"
                        type='number'
                        value={Y}
                        onChange={(e) => setY(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="IGT"
                        value={igt}
                        onChange={(e) => setIgt(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Observation"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        type="button"
                        variant="contained"
                        color="dark"
                        sx={{
                            color: '#000 !important',
                            marginTop: '12px',
                            backgroundColor: '#ffc107 !important',
                        }}
                        onClick={handlePolygonSubmit}
                    >
                        Sauvegarder
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default SurveyMap;
