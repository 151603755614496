import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProjects, deleteProject } from '../../actions/ProjetActions';
import { Link } from 'react-router-dom';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
    TextField,
    Box, Typography, Modal, Button, IconButton, useMediaQuery,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaceIcon from '@mui/icons-material/Place';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccessDenied from "../Errors/AccessDenied";
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ProjectsReview from './ProjectsReview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PolylineIndex from '../geometry/PolylineIndex';
import { encodeId } from '../../actions/Crypte';
import FactureProjet from './FactureProjet';
import DescriptionIcon from '@mui/icons-material/Description';
// import { width } from '@mui/system';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';

const ProjectList = ({ currentUser }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [projectsPerPage] = useState(10);
    const projects = useSelector((state) => state.project.projects || []);
    const isMobile = useMediaQuery('(max-width:600px)');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [currentPageTotalPrice, setCurrentPageTotalPrice] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFactureDialogOpen, setIsFactureDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isPolygonProjectOpen, setIsPolygonProjectOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllProjects());
    }, [dispatch]);


    useEffect(() => {
        const filteredProjects = projects?.filter((project) => {
            const reference = project.reference_fonciere || '';
            const numero = project.numero || '';
            const client = project.client?.full_name || '';
            const commercial = project.projectContact?.name || '';
            const creation = project.date_creation || '';
            const situation = project?.situation || '';
            return (
                reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
                numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                client.toLowerCase().includes(searchTerm.toLowerCase()) ||
                commercial.toLowerCase().includes(searchTerm.toLowerCase()) ||
                creation.toLowerCase().includes(searchTerm.toLowerCase()) ||
                situation.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredProjects(filteredProjects);
    }, [projects, searchTerm]);

    useEffect(() => {
        const indexOfLastProject = currentPage * projectsPerPage;
        const indexOfFirstProject = indexOfLastProject - projectsPerPage;
        const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);

        const currentPageTotalPrice = calculateTotalPrice(currentProjects);
        setCurrentPageTotalPrice(currentPageTotalPrice);
    }, [currentPage, filteredProjects, projectsPerPage]);

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentProjectId, setCurrentProjectId] = useState(null);

    const openDeleteModal = (projectId) => {
        setDeleteModalOpen(true);
        setCurrentProjectId(projectId);
    };

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const handleDelete = (projectId) => {
        dispatch(deleteProject(projectId));
    };

    const handleConfirmDelete = () => {
        if (currentProjectId) {
            handleDelete(currentProjectId);
            closeDeleteModal();
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const openDialogLocation = (item) => {
        setSelectedItem(item);
        setIsPolygonProjectOpen(true);
    };

    const closeDialogLocation = () => {
        setSelectedItem(null);
        setIsPolygonProjectOpen(false);
    };

    const getStatusColor = (statut) => {
        switch (statut) {
            case 'preparation':
                return '#ffa36d';
            case 'terrain':
                return '#FF8C00';
            case 'bureau':
                return '#FFFF00';
            case 'controle':
                return '#00CED1';
            case 'livraison':
                return '#00FFFF';
            case 'cloture':
                return '#5F9EA0';
            default:
                return '';
        }
    };

    const calculateTotalPrice = (projects) => {
        let total = 0;
        projects?.forEach((project) => {
            total += parseFloat(project.prix || 0);
        });
        return total;
    };

    const Pagination = ({ page, onPageChange, className }) => {
        const apiRef = useGridApiContext();
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (
            <MuiPagination
                color="primary"
                className={className}
                count={pageCount}
                page={page + 1}
                onChange={(event, newPage) => {
                    onPageChange(event, newPage - 1);
                }}
            />
        );
    }

    const CustomPagination = (props) => {
        return <GridPagination ActionsComponent={Pagination} {...props} />;
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const isUserIncludePrix = currentUser &&
        (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Consulter les prix des projets"));
    const isUserIncludeContact = currentUser &&
        (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Consulter les contacts des projets"));
    const isUserPaiement = currentUser &&
        (currentUser.roles.includes("ROLE_ADMIN") || currentUser.roles.includes("Consulter les paiements des projets"));

    const openDialog = (project) => {
        setSelectedItem(project);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const openFactureDialog = (project) => {
        setSelectedItem(project);
        setIsFactureDialogOpen(true);
    };

    const closeFactureDialog = () => {
        setSelectedItem(null);
        setIsFactureDialogOpen(false);
    };

    const columns = [
        {
            field: 'numero',
            headerName: 'Project',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            // flex: 1,
            renderCell: (params) => (
                <Typography component={Link} to={`/projects/project/${encodeId(params.row.id)}`} className="text-decoration-none text-dark active">
                    {params.value}
                </Typography>
            ),
        },
        {
            field: 'client?.full_name', headerName: 'Client',
            ...(isMobile ? { width: 180 } : { flex: 1 }),
            valueGetter: (params) => params.row.client?.full_name || '',
        },
        ...(isMobile
            ? []
            : [
                { field: 'reference_fonciere', headerName: 'Référence Foncière', flex: 1 },
                {
                    field: 'nature',
                    headerName: 'Nature',
                    flex: 1,
                    valueGetter: (params) => params.row.nature.map((natures) => natures.name).join(", "),
                },
                { field: 'date_creation', headerName: 'Créé à', flex: 1 },
                {
                    field: 'statut',
                    headerName: 'Status',
                    flex: 1,
                    renderCell: (params) => (
                        <span className="label label-default" style={{ backgroundColor: getStatusColor(params.value) }}>
                            {params.value}
                        </span>
                    ),
                },
                ...(isUserIncludePrix
                    ? [
                        { field: 'prix', headerName: 'Prix', flex: 1 },
                        { field: 'paiement', headerName: 'Paiement', flex: 0.5 },
                    ]
                    : []),
            ]
        ),
        {
            field: 'actions',
            headerName: 'Actions',
            ...(isMobile ? { width: 180 } : { flex: 3 }),
            renderCell: (params) => (
                <>

                    <Tooltip title="Détails" arrow>
                        <IconButton
                            color="warning"
                            aria-label="Preview"
                            onClick={() => openDialog(params.row)}
                        >
                            <OpenInFullIcon />
                        </IconButton>
                    </Tooltip>
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des factures")) &&
                        <>
                            {(params.row?.factures?.length === 0) &&
                                <Tooltip title="Facturé le projet" arrow>
                                    <IconButton
                                        color="warning"
                                        aria-label="Preview"
                                        onClick={() => openFactureDialog(params.row)}
                                    >
                                        <DescriptionIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    }
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) &&
                        <Tooltip title="Informations" arrow>
                            <IconButton
                                color="dark"
                                aria-label="Preview"
                                component={Link}
                                to={`/projects/project/${encodeId(params.row.id)}`}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les projets")) &&
                        <Tooltip title="Modifier" arrow>
                            <Link to={`/projects/update/${encodeId(params.row.id)}`} className="text-decoration-none">
                                <IconButton color="info" aria-label="Edit" title="Edit">
                                    <BorderColorIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    }
                    {!isMobile && (
                        <>
                            {(currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les projets")) &&
                                <Tooltip title="Supprimer" arrow>
                                    <IconButton
                                        color="error"
                                        aria-label="Delete"
                                        onClick={() => openDeleteModal(params.row.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </>
                    )}
                    {!isMobile && (
                        <IconButton onClick={() => openDialogLocation(params.row)} color="success" aria-label="View" title="View">
                            <PlaceIcon />
                        </IconButton>
                    )}
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    const createFactureButton = (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) && (
                <Link to={`/projects/create`} className="text-decoration-none mt-1">
                    <MDButton
                        variant="contained"
                        color='info'
                        size='small'
                        sx={{ marginLeft: '0.5rem' }}
                    >
                        <ControlPointIcon sx={{ mr: "12px", }} />
                        Créer
                    </MDButton>
                </Link>
            )}
        </>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les projets")) ? (
                <div className="container mb-3">
                    <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                        mb={2}
                        mt={4}
                    >
                        Liste des projets
                    </Typography>
                    <div className="search-container">
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Rechercher un projet par référence, client, contact, situation, date de création ou numéro"
                            onChange={handleSearch}
                            value={searchTerm}
                            name="Search Project"
                            sx={{ gridColumn: "span 2", marginBottom: '1rem' }}
                        />
                    </div>
                    <Box >
                        <Box
                            m="0 0 0 0"
                            height="100vh"
                        >
                            <DataGrid
                                rows={filteredProjects || []}
                                columns={columns}
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                components={{
                                    // pagination: CustomPagination,
                                    Toolbar: (props) => (
                                        <div className='d-flex' style={{ color: darkMode ? "#000" : "#000" }}>
                                            {!isMobile && createFactureButton}
                                            <GridToolbar {...props} />
                                        </div>
                                    ),
                                }}
                                // pagination
                                // initialState={{
                                //     ...filteredProjects.initialState,
                                //     pagination: { paginationModel: { pageSize: 25 } },
                                // }}
                                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                style={{ color: darkMode ? "#fff" : "#000" }}
                                sx={{
                                    '& .MuiDataGrid-cell': {
                                        color: darkMode ? "light" : "black",
                                    },
                                    '& .MuiDataGrid-row': {
                                        backgroundColor: darkMode ? "rgb(36, 39, 59)" : "light",
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </div>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Modal
                open={isDeleteModalOpen}
                onClose={closeDeleteModal}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box sx={{ ...modalStyle }}>
                    <Typography id="delete-modal-title" variant="h5" component="h2" gutterBottom>
                        Confirmation de suppression
                    </Typography>
                    <Typography id="delete-modal-description" sx={{ marginBottom: '1rem' }}>
                        Êtes-vous sûr de vouloir supprimer ce projet ?
                    </Typography>
                    <MDButton onClick={handleConfirmDelete} variant="contained" color="error" sx={{ marginRight: '1rem' }}>
                        Confirmer la suppression
                    </MDButton>
                    <MDButton color='secondary' onClick={closeDeleteModal} variant="contained">
                        Annuler
                    </MDButton>
                </Box>
            </Modal>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                    Détails du projet: {selectedItem?.numero}
                </DialogTitle>
                <DialogContent>
                    <ProjectsReview project={selectedItem} currentUser={currentUser} darkMode={darkMode} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isPolygonProjectOpen}
                onClose={closeDialogLocation}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Emplacement du projet
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <>
                            <PolylineIndex projectId={selectedItem?.id} currentUser={currentUser} />
                        </>
                    ) : (
                        <>
                            <Typography>Oups, il y a peut-être une erreur</Typography>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogLocation} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isFactureDialogOpen}
                onClose={closeFactureDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                    Facture projet
                </DialogTitle>
                <DialogContent>
                    {selectedItem ? (
                        <>
                            <FactureProjet projet={selectedItem} handleClose={closeFactureDialog} />
                        </>
                    ) : (
                        <>
                            <Typography>Oups, il y a peut-être une erreur</Typography>
                        </>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFactureDialog} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default ProjectList;
