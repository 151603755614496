import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { getMaterialsByType, deleteMaterial } from "../../actions/MaterialsActions";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Link } from "react-router-dom";
import {
    Container,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Snackbar,
    Alert,
    IconButton,
    Grid, 
    Card,
    CardContent,
    Avatar,
    Stack,  
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
import AccessDenied from "../Errors/AccessDenied";
import { useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const MaterialsByType = ({ currentUser }) => {
    const dispatch = useDispatch();
    const { type } = useParams();
    const materials = useSelector((state) => state.material.materials);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        dispatch(getMaterialsByType(type));
    }, [dispatch, type]);

    const openDeleteModal = (material) => {
        setSelectedMaterial(material);
        setDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setSelectedMaterial(null);
        setDeleteModalOpen(false);
    };

    const handleDelete = () => {
        if (selectedMaterial) {
            dispatch(deleteMaterial(selectedMaterial.id))
                .then(() => {
                    setSnackbarMessage('Matériau supprimé avec succès');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarMessage('Échec de la suppression du matériau');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
        }
        closeDeleteModal();
    };

    const openDialog = (item) => {
        setSelectedItem(item);
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setSelectedItem(null);
        setIsDialogOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'name', headerName: 'Nom', flex: 1 },
        { field: 'date_acquisition', headerName: 'Date Acquisition', flex: 1 },
        { field: 'prix_acquisition', headerName: 'Prix Acquisition', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            sortable: false,
            width: 200,
            renderCell: (params) => (
                <div>
                    <>
                        <IconButton
                            color="success"
                            onClick={() => openDialog(params.row)}
                        >
                            <OpenInFullIcon />
                        </IconButton>
                    </>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les matériels")) && (
                        <>
                            <IconButton
                                component={Link}
                                color="info"
                                to={`/moyens-materiels/materiels/update/${params.row.id}`}
                            >
                                <BorderColorIcon />
                            </IconButton>
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les matériels")) && (
                        <>
                            <IconButton
                                onClick={() => openDeleteModal(params.row)}
                                style={{ marginLeft: "8px" }}
                                color="error"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const createFactureButton = (
        <Link to={`/moyens-materiels/materiels/create`} className="text-decoration-none">
            <MDButton
                variant="contained"
                color="info"
                size='small'
            >
                <ControlPointIcon sx={{ mr: "12px" }} />
                ajouter
            </MDButton>
        </Link>
    );
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les matériels")) ? (
                <Container maxWidth="md">
                    <Typography variant="h2" textAlign={"center"} mb={5} gutterBottom>
                        Liste de matériel
                    </Typography>

                    {materials.length === 0 ? (
                        <>
                            <Link to={`/moyens-materiels/materiels/create`} className="text-decoration-none float-end">
                                <MDButton variant="contained" color="success" >
                                    <ControlPointIcon sx={{ mr: "10px" }} />
                                    ajouter
                                </MDButton>
                            </Link>
                            <Typography variant="body1">Aucun matériel disponible.</Typography>
                        </>
                    ) : (
                        <Box>
                            <Box
                                m="0 0 0 0"
                                height="75vh"
                            >
                                <DataGrid
                                    rows={materials}
                                    columns={columns}
                                    components={{
                                        Toolbar: (props) => (
                                            <div className='d-flex'>
                                                {createFactureButton}
                                                <GridToolbar {...props} />
                                            </div>
                                        ),
                                    }}
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                                />
                            </Box>
                        </Box>
                    )}
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
                <DialogTitle>Confirmation de la suppression</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Êtes-vous sûr de vouloir supprimer ce matériel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteModal}>Annuler</Button>
                    <Button onClick={handleDelete} sx={{ color: "red !important" }} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                {selectedItem && (
                    <>
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Détails de la matériel
                        </DialogTitle>
                        <DialogContent>
                            {selectedItem ? (
                                <Grid container spacing={2}>
                                    <Grid item md={6}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="h6" color="info" gutterBottom>
                                                    NOM:
                                                </Typography>
                                                <Typography>{selectedItem?.name}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                <Typography variant="h6" color="info" gutterBottom>
                                                    TYPE:
                                                </Typography>
                                                <Typography>{selectedItem?.type}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Card variant="outlined">
                                            <CardContent sx={{ textAlign: 'center' }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Avatar>
                                                        <PaidIcon />
                                                    </Avatar>
                                                    <Typography variant="h6" color="info" gutterBottom>
                                                        PRIX ACQUISITION 
                                                    </Typography>
                                                </Stack>
                                                <Typography component="div" variant="h5" gutterBottom>
                                                    {selectedItem?.prix_acquisition}&nbsp;
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="success"
                                                        sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}
                                                    >
                                                        MAD
                                                    </Typography>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item md={6}>
                                        <Card variant="outlined">
                                            <CardContent sx={{ textAlign: 'center' }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Avatar>
                                                        <CalendarMonthIcon />
                                                    </Avatar>
                                                    <Typography variant="h6" color="info" gutterBottom>
                                                        DATE ACQUISITION
                                                    </Typography>
                                                </Stack>
                                                <Typography component="div" variant="h5" gutterBottom>
                                                    {selectedItem?.date_acquisition}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Typography>Oups, il y a peut-être une erreur</Typography>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default MaterialsByType;


