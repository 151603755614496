import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllTache, deleteTache } from '../../actions/TacheActions';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    IconButton,
    Tooltip,
} from '@mui/material';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from '../../components/MDButton';
import { Delete } from '@mui/icons-material';
const TasksList = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.taches) || [];
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [pageSize, setPageSize] = useState(25);
    const [filteredItems, setFilteredItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const isMobile = useMediaQuery('(max-width:600px)');

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);

    useEffect(() => {
        dispatch(getAllTache())
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch]);

    useEffect(() => {
        if (Array.isArray(tasks)) {
            const filteredItems = tasks?.filter((task) => {
                const numero = task?.projet?.numero || '';
                const nom = task.user?.nom || '';
                const prenom = task.user?.prenom || '';
                return (
                    nom.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    numero.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    prenom.toLowerCase().includes(searchTerm.toLowerCase())
                );
            });

            setFilteredItems(filteredItems);
        }
    }, [tasks, searchTerm]);

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete))
            .then(() => {
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const columns = [
        {
            field: 'projet?.numero', headerName: 'Projet',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'user?.nom', headerName: 'Personnel',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.user?.nom || '',
        },
        {
            field: 'projet?.reference_fonciere',
            headerName: 'Référence',
            flex: 1,
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        { field: 'name', headerName: 'Tâche', ...(isMobile ? { width: 120 } : { flex: 1 }), },
        {
            field: 'status',
            headerName: 'Status',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
            valueGetter: (params) => params.row.status || '-',
            renderCell: (params) => {
                let statusLabel, statusColor;

                switch (params.row.status) {
                    case 'pending':
                        statusLabel = 'En cours';
                        statusColor = 'orange';
                        break;
                    case 'confirmed':
                        statusLabel = 'Validée';
                        statusColor = 'green';
                        break;
                    case 'rejected':
                        statusLabel = 'Rejetée';
                        statusColor = 'red';
                        break;
                    default:
                        statusLabel = "Dans la liste d'attente";
                        statusColor = 'black';
                }

                return (
                    <span style={{ backgroundColor: statusColor, color: "#fff" }}>{statusLabel}</span>
                );
            },
        },
        {
            field: 'deadline', headerName: "Date d'échéance",
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'actions',
            headerName: 'Supprimer',
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les tâches")) && (
                            <Tooltip title='Supprimer' arrow>
                                <IconButton
                                    variant="contained"
                                    size='small'
                                    color="error"
                                    onClick={() => handleDeleteClick(params.row.id)}
                                >
                                    <Delete fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },

    ];

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box>
                {/* <Typography variant="h2" mt={2} textAlign={'center'} gutterBottom>
                    Historique des tâches
                </Typography> */}
                <Typography
                        sx={{
                            fontSize: '36px',
                            textAlign: 'center',
                            marginTop: '12px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                        variant="h2"
                        gutterBottom
                        mt={2}
                        mb={2}
                    >
                        Historique des tâches
                    </Typography>
                <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Rechercher par numéro du projet ou par presonnel"
                    onChange={handleSearch}
                    value={searchTerm}
                    name="Search Project"
                    sx={{
                        gridColumn: "span 2",
                        marginBottom: '1rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        "& .MuiInputLabel-root": { paddingLeft: '1rem', paddingRight: '1rem' },
                    }}
                />
                <DataGrid
                    className={darkMode ? "text-light" : "text-dark"}
                    rows={filteredItems ?? []}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    components={{ Toolbar: GridToolbar }}
                    loading={loading}
                    autoHeight
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle
                    textAlign={'center'}
                    sx={{
                        backgroundColor: "red",
                        color: '#fff'
                    }}
                >
                    Supprimer la tâche
                </DialogTitle>
                <DialogContent>
                    <Typography mt={2} variant="h6">
                        Êtes-vous sûr de vouloir supprimer cette tâche ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Annuler</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={handleDeleteConfirm} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default TasksList;
