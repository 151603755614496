import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createProject } from '../../actions/ProjetActions';
import { getAllCommercials } from '../../actions/ContactActions';
import { getAllNatures } from '../../actions/NatureActions';
import { getAllPieces } from '../../actions/PieceActions';
import { Link, useLocation } from 'react-router-dom';
import { getAllClients } from '../../actions/ClientActions';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import {
    Button,
    Checkbox,
    Container,
    Card,
    Grid,
    FormControlLabel,
    FormGroup,
    Box,
    MenuItem,
    Autocomplete,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@mui/material";
import AddAdvance from "./AddAdvance";
import FileUploadComponent from "./UploadFiles";
import AssignTache from './AssignProject';
function AddProject({
    currentUser,
    natures,
    pieces,
    commercials,
    clients,
    getAllNatures,
    getAllPieces,
    getAllCommercials,
    getAllClients,
    createProject
}) {
    const [data, setData] = useState(null);
    const [isAdvancesDialogOpen, setIsAdvancesDialogOpen] = useState(false);
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [isTasksDialogOpen, setIsTasksDialogOpen] = useState(false);
    const [state, setState] = useState({
        reference_fonciere: '',
        date_creation: '',
        situation: '',
        latitude: '',
        longitude: '',
        prix: '',
        statut: '',
        paiement: '',
        observation: '',
        facture: '',
        prop_dit: '',
        clientId: '',
        commercialId: '',
        selectedNatures: [],
        selectedPieces: [],
        userId: '',
        error: '',
        submitted: false,
        selectedOption: "none",
        showForm: false,
        filterText: '',
        queryClientId: null,
        queryClientName: "",
    });
    const location = useLocation();
    const { clientId, clientName } = location.state || { clientId: null, clientName: "" };

    useEffect(() => {
        getAllNatures();
        getAllPieces();
        getAllCommercials();
        getAllClients();
    }, [getAllNatures, getAllPieces, getAllCommercials, getAllClients]);

    useEffect(() => {
        if (!state.clientId && location.state && location.state.from === "/clients/create") {
            setState({ ...state, clientId: location.state.clientId });
        }
    }, []);

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    // const handleClientChange = (selectedOption) => {
    //     setState({ ...state, clientId: selectedOption ? selectedOption.value : '' });
    // };

    const handleNatureChange = (event) => {
        const { selectedNatures } = state;
        const natureId = parseInt(event.target.value, 10);

        if (event.target.checked) {
            setState({
                ...state,
                selectedNatures: [...selectedNatures, natureId],
            });
        } else {
            setState({
                ...state,
                selectedNatures: selectedNatures.filter((id) => id !== natureId),
            });
        }
    };

    const handlePieceChange = (event) => {
        const { selectedPieces } = state;
        const pieceId = parseInt(event.target.value, 10);

        if (event.target.checked) {
            setState({
                ...state,
                selectedPieces: [...selectedPieces, pieceId],
            });
        } else {
            setState({
                ...state,
                selectedPieces: selectedPieces.filter((id) => id !== pieceId),
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const projectData = {
            reference_fonciere: state.reference_fonciere,
            date_creation: new Date(),
            situation: state.situation,
            geometrie: state.longitude && state.latitude
                ? {
                    type: 'Point',
                    coordinates: [parseFloat(state.longitude), parseFloat(state.latitude)],
                }
                : null,
            prop_dit: state.prop_dit,
            prix: state.prix,
            statut: state.statut || 'preparation',
            paiement: 'non',
            observation: state.observation,
            facture: state.facture || 'non',
            clientId: state.clientId,
            commercialId: state.commercialId,
            naturesIds: state.selectedNatures,
            piecesIds: state.selectedPieces,
            userId: currentUser.id,
        };

        Object.keys(projectData).forEach((key) => {
            if (projectData[key] === '') {
                projectData[key] = null;
            }
        });


        createProject(projectData)
            .then((response) => {
                setData(response.data);
                setState({
                    ...state,
                    reference_fonciere: '',
                    date_creation: '',
                    situation: '',
                    latitude: '',
                    longitude: '',
                    prop_dit: '',
                    prix: '',
                    statut: '',
                    paiement: '',
                    observation: '',
                    facture: '',
                    clientId: '',
                    commercialId: '',
                    selectedNatures: [],
                    selectedPieces: [],
                    userId: '',
                    error: '',
                    submitted: true,
                });

            })
            .catch((error) => {
                setState({ ...state, error: error.message });
            });
    };

    const handleOptionChange = (event) => {
        const selectedOption = event.target.value;
        const showForm = selectedOption !== "none";
        setState({
            ...state,
            selectedOption,
            showForm
        });
    };

    const clientOptions = clients?.map((client) => ({
        value: client.id,
        label: client.full_name,
    }));

    const defaultClientOption = clientId ? clientOptions.find((option) => option.value === clientId) : clientId;

    const newClient = () => {
        setState({
            reference_fonciere: '',
            date_creation: '',
            situation: '',
            latitude: '',
            longitude: '',
            prix: '',
            statut: '',
            prop_dit: '',
            paiement: '',
            observation: '',
            facture: '',
            clientId: '',
            commercialId: '',
            selectedNatures: [],
            selectedPieces: [],
            userId: '',
            error: '',
            submitted: false,
            selectedOption: "none",
            showForm: false,
            filterText: '',
            queryClientId: null,
            queryClientName: "",
        });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des projets")) ? (
                <Container>
                    <Typography
                        variant="h2"
                        textAlign="center"
                        mb={1}
                        sx={{
                            fontSize: '36px',
                            fontWeight: 'bold',
                            color: '#3e4396',
                            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                        }}
                    >
                        Créer un projet
                    </Typography>
                    {state.submitted ? (
                        <div className="text-center">
                            <Container>
                                <Box mt={2}>
                                    <Card sx={{ p: 3, py: 4 }}>
                                        <Box textAlign="center" mt={2}>
                                            <Typography variant="h4" mb={2}
                                                sx={{
                                                    fontSize: '29px',
                                                    fontWeight: 'bold',
                                                    color: '#4caf50',
                                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                                                }}
                                            >
                                                Votre projet a été créé avec succès!
                                            </Typography>
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    fontWeight: "bold",
                                                    borderRadius: "rounded",
                                                    color: "#3e4396",
                                                }}
                                            >
                                                PROJET : {data?.numero}
                                            </Typography>
                                            <Box mt={3}>
                                                <Grid container spacing={2} justifyContent="center">
                                                    <Grid item xs={12} sm={6}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#4caf50",
                                                                color: "#fff",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                                padding: "10px 20px",
                                                                margin: "16px",
                                                            }}
                                                            onClick={newClient}
                                                        >
                                                            Ajouter un autre
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#4caf50",
                                                                color: "#fff",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                                padding: "10px 20px",
                                                                margin: "16px",
                                                            }}
                                                            onClick={() => setIsTasksDialogOpen(true)}
                                                        >
                                                            Attribuer des tâches
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#4caf50",
                                                                color: "#fff",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                                padding: "10px 20px",
                                                                margin: "16px",
                                                            }}
                                                            onClick={() => setIsAdvancesDialogOpen(true)}
                                                        >
                                                            Ajouter une avance
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                backgroundColor: "#4caf50",
                                                                color: "#fff",
                                                                fontSize: "14px",
                                                                fontWeight: "bold",
                                                                padding: "10px 20px",
                                                                margin: "16px",
                                                            }}
                                                            onClick={() => setIsUploadDialogOpen(true)}
                                                        >
                                                            Importer des fichiers
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Box>
                                <Dialog
                                    open={isAdvancesDialogOpen}
                                    onClose={() => setIsAdvancesDialogOpen(false)}
                                    fullWidth
                                    maxWidth="md"
                                >
                                    <>
                                        <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                            Ajouter un Avance
                                        </DialogTitle>
                                        <DialogContent>
                                            <AddAdvance currentUser={currentUser} numero={data?.numero} />
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => setIsAdvancesDialogOpen(false)} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </>
                                </Dialog>
                                <Dialog
                                    open={isUploadDialogOpen}
                                    onClose={() => setIsUploadDialogOpen(false)}
                                    fullWidth
                                    maxWidth="md"
                                >
                                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                        Importation de fichiers
                                    </DialogTitle>
                                    <DialogContent>
                                        <h4 className="ms-4 mt-2">Importation de fichiers</h4>
                                        <FileUploadComponent projectId={data?.id} />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setIsUploadDialogOpen(false)} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={isTasksDialogOpen}
                                    onClose={() => setIsTasksDialogOpen(false)}
                                    fullWidth
                                    maxWidth="md"
                                >
                                    <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                        Attribuer des tâches
                                    </DialogTitle>
                                    <DialogContent>
                                        <AssignTache projectId={data?.id} />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setIsTasksDialogOpen(false)} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Container>
                        </div>
                    ) : (
                        <div className="mt-3">
                            <form className="row" onSubmit={handleSubmit}>
                                <div className="col-sm-6">
                                    <TextField
                                        label="Reference Fonciere"
                                        name="reference_fonciere"
                                        value={state.reference_fonciere}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                    <TextField
                                        label="Situation"
                                        name="situation"
                                        value={state.situation}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                    <TextField
                                        label="Latitude"
                                        name="latitude"
                                        value={state.latitude}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                    <TextField
                                        fullWidth
                                        name="paiement"
                                        label='Paiement (par defaut Non)'
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                        select
                                        disabled
                                    >
                                        <MenuItem value="non">Non</MenuItem>
                                        <MenuItem value="oui">Oui</MenuItem>
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        name="facture"
                                        label='Facturé'
                                        value={state.facture}
                                        onChange={handleChange}
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                        select
                                    >
                                        <MenuItem value="non">Non</MenuItem>
                                        <MenuItem value="oui">Oui</MenuItem>
                                    </TextField>
                                    <TextField
                                        label="Observation"
                                        name="observation"
                                        value={state.observation}
                                        onChange={handleChange}
                                        multiline
                                        fullWidth
                                        rows={4}
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Autocomplete
                                        options={clientOptions}
                                        getOptionLabel={(option) => option.label}
                                        fullWidth
                                        value={clientOptions.find((option) => option.value === state.clientId) || null}
                                        onChange={(event, newValue) => {
                                            setState({ ...state, clientId: newValue ? newValue.value : '' });
                                        }}
                                        isSearchable
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Client"
                                                placeholder="Filter clients by name..."
                                                fullWidth
                                                required
                                                sx={{ marginBottom: 3 }}
                                                variant="standard"
                                            />
                                        )}

                                    />

                                    <TextField
                                        label="Prix"
                                        name="prix"
                                        value={state.prix}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                    <TextField
                                        label="Longitude"
                                        name="longitude"
                                        value={state.longitude}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                    <TextField
                                        value={state.statut}
                                        onChange={handleChange}
                                        fullWidth
                                        name="statut"
                                        label='Statut'
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                        select
                                    >
                                        <MenuItem value="preparation">Preparation</MenuItem>
                                        <MenuItem value="terrain">Terrain</MenuItem>
                                        <MenuItem value="bureau">Bureau</MenuItem>
                                        <MenuItem value="controle">Controle</MenuItem>
                                        <MenuItem value="livraison">Livraison</MenuItem>
                                        <MenuItem value="cloture">Cloture</MenuItem>
                                    </TextField>
                                    <TextField
                                        value={state.selectedOption}
                                        onChange={handleOptionChange}
                                        fullWidth
                                        label='Par Contact'
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                        select
                                    >
                                        <MenuItem value="none" disabled>Select Option...</MenuItem>
                                        <MenuItem value="none">Non</MenuItem>
                                        <MenuItem value="oui">Oui</MenuItem>
                                    </TextField>
                                    {state.showForm && (
                                        <>
                                            <Autocomplete
                                                options={commercials || []}
                                                getOptionLabel={(commercial) => commercial.name}
                                                fullWidth
                                                value={commercials.find((commercial) => commercial.id === state.commercialId) || null}
                                                onChange={(event, newValue) => {
                                                    setState({ ...state, commercialId: newValue ? newValue.id : '' });
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Contact"
                                                        sx={{ marginBottom: 3 }}
                                                        variant="standard"
                                                    />
                                                )}

                                            />
                                        </>
                                    )}
                                    <TextField
                                        label="Propriété dite"
                                        name="prop_dit"
                                        value={state.prop_dit}
                                        onChange={handleChange}
                                        fullWidth
                                        sx={{ marginBottom: 3 }}
                                        variant="standard"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Typography>Nature</Typography>
                                    <FormGroup>
                                        {natures?.map((nature) => (
                                            <FormControlLabel
                                                key={nature.id}
                                                control={
                                                    <Checkbox
                                                        name={nature.name}
                                                        value={nature.id}
                                                        checked={state.selectedNatures.includes(nature.id)}
                                                        onChange={handleNatureChange}
                                                    />
                                                }
                                                label={nature.name}
                                            />
                                        ))}
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <Typography>Pieces</Typography>
                                    <FormGroup>
                                        {pieces?.map((piece) => (
                                            <FormControlLabel
                                                key={piece.id}
                                                control={
                                                    <Checkbox
                                                        name={piece.name}
                                                        value={piece.id}
                                                        checked={state.selectedPieces.includes(piece.id)}
                                                        onChange={handlePieceChange}
                                                    />
                                                }
                                                label={piece.piece}
                                            />
                                        ))}
                                    </FormGroup>
                                </div>
                                <div className="mt-3 form-group">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: "#3e4396",
                                            color: "#e0e0e0",
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            padding: "10px 20px",
                                        }}
                                    >
                                        Ajouter un projet
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )
                    }
                </Container >
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
        </DashboardLayout >
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.auth.user,
    natures: state.nature.natures,
    commercials: state.commercial.commercials,
    pieces: state.piece.pieces,
    clients: state.clients
});

const mapDispatchToProps = {
    getAllNatures,
    getAllPieces,
    getAllCommercials,
    getAllClients,
    createProject
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);


