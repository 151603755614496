import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPrimes } from '../../actions/PrimesActions';
import {
    Box,
    TextField,
    Typography,
    Container,
    Grid,
    Select,
    MenuItem,
    Snackbar,
    Alert,
} from '@mui/material';
import MDButton from '../../components/MDButton';
import imposablePrimes from './data/ImposableData';
import nonImposablePrimes from './data/NonImposableData';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AccessDenied from '../Errors/AccessDenied';

const SetPrimeTouser = ({ currentUser, userId }) => {
    const dispatch = useDispatch();

    const [primeData, setPrimeData] = useState({
        mois: '',
        userId: userId,
        primes: [],
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPrimeData({
            ...primeData,
            [name]: value,
        });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleAddPrime = () => {
        if (!primeData.name || !primeData.montant) return;
        const primeToAdd = {
            name: primeData.name,
            montant: primeData.montant,
            type: primeData.type,
        };
        setPrimeData({
            ...primeData,
            primes: [...primeData.primes, primeToAdd],
            name: '',
            montant: '',
        });
    };

    const handleRemovePrime = (index) => {
        const updatedSelectedPrimes = [...primeData.primes];
        updatedSelectedPrimes.splice(index, 1);
        setPrimeData({
            ...primeData,
            primes: updatedSelectedPrimes,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const primesToSend = primeData.primes;

        dispatch(createPrimes({ ...primeData, primes: primesToSend }))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('Premier(s) attribué(s) avec succès');
                setSnackbarOpen(true);
                setPrimeData({
                    mois: '',
                    userId: '',
                    primes: [],
                });
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(`Error creating prime: ${error.message}`);
                setSnackbarOpen(true);
            });
    };

    const renderPrimesList = () => {
        const primesList = primeData.type === 'imposable' ? imposablePrimes.imposable.names : nonImposablePrimes.nonImposable.names;
        const primesNotes = primeData.type === 'non imposable' ? nonImposablePrimes.nonImposable.notes : [];

        return (
            <Select
                label="Prime Name"
                name="name"
                value={primeData.name}
                onChange={handleInputChange}
                fullWidth
            // required
            >
                <MenuItem value="" disabled>
                    Select Prime
                </MenuItem>
                {primesList.map((prime, index) => (
                    <MenuItem key={index} value={prime}>
                        {prime} - {primesNotes[index]}
                    </MenuItem>
                ))}
            </Select>
        );
    };
    return (
        <>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des primes")) ? (
                <>
                    <Container maxWidth="lg">
                        <form onSubmit={handleSubmit}>
                            <Grid mt={2} container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Mois"
                                        type='month'
                                        name="mois"
                                        value={primeData.mois}
                                        onChange={handleInputChange}
                                        fullWidth
                                    // required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        label="Type"
                                        name="type"
                                        value={primeData.type}
                                        onChange={handleInputChange}
                                        fullWidth
                                    >
                                        <MenuItem value="non imposable">Non Imposable</MenuItem>
                                        <MenuItem value="imposable">Imposable</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    {renderPrimesList()}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Montant"
                                        type='number'
                                        name="montant"
                                        value={primeData.montant}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MDButton onClick={handleAddPrime} variant="contained" color="primary">
                                        Ajouter Prime
                                    </MDButton>
                                </Grid>
                            </Grid>
                            <div className='mt-2'>
                                <Typography variant="h5" textAlign={'center'} gutterBottom>
                                    Primes sélectionnées
                                </Typography>
                                <>

                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Prime</TableCell>
                                                    <TableCell align="right">Montant</TableCell>
                                                    <TableCell align="right">Type</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {primeData.primes.map((prime, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{prime.name}</TableCell>
                                                        <TableCell align="right">{prime.montant}</TableCell>
                                                        <TableCell align="right">{prime.type}</TableCell>
                                                        <TableCell align="right">
                                                            <MDButton
                                                                className='ms-2'
                                                                onClick={() => handleRemovePrime(index)}
                                                                variant="contained"
                                                                color="error"
                                                                size='small'
                                                            >
                                                                Remove
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            </div>
                            <Box mt={2}>
                                <MDButton type="submit" variant="contained" color="info">
                                    Create Prime
                                </MDButton>
                            </Box>
                        </form>
                    </Container>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                    >
                        <Alert
                            variant='filled'
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{ width: '100%' }}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </>
    );
};

export default SetPrimeTouser;
