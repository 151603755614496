import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPendingRequests, updateRequest } from '../../actions/RequestActions';
import {
    Box,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import { TaskAlt, DoneOutline, Clear } from '@mui/icons-material';

const PendingRequestsList = ({ currentUser, darkMode }) => {
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.request.requests);
    const [searchTerm, setSearchTerm] = useState('');
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isRejectionOpen, setRejectionOpen] = useState(false);
    const [selectedAvance, setSelectedAvance] = useState(null);

    useEffect(() => {
        dispatch(getAllPendingRequests());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };


    const openValModal = (avance) => {
        setSelectedAvance(avance);
        setConfirmationOpen(true);
    };

    const closeValModal = () => {
        setSelectedAvance(null);
        setConfirmationOpen(false);
    };


    const openRejModal = (avance) => {
        setSelectedAvance(avance);
        setRejectionOpen(true);
    };

    const closeRejModal = () => {
        setSelectedAvance(null);
        setRejectionOpen(false);
    };

    const handleValider = () => {
        if (selectedAvance) {
            dispatch(updateRequest(selectedAvance.id, { status: 'oui' }));
        }
        closeValModal();
    };

    const handleRejeter = () => {
        if (selectedAvance) {
            dispatch(updateRequest(selectedAvance.id, { status: 'non' }));
        }
        closeRejModal();
    };

    const columns = [
        {
            field: 'user?.nom', headerName: 'User', flex: 1,
            valueGetter: (params) => params.row.user?.nom || '',
        },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        { field: 'createdAt', headerName: "Date d'envoi", flex: 1 },
        { field: 'prix', headerName: 'Cout', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                    <MDButton
                        className='me-1'
                        size='small'
                        color='success'
                        onClick={() => openValModal(params.row)}
                    >
                        <DoneOutline />&nbsp;Valider
                    </MDButton>
                    <MDButton
                        className='ms-1'
                        size='small'
                        color='error'
                        onClick={() => openRejModal(params.row)}
                    >
                        <Clear />&nbsp;Rejeter
                    </MDButton>

                </>
            ),
            flex: 2,
            cellClassName: 'actions-cell',
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Typography variant="h2" mb={2} textAlign={'center'}>
                Liste des demandes d'avance sur salaire
            </Typography>
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Valider les avances de salaires")) ? (
                <Box m="20px">
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Search Project"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: "leaderboard",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "secondary",
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "primary",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: "secondary",
                            },
                            "& .MuiCheckbox-root": {
                                color: `leaderboard !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `primary !important`,
                            },
                        }}
                    >
                        <DataGrid
                            rows={requests.requests ?? []}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            style={{ color: darkMode ? "#fff" : "#000" }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}

                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
            <Dialog open={isConfirmationOpen} onClose={closeValModal}>
                <DialogTitle
                    sx={{
                        background: 'green',
                        textAlign: 'center',
                        color: '#fff',
                        marginBottom: '1rem'
                    }}
                >
                    <TaskAlt fontSize='medium' /> Confirmer
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to Confirm this Request or Reject it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-info btn-sm" onClick={closeValModal}>
                        Annuler
                    </Button>
                    <Button
                        sx={{
                            background: 'green',
                            color: '#fff !important',
                        }}
                        onClick={handleValider}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={isRejectionOpen} onClose={closeRejModal}>
                <DialogTitle
                    sx={{
                        background: 'red',
                        textAlign: 'center',
                        color: '#fff',
                        marginBottom: '1rem'
                    }}
                >
                    Rejeter
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to Confirm this Request or Reject it?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="btn btn-info btn-sm" onClick={closeRejModal}>
                        Annuler
                    </Button>
                    <Button
                        sx={{
                            background: 'red',
                            color: '#fff !important',
                        }} onClick={handleRejeter}>
                        Rejeter
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

export default PendingRequestsList;

