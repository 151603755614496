import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDAvatar from "../../components/MDAvatar";
import breakpoints from "../../assets/theme/base/breakpoints";
import burceMars from "../../assets/images/profile.jpg";
import backgroundImage from "../../assets/images/bg-profile.jpeg";
import { fetchUserFiles, fetchFileContent, uploadUserFiles, getUser } from "../../actions/UserActions";
import { useSelector, useDispatch } from 'react-redux';

function Header({ children, selectedTab, handleTabChange, currentUser }) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const dispatch = useDispatch();
  const files = useSelector((state) => state.users.files);
  const [profileImage, setProfileImage] = useState(burceMars);

  useEffect(() => {
    dispatch(fetchUserFiles(currentUser?.id));
  }, [dispatch, currentUser?.id]);


  useEffect(() => {
    const file = files.find(file => file?.startsWith(`${currentUser?.nom}`));

    if (file) {
      dispatch(fetchFileContent(currentUser?.id, file))
        .then(response => {
          const image = `data:image/png;base64,${response.data}`;
          setProfileImage(image);
        })
        .catch(error => {
          console.error('Error fetching profile image:', error);
        });
    }
  }, [files, dispatch, currentUser?.id, currentUser?.nom]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <MDAvatar src={profileImage} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {currentUser?.username}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="regular">
                {currentUser.roles}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs value={selectedTab} onChange={handleTabChange} centered>
                <Tab label="Profile" />
                <Tab label="Modifier" />
                <Tab label="Mot de passe" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {children}
      </Card>
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
