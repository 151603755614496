import React, { useState } from 'react';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";
import FileUpload from './UploadFiles';
import FactureFiles from './FactureFiles';
import {
    Typography,
    Button,
    Paper,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Container,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    IconButton,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { useSelector } from 'react-redux';
import MDButton from '../../components/MDButton';
import { encodeId } from '../../actions/Crypte';
import { ToWords } from 'to-words';
import StatisticsServices from "../../services/statisticsServices";
import GetAppIcon from '@mui/icons-material/GetApp';

const FacturePreview = ({ facture }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isFilesDialogOpen, setIsFilesDialogOpen] = useState(false);
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const prixHT = facture.items.length > 0
        ? facture.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
        : "-";
    const tva = 0.2 * (facture.items.length > 0
        ? facture.items.reduce((total, item) => total + item.quantity * item.prix_unitaire, 0)
        : "-");

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const openUploadDialog = (project) => {
        setSelectedItem(project);
        setUploadDialogOpen(true);
    };

    const closeUploadDialog = () => {
        setSelectedItem(null);
        setUploadDialogOpen(false);
    };

    const openFilesDialog = (project) => {
        setSelectedItem(project);
        setIsFilesDialogOpen(true);
    };

    const closeFilesDialog = () => {
        setSelectedItem(null);
        setIsFilesDialogOpen(false);
    };

    let statusLabel, statusColor;

    switch (facture?.statut) {
        case 'en_instance':
            statusLabel = 'En instance';
            statusColor = 'orange';
            break;
        case 'paye':
            statusLabel = 'Payé';
            statusColor = 'green';
            break;
        default:
            statusLabel = '-';
            statusColor = 'dark';
    }


    const toWords = new ToWords({ localeCode: 'fr-FR' });
    const [integerPart, decimalPart] = facture?.montant_ttc.split('.');
    let wordsIntegerPart = toWords.convert(integerPart);

    let wordsDecimalPart = '';
    if (decimalPart) {
        wordsDecimalPart = toWords.convert(decimalPart);
    }
    const getYearFromDate = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear().toString();
    }
    const factureNumber = facture.numero;
    const factureYear = getYearFromDate(facture.date);

    const handleExportArchive = async (folder) => {
        try {
            const response = await StatisticsServices.downloadArchive(folder);

            const blob = new Blob([response.data], { type: 'application/zip' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'archive.zip';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting archive:', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" textAlign="center" mt={4} mb={2}>
                Facture: {facture?.numero}&nbsp;
                <Typography
                    component="span"
                    variant="body1"
                    sx={{
                        backgroundColor: `${statusColor} !important`,
                        verticalAlign: 'top', fontSize: '0.9rem',
                        padding: '0.3rem'
                    }}
                >
                    {statusLabel}
                </Typography>
            </Typography>

            <div className="mb-2">
                <Typography variant="h5" textAlign="center" mb={1}>
                    {facture?.client?.full_name.toUpperCase()}
                </Typography>
            </div>

            <div className="mb-2">
                <Typography variant="subtitle1" textAlign="end">
                    Date: {formatDate(facture?.date)}
                </Typography>
            </div>

            <div className="text-muted">
                <Typography variant="body1" mb={1}>
                    <PhoneIcon /> : &nbsp; {facture?.client?.telephone}
                </Typography>
                <Typography variant="body1" mb={1}>
                    <LocationOnIcon /> : &nbsp;{facture?.client?.adresse || '-'}
                </Typography>
                <Typography variant="body1">
                    <ApartmentIcon /> : &nbsp;{facture?.client?.ice || '-'}
                </Typography>
            </div>

            <hr className="my-4" />

            <div className="row">
                <div className="col-sm-6">
                    <div className="text-muted">
                        <Typography variant="h5" mb={2}>
                            Objet: {facture?.objet || '-'}
                        </Typography>
                        {facture?.statut === 'paye' &&
                            <Typography variant="body1" mb={1}>
                                Date de Paiement: {formatDate(facture?.date_paiement)}
                            </Typography>
                        }
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="text-muted text-sm-end">
                        <div className="d-flex justify-content-end">
                            <Typography variant="h5" mb={1}>
                                Reference: {facture?.reference || '-'}
                            </Typography>
                        </div>
                        {facture?.statut === 'paye' &&
                            <Typography variant="body1" mb={1}>
                                Mode de Paiement: {facture?.mode_paiement || '-'}
                            </Typography>
                        }
                    </div>
                </div>
            </div>

            <div className="py-2">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "70px" }}>N°</TableCell>
                                <TableCell>DESIGNATION</TableCell>
                                <TableCell>P.U</TableCell>
                                <TableCell>Quantite</TableCell>
                                <TableCell className="text-end" style={{ width: "120px" }}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {facture?.items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <Typography variant="h5" className="text-truncate" mb={1}>
                                            {item?.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>{item?.prix_unitaire}</TableCell>
                                    <TableCell>{item?.quantity}</TableCell>
                                    <TableCell className="text-end">{item?.price}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Prix Total (H.T)</TableCell>
                                <TableCell className="text-end">{prixHT.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Montant de la TVA (20%) :</TableCell>
                                <TableCell className="text-end">{tva.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} className="text-end">Montant Total TTC</TableCell>
                                <TableCell className="text-end">
                                    <Typography variant="h4" fontWeight="bold" margin="0">
                                        {facture?.montant_ttc}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="d-print-none mt-4">
                    <div className="float-end">
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Modifier les factures")) && (
                            <MDButton
                                component={Link}
                                to={`/finances/factures/update/${encodeId(facture?.id)}`}
                                variant="contained"
                                color="success"
                                sx={{ marginRight: 1 }}
                            >
                                <BorderColorIcon />
                            </MDButton>
                        )}
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Importer les fichiers")) && (
                            <MDButton
                                className='me-1 ms-1'
                                size='small'
                                color='info'
                                onClick={() => openUploadDialog(facture)}
                            >
                                Importer fichiers
                            </MDButton>
                        )}
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Exporter l'archive")) && (
                            <MDButton
                                className='me-1 ms-1'
                                size='small'
                                color='info'
                                onClick={() => handleExportArchive(`factures.${factureYear}.F-${factureNumber}`)}
                            >
                                Archive
                            </MDButton>
                        )}
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les fichiers")) && (
                            <MDButton
                                className='me-1'
                                size='small'
                                color='warning'
                                onClick={() => openFilesDialog(facture)}
                            >
                                Fichiers
                            </MDButton>
                        )}
                    </div>
                </div>
            </div>

            <Dialog
                open={uploadDialogOpen}
                onClose={closeUploadDialog}
                fullWidth
                maxWidth="md"
            >
                {selectedItem && (
                    <>
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Importer
                        </DialogTitle>
                        <DialogContent>
                            <FileUpload factureId={selectedItem?.id} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeUploadDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
            <Dialog
                open={isFilesDialogOpen}
                onClose={closeFilesDialog}
                fullWidth
                maxWidth="md"
            >
                {selectedItem && (
                    <>
                        <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                            Fichiers
                        </DialogTitle>
                        <DialogContent>
                            <FactureFiles factureId={selectedItem?.id} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeFilesDialog} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </Container>
    );
}

export default FacturePreview;
