import React, { useEffect, useState } from 'react';
import {
    Typography,
    Card,
    CardContent,
    Grid,
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    List,
    Divider,
} from "@mui/material";
import FicheDePaiament from './FichePaiement';
import StatisticsServices from "../../services/statisticsServices";
import MDButton from '../../components/MDButton';
const ProjectsReview = ({ project, currentUser, darkMode }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };
    const dividerStyle = {
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#007bff',
        width: '100%',
        height: '5px',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    };

    const getYearFromDate = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear()?.toString();
    }

    const calculateProjectPrefix = (natures) => {
        const natureAbbreviations = {
            'Plan de Situation': 'PDS',
            'Plan de Délimitation': 'PDL',
            'Plan Côté': 'PCT',
            'Mise En Concordance et Copropriété': 'MCP',
            'Projet de Morcellement': 'PDM',
            'Parcellaire': 'PRC',
            'Mesurage': 'MSR',
            'Fusion': 'FUS',
            'Implantation': 'IMP',
            'Mise En Concordance': 'MEC',
            'Copropriété': 'COP',
            'Morcellement': 'MRC',
            'Lotissement': 'LOT',
        };
        let prefix = 'Proj';

        if (natures?.length === 1) {
            const firstNature = natures[0]?.name;
            const abbreviation = natureAbbreviations[firstNature];
            if (abbreviation) {
                prefix = abbreviation;
            } else {
                prefix = firstNature.substring(0, 3).toLowerCase();
            }
        }

        return prefix;
    };

    const natures = project?.nature;
    const prefix = calculateProjectPrefix(natures);
    const projectDate = new Date(project?.date_creation);
    const projectMonth = String(projectDate.getMonth() + 1).padStart(2, '0');
    const projectYear = projectDate?.getFullYear()?.toString();

    const projectNumber = project?.numero;

    // const projectNumber = project.numero;
    // const projectFol = projectNumber.split('/');
    // const projectYear = getYearFromDate(project.date_creation);

    const handleExportArchive = async (folder) => {
        try {
            const response = await StatisticsServices.downloadArchive(folder);

            const blob = new Blob([response.data], { type: 'application/zip' });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'archive.zip';

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
        } catch (error) {
            console.error('Error exporting archive:', error);
        }
    };
    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                                className={darkMode ? "text-light" : "text-dark"}
                            >
                                <div>
                                    {currentUser && currentUser.roles.includes("ROLE_ADMIN") && (
                                        <Typography variant="body1">
                                            <strong>Id :</strong>
                                        </Typography>
                                    )}
                                    <Typography variant="body1">
                                        <strong>Client :</strong>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Référence :</strong>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Situation :</strong>
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        <strong>Propriété dite :</strong>
                                    </Typography>
                                    {project?.nature && project?.nature.length > 0 && (
                                        <Typography variant="body1">
                                            <strong>Nature :</strong>
                                        </Typography>
                                    )}

                                    <Divider
                                        sx={dividerStyle}
                                    />
                                    <Typography variant="body1">
                                        <strong>Date de Création :</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Statut :</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Date de Clôture :</strong>
                                    </Typography>
                                    <Divider
                                        sx={dividerStyle}
                                    />
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">
                                            <strong>Prix :</strong>
                                        </Typography>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">
                                            <strong>Facturé :</strong>
                                        </Typography>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">
                                            <strong>Statut de paiement :</strong>
                                        </Typography>
                                    )}
                                    <Divider sx={dividerStyle} />
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts des projets")) && (
                                        <Typography variant="body1">
                                            <strong>Contact :</strong>
                                        </Typography>
                                    )}
                                </div>
                                <div>
                                    {currentUser && currentUser.roles.includes("ROLE_ADMIN") && (
                                        <Typography variant="body1">{project?.id || "-"}</Typography>
                                    )}
                                    <Typography variant="body1">{project?.client?.full_name || "-"}</Typography>
                                    <Typography variant="subtitle1">{project?.reference_fonciere || "-"}</Typography>
                                    <Typography variant="subtitle1">{project?.situation || "-"}</Typography>
                                    <Typography variant="subtitle1">{project?.prop_dit || "-"}</Typography>
                                    {project?.nature && project?.nature.length > 0 ? (
                                        <Typography variant="body1">
                                            <ul>
                                                {project?.nature.map((natures) => (
                                                    <ListItem key={natures.id}>
                                                        <ListItemText primary={natures.name} />
                                                    </ListItem>
                                                ))}
                                            </ul>
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">-</Typography>
                                    )}

                                    <Divider
                                        sx={dividerStyle}
                                    />
                                    <Typography variant="body1">{project?.date_creation || "-"}</Typography>
                                    <Typography variant="body1">{project?.statut || "-"}</Typography>
                                    <Typography variant="body1">{project?.date_cloture || "-"}</Typography>
                                    <Divider sx={dividerStyle} />
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">{project?.prix || "-"}</Typography>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">{project?.facture || "-"}</Typography>
                                    )}
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les prix des projets")) && (
                                        <Typography variant="body1">{project?.paiement || "-"}</Typography>
                                    )}
                                    <Divider
                                        sx={dividerStyle}
                                    />
                                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Consulter les contacts des projets")) && (
                                        <Typography variant="body1">{project?.projectContact?.name || "-"}</Typography>
                                    )}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Grid container spacing={2} p={2}>
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Imprimer le reçu de paiement")) && (
                        <>
                            {project?.paiement === 'oui' &&
                                <Grid item xs={6} sm={6}>
                                    <MDButton
                                        className='mb-2 '
                                        color='info'
                                        fullWidth
                                        onClick={openDialog}
                                    > Reçu de paiement</MDButton>
                                </Grid>
                            }
                        </>
                    )}
                    {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Exporter l'archive")) && (
                        <Grid item xs={6} sm={6}>
                            <MDButton color='info'
                                className='mb-2 '
                                fullWidth
                                onClick={() => handleExportArchive(`projects.${projectYear}.${projectMonth}-${projectYear}.D${projectNumber}-${prefix}`)}
                            > Archive</MDButton>
                        </Grid>
                    )}
                </Grid>
            </Card>
            <Dialog
                open={isDialogOpen}
                onClose={closeDialog}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '24px' }}>
                    Reçu de paiement
                </DialogTitle>
                <DialogContent>
                    <FicheDePaiament projet={project} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default ProjectsReview;