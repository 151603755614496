import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getHoliday, updateHoliday } from "../../actions/HolidaysActions";
import {
    Container,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Snackbar,
    Alert,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import MDButton from "../../components/MDButton";
const UpdateHoliday = ({ currentUser }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const holiday = useSelector((state) => state.holiday.holiday);

    const [formData, setFormData] = useState({
        requestDays: '',
        requestDate: '',
        startDate: '',
        endDate: '',
        isPaid: "",
        cause: '',
        status: "pending",
    });

    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateHoliday(id, formData))
            .then(() => {
                setSnackbarSeverity('success');
                setSnackbarMessage('congé mis à jour avec succès!');
                setSnackbarOpen(true);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(`Error updating Holiday: ${error.message}`);
                setSnackbarOpen(true);
            });
    };

    useEffect(() => {
        dispatch(getHoliday(id));
    }, [dispatch, id]);
    console.log(holiday);
    useEffect(() => {
        setFormData({
            requestDays: holiday?.requestDays,
            requestDate: holiday?.requestDate,
            startDate: holiday?.startDate,
            endDate: holiday?.endDate,
            isPaid: currentUser.roles.includes("ROLE_ADMIN") ? holiday?.isPaid : "",
            cause: holiday?.cause,
            status: currentUser.roles.includes("ROLE_ADMIN") ? holiday?.status : "pending",
        });
    }, [holiday]);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Container maxWidth="md">
                <Typography variant="h2" textAlign={'center'} gutterBottom>
                    Mettre à jour le congé
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        className="mt-3"
                        label="Request Days"
                        fullWidth
                        name="requestDays"
                        value={formData.requestDays}
                        onChange={(e) => setFormData({ ...formData, requestDays: e.target.value })}
                    />

                    {/* <TextField
                        className="mt-3"
                        label="Request Date"
                        fullWidth
                        type="date"
                        name="requestDate"
                        value={formData.requestDate}
                        onChange={(e) => setFormData({ ...formData, requestDate: e.target.value })}
                    /> */}

                    <TextField
                        className="mt-3"
                        label="Start Date"
                        fullWidth
                        type="date"
                        name="startDate"
                        value={formData.startDate}
                        onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                    />

                    <TextField
                        className="mt-3"
                        label="End Date"
                        fullWidth
                        type="date"
                        name="endDate"
                        InputLabelProps={{ shrink: true }}
                        value={formData.endDate}
                        onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                    />

                    {currentUser.roles.includes("ROLE_ADMIN") && (
                        <FormControl className="mt-3" fullWidth>
                            <InputLabel>Is Paid</InputLabel>
                            <Select

                                name="isPaid"
                                value={formData.isPaid}
                                onChange={(e) => setFormData({ ...formData, isPaid: e.target.value })}
                            >
                                <MenuItem value="oui">Oui</MenuItem>
                                <MenuItem value="non">Non</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        className="mt-3"
                        label="Cause (Optional)"
                        fullWidth
                        name="cause"
                        value={formData.cause}
                        onChange={(e) => setFormData({ ...formData, cause: e.target.value })}
                    />

                    {currentUser.roles.includes("ROLE_ADMIN") && (
                        <FormControl className="mt-3" fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select

                                name="status"
                                value={formData.status}
                                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                            >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <MDButton
                        className="mt-3"
                        type="submit"
                        variant="contained"
                        color="dark"
                    // fullWidth
                    >
                        Update Holiday
                    </MDButton>
                </form>

                <Snackbar
                    open={isSnackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        severity={snackbarSeverity}
                        onClose={handleSnackbarClose}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </DashboardLayout>
    );
};

export default UpdateHoliday;
