import axios from 'axios';
import store from '../app/store';
import { logout } from '../actions/UserActions';

const AxiosInstance = axios.create({
    // baseURL: "http://localhost:8000/api",
    // baseURL: "https://backend.metic.ma:8000/api",
    // baseURL: "https://data.saftuing.com:8001/api",
    // baseURL: "https://gestapp.metic.ma:8002/api",
    baseURL: "https://test.geomaping.net/api",
    // baseURL: "https://survback.syscamp.ma:8011/api",
    headers: {
        "Content-type": "application/json",
        // "X-App-Type": "Web",
    },
});

AxiosInstance.interceptors.request.use(
    (config) => {
        const { auth } = store.getState();
        const { accessToken } = auth.user || {};

        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response && response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);


export default AxiosInstance;
