import API_URL from './apiUrl';

class UserServices {
    register(userdata) {
        return API_URL.post("/auth/signup", userdata);
    }
    login(userdata) {
        return API_URL.post("/auth/signin", userdata)
            .then((response) => {
                if (response.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }

                return response.data;
            });
    };

    logout = () => {
        localStorage.removeItem("user");
    };

    getAllUsers() {
        return API_URL.get("/auth/users");
    };

    updateUser(id, data) {
        return API_URL.put(`/auth/users/${id}`, data);
    };

    updateUserPassword(id, data) {
        return API_URL.put(`/auth/users/${id}/password`, data);
    };

    getUser(id) {
        return API_URL.get(`/auth/user/${id}`);
    };

    deleteUser(id) {
        return API_URL.delete(`/auth/users/delete/${id}`);
    };

    updateUserPermissions(id, permissionIds) {
        return API_URL.put(`/auth/user/${id}/permissions/update`, permissionIds);
    };

    getUserPerformanceIndex() {
        return API_URL.get('/auth/users/performance/index');
    }

    uploadFilesToUser = (userId, formData) => {
        return API_URL.post(`/auth/users/${userId}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getAllUserFiles = (userId) => {
        return API_URL.get(`/auth/users/${userId}/files`);
    }

    getFileContent = (userId, fileName) => {
        return API_URL.get(`/auth/users/${userId}/files/${fileName}`);
    }

    downloadFile = (userId, fileName) => {
        return API_URL.get(`/auth/users/${userId}/download/${fileName}`, {
            responseType: 'blob',
        });
    };

    deleteFile = (userId, fileName) => {
        return API_URL.delete(`/auth/users/${userId}/delete/${fileName}`);
    }

}

export default new UserServices();