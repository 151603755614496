import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../actions/UserActions";
import { createTache } from "../../actions/TacheActions";
import {
  Container,
  Grid,
  Select,
  MenuItem,
  TextField,
  Card,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  DialogTitle,
} from "@mui/material";
import MDButton from "../../components/MDButton";
import UserPendingTaches from "../personnel/PendingTaches";

const AssignTache = ({ projectId }) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [formData, setFormData] = useState({
    userId: "",
    name: "preparation",
    deadline: "",
    note: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { userId, name, deadline, note } = formData;

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const tacheData = {
      projetId: projectId,
      userId,
      name,
      deadline,
      note,
    };

    dispatch(createTache(tacheData))
      .then((response) => {
        setSuccessMessage("Tâche assignée avec succès.");
        setErrorMessage("");
        setIsSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Erreur lors de l'attribution de la tâche :", error);
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Erreur lors de l'attribution de la tâche.");
        }
        setSuccessMessage("");
        setIsSnackbarOpen(true);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  return (
    <>
      <Container maxWidth="md">
        <Card elevation={3} style={{ padding: "20px" }}>
          <form className="mt-3" onSubmit={handleFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  select
                  label='Employé'
                  name="userId"
                  value={userId}
                  onChange={handleInputChange}
                  required
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select User
                  </MenuItem>
                  {Array.isArray(users) && users.length > 0 && users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user?.nom}&nbsp;{user?.prenom}
                      &nbsp;, tâches actuelles:&nbsp;{user?.taches?.length}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {formData?.userId &&
                <Grid item xs={12}>
                  <MDButton
                    variant="contained"
                    color="success"
                    onClick={() => setIsDialogOpen(true)}
                    fullWidth
                  >
                    tâches actuelles
                  </MDButton>
                </Grid>
              }
              <Grid item xs={12}>
                <Select
                  name="name"
                  value={name}
                  onChange={handleInputChange}
                  required
                  fullWidth
                >
                  <MenuItem value="preparation">Preparation</MenuItem>
                  <MenuItem value="terrain">Terrain</MenuItem>
                  <MenuItem value="bureau">Bureau</MenuItem>
                  <MenuItem value="controle">Controle</MenuItem>
                  <MenuItem value="livraison">Livraison</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="date"
                  label='date d échéance'
                  name="deadline"
                  value={deadline}
                  onChange={handleInputChange}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="note"
                  value={note}
                  onChange={handleInputChange}
                  fullWidth
                  label="Note (optional)"
                />
              </Grid>
              <Grid item xs={12}>
                <MDButton
                  type="submit"
                  variant="contained"
                  color="info"
                  fullWidth
                >
                  Assign Task
                </MDButton>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Container>
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
        maxWidth="100%"
      >
        <DialogTitle className="text-center" style={{ fontSize: "29px" }}>
          Liste des tâches actuelles
        </DialogTitle>
        <DialogContent>
          <Box>
            <UserPendingTaches userId={formData?.userId} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        {errorMessage ? (
          <Alert onClose={handleSnackbarClose} severity="error" variant="filled">
            {errorMessage}
          </Alert>
        ) : (
          <Alert onClose={handleSnackbarClose} severity="success" variant="filled">
            {successMessage}
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

export default AssignTache;
