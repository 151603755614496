import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createFacture } from "../../actions/FactureActions";
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Snackbar,
    Alert,
    Tooltip,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { updateAvance, getProjectAdvances } from '../../actions/EncaissementActions';
import { updateJusOnProjet, fetchProject } from '../../actions/ProjetActions';

const FactureAvance = ({ avances, projet, handleClose, totalHt, handelCloseAll }) => {
    const dispatch = useDispatch();
    let objet = 'Prestations Topographiques';
    const date = new Date();
    const [buttonShow, setButtonShow] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const prixHT = totalHt;
    const prixProjet = projet?.factures?.reduce((total, item) => total + parseFloat(item.montant_ttc), 0)

    console.log(prixProjet);

    const items = {
        name: projet?.nature?.map(nature => nature.name).join(' et ') || 'Default Name',
        unite: 'unite',
        prix_unitaire: parseFloat(prixProjet),
        price: parseFloat(prixProjet) * parseFloat(1),
        quantity: parseFloat(1),
    } || [];
    
    // const items = projet?.nature?.map((nature) => ({
    //     name: nature.name,
    //     unite: 'unite',
    //     prix_unitaire: parseFloat(prixHT),
    //     price: parseFloat(prixHT) * parseFloat(1),
    //     quantity: parseFloat(1),
    // })) || [];

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    };

    const tva = prixHT * 0.2;

    let statut = '';

    if (projet?.paiement === 'oui') {
        statut = 'paye';
    } else {
        statut = 'en_instance';
    }
    let statusLabel, statusColor;

    switch (avances[0]?.status) {
        case 'oui':
            statusLabel = 'Payé';
            statusColor = 'green';
            break;
        case 'non':
            statusLabel = 'En instance';
            statusColor = 'orange';
            break;
        default:
            statusLabel = '-';
            statusColor = 'dark';
    }

    // let montant = projet?.prix * 1.2;
    const handleCreateFacture = () => {
        setButtonShow(false);
        const Data = {
            date: date,
            objet: objet,
            clientId: projet.clientId,
            items: items,
            statut: 'paye',
            reference: projet?.numero,
            projetId: projet?.id,
        };

        dispatch(createFacture(Data))
            .then((response) => {
                const updatePromises = avances.map((avance) => {
                    return dispatch(updateAvance(avance.id, { facture: 'oui', }));
                });
                // if(prixProjet === totalHt) {
                //     dispatch(updateJusOnProjet(projet?.id, { facture: 'oui' }));
                // } else {
                //     dispatch(updateJusOnProjet(projet?.id, { facture: 'oui' }));
                // }
                dispatch(getProjectAdvances(projet?.id));
                dispatch(fetchProject(projet?.id));
                Promise.all(updatePromises)
                    .then(() => {
                        setSnackbarMessage("La facture a été créée avec succès.");
                        setSnackbarSeverity("success");
                        setOpenSnackbar(true);
                        // handleClose();
                        // handelCloseAll();
                    })
                    .catch((error) => {
                        setSnackbarMessage("Erreur dans la mise à jour des avances vers facturées.");
                        setSnackbarSeverity("error");
                        console.error("Erreur dans la mise à jour des avances vers facturées", error);
                    });
            })
            .catch((error) => {
                setSnackbarMessage("Erreur lors de la création de la facture : " + error.response?.data?.message || "");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            });
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };
    const isAnyFactured = avances.some((avance) => avance.facture === 'oui');

    return (
        <>
            {projet?.nature?.length > 0 ? (
                <>
                    <Container>
                        <div className="mb-2">
                            <Typography variant="h5" textAlign="center" mb={1}>
                                {projet?.client?.full_name.toUpperCase()} &nbsp;
                                <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{
                                        backgroundColor: `green !important`,
                                        verticalAlign: 'top',
                                        fontSize: '0.8rem',
                                        padding: '0.3rem',
                                        color: '#fff !important',
                                    }}
                                >
                                    Payé
                                </Typography>
                            </Typography>
                        </div>

                        <div className="mb-2">
                            <Typography variant="subtitle1" textAlign="end">
                                Date: {formatDate(date)}
                            </Typography>
                        </div>

                        <div className="text-muted">
                            <Typography variant="body1" mb={1}>
                                <PhoneIcon /> : &nbsp;{projet?.client?.telephone}
                            </Typography>
                            <Typography variant="body1" mb={1}>
                                <LocationOnIcon /> : &nbsp;{projet?.client?.adresse || '-'}
                            </Typography>
                            <Typography variant="body1">
                                <ApartmentIcon /> : &nbsp;{projet?.client?.ice || '-'}
                            </Typography>
                        </div>
                        <hr className="my-4" />
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="text-muted">
                                    <Typography variant="h5" mb={2}>
                                        Objet: {objet}
                                    </Typography>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="text-muted text-sm-end">
                                    <div className="d-flex justify-content-end">
                                        <Typography variant="h5" mb={1}>
                                            Référence: {projet?.numero || '-'}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="py-2">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: "70px" }}>N°</TableCell>
                                            <TableCell>DESIGNATION</TableCell>
                                            <TableCell>P.U</TableCell>
                                            <TableCell>Quantite</TableCell>
                                            <TableCell className="text-end" style={{ width: "120px" }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* {items?.map((item, index) => ( */}
                                            <TableRow>
                                                <TableCell>{1}</TableCell>
                                                <TableCell>
                                                    <Typography variant="h5" className="text-truncate" mb={1}>
                                                        {items?.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{prixHT.toFixed(2)}</TableCell>
                                                <TableCell>{items?.quantity}</TableCell>
                                                <TableCell className="text-end">{parseFloat(prixHT * items?.quantity).toFixed(2)}</TableCell>
                                            </TableRow>
                                        {/* ))} */}
                                        <TableRow>
                                            <TableCell colSpan={4} className="text-end">Prix Total (H.T)</TableCell>
                                            <TableCell className="text-end">{prixHT.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className="text-end">Montant de la TVA (20%)</TableCell>
                                            <TableCell className="text-end">{tva.toFixed(2)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className="text-end">Montant Total TTC</TableCell>
                                            <TableCell className="text-end">
                                                <Typography variant="h4" fontWeight="bold" margin="0">
                                                    {(prixHT * 1.2).toFixed(2)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {(!isAnyFactured && avances[0]?.status === 'oui') ? (
                                <div className="d-print-none mt-4">
                                    <Typography variant="h6" mb={1}>
                                        {('Êtes-vous sûr de vouloir créer la facture pour ce projet ?').toUpperCase()}
                                    </Typography>

                                    <div className="float-end">
                                        {(buttonShow === true) &&
                                            <>
                                                <Tooltip title="Facturer le projet" arrow>
                                                    <Button
                                                        onClick={handleCreateFacture}
                                                        variant="contained"
                                                        color="info"
                                                        sx={{
                                                            color: "#fff !important",
                                                            backgroundColor: 'green !important',
                                                        }}
                                                    >
                                                        OUI
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Annuler la création de la facture" arrow>
                                                    <Button
                                                        onClick={handleClose}
                                                        variant="contained"
                                                        color="error"
                                                        sx={{
                                                            marginLeft: '0.7rem',
                                                            color: "#fff !important",
                                                            backgroundColor: 'red !important',
                                                        }}
                                                    >
                                                        NON
                                                    </Button>
                                                </Tooltip>
                                            </>
                                        }
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Typography variant="h6" mb={1} mt={3}>
                                        {('Ce projet est déjà facturé !').toUpperCase()}
                                    </Typography>
                                </>
                            )}
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={openSnackbar}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                        >
                            <Alert
                                onClose={handleSnackbarClose}
                                severity={snackbarSeverity}
                                sx={{ mt: 2 }}
                            >
                                {snackbarMessage}
                            </Alert>
                        </Snackbar>
                    </Container>
                </>
            ) : (
                <>
                    Oups! aucune nature trouvé dans le projet
                </>
            )
            }

        </>
    );
}

export default FactureAvance;