import React, { useState, useEffect } from "react";
import { createProject } from "../../actions/ProjetActions";
import { getAllNatures } from '../../actions/NatureActions';
import { getAllPieces } from '../../actions/PieceActions';
import { getAllCommercials } from '../../actions/ContactActions';
import { useDispatch, useSelector } from "react-redux";
import { updateDevisStatut } from "../../actions/DevisActions";
import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Card,
    CardContent,
    MenuItem,
    Autocomplete,
    TextField,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Paid';
import { fetchAllProjects } from "../../actions/ProjetActions";

const DevisProject = ({ devis, userId }) => {
    const dispatch = useDispatch();
    const natures = useSelector((state) => state.nature.natures);
    const commercials = useSelector((state) => state.commercial.commercials);
    const pieces = useSelector((state) => state.piece.pieces);
    const [state, setState] = useState({
        reference_fonciere: '',
        situation: '',
        observation: '',
        facture: '',
        commercialId: '',
        selectedNatures: [],
        selectedPieces: [],
        userId: '',
        error: '',
        submitted: false,
        selectedOption: "none",
        showForm: false,
    });
    const [message, setMessage] = useState('');

    useEffect(() => {
        dispatch(getAllNatures());
        dispatch(getAllPieces());
        dispatch(getAllCommercials());
    }, [dispatch]);

    const handleOptionChange = (event) => {
        const selectedOption = event.target.value;
        const showForm = selectedOption !== "none";
        setState({
            ...state,
            selectedOption,
            showForm
        });
    };

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const handleNatureChange = (event) => {
        const { selectedNatures } = state;
        const natureId = parseInt(event.target.value, 10);

        if (event.target.checked) {
            setState({
                ...state,
                selectedNatures: [...selectedNatures, natureId],
            });
        } else {
            setState({
                ...state,
                selectedNatures: selectedNatures.filter((id) => id !== natureId),
            });
        }
    };

    const handlePieceChange = (event) => {
        const { selectedPieces } = state;
        const pieceId = parseInt(event.target.value, 10);

        if (event.target.checked) {
            setState({
                ...state,
                selectedPieces: [...selectedPieces, pieceId],
            });
        } else {
            setState({
                ...state,
                selectedPieces: selectedPieces.filter((id) => id !== pieceId),
            });
        }
    };

    const handleValider = (e) => {
        e.preventDefault();

        const projectData = {
            reference_fonciere: state.reference_fonciere,
            date_creation: new Date(),
            situation: state.situation,
            prix: devis.montant_ht || null,
            statut: 'preparation',
            paiement: 'non',
            observation: state.observation,
            facture: state.facture || 'non',
            clientId: devis.clientId,
            commercialId: state.commercialId || null,
            naturesIds: state.selectedNatures,
            piecesIds: state.selectedPieces,
            userId: userId,
        };

        dispatch(createProject(projectData))
            .then((response) => {
                dispatch(updateDevisStatut(devis.id, { reference: response?.data?.numero }));
                setState({
                    ...state,
                    reference_fonciere: '',
                    situation: '',
                    observation: '',
                    facture: '',
                    commercialId: '',
                    selectedNatures: [],
                    selectedPieces: [],
                    userId: '',
                    error: '',
                    submitted: true,
                });

                setMessage('Devis confirmé avec succès');
            })
            .catch((error) => {
                setMessage('Échec de la validation de Devis');
                console.log('Error creating devis:', error);
            });
    };

    return (
        <Container>
            <Typography
                variant="h2"
                textAlign="center"
                mb={1}
                sx={{
                    fontSize: '36px',
                    fontWeight: 'bold',
                    color: '#3e4396',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
                }}
            >
                Créer la projet de devis
            </Typography>
            {state.submitted ? (
                <div className="text-center">
                    <Container>
                        <div className="alert alert-success">
                            {message}
                        </div>
                    </Container>
                </div>
            ) : (
                <div className="mt-3">
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Card variant="outlined">
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <PaidIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                        <Typography variant="h6" color="info" gutterBottom>
                                            PRIX :
                                        </Typography>
                                    </Stack>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {devis?.montant_ht}&nbsp;
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="success"
                                            sx={{ verticalAlign: 'top', fontSize: '0.8rem' }}
                                        >
                                            MAD
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={6}>
                            <Card variant="outlined">
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <PersonIcon fontSize="large" sx={{ mr: 1 }} color="info" />
                                        <Typography variant="h6" color="info" gutterBottom>
                                            CLIENT:
                                        </Typography>
                                    </Stack>
                                    <Typography component="div" variant="h5" gutterBottom>
                                        {devis?.client?.full_name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <form className="row mt-2" onSubmit={handleValider}>
                        <div className="col-sm-6">
                            <TextField
                                label="Reference Fonciere"
                                name="reference_fonciere"
                                value={state.reference_fonciere}
                                onChange={handleChange}
                                fullWidth
                                required
                                sx={{ marginBottom: 3 }}
                                variant="standard"
                            />
                            <TextField
                                label="Situation"
                                name="situation"
                                value={state.situation}
                                onChange={handleChange}
                                fullWidth
                                sx={{ marginBottom: 3 }}
                                variant="standard"
                            />
                            <TextField
                                label="Observation"
                                name="observation"
                                value={state.observation}
                                onChange={handleChange}
                                multiline
                                fullWidth
                                rows={4}
                                sx={{ marginBottom: 3 }}
                                variant="standard"
                            />
                        </div>
                        <div className="col-md-6">
                            <TextField
                                fullWidth
                                name="facture"
                                label='Facturé'
                                value={state.facture}
                                onChange={handleChange}
                                sx={{ marginBottom: 3 }}
                                variant="standard"
                                select
                            >
                                <MenuItem value="non">Non</MenuItem>
                                <MenuItem value="oui">Oui</MenuItem>
                            </TextField>
                            <TextField
                                value={state.selectedOption}
                                onChange={handleOptionChange}
                                fullWidth
                                label='Par Contact'
                                sx={{ marginBottom: 3 }}
                                variant="standard"
                                select
                            >
                                <MenuItem value="none" disabled>Select Option...</MenuItem>
                                <MenuItem value="none">Non</MenuItem>
                                <MenuItem value="oui">Oui</MenuItem>
                            </TextField>

                            {state.showForm && (
                                <>
                                    <Autocomplete
                                        options={commercials || []}
                                        getOptionLabel={(commercial) => commercial.name}
                                        fullWidth
                                        value={commercials.find((commercial) => commercial.id === state.commercialId) || null}
                                        onChange={(event, newValue) => {
                                            setState({ ...state, commercialId: newValue ? newValue.id : '' });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Contact"
                                                sx={{ marginBottom: 3 }}
                                                variant="standard"
                                            />
                                        )}

                                    />
                                </>
                            )}
                        </div>
                        <div className="col-md-6">
                            <Typography>Nature</Typography>
                            <FormGroup>
                                {natures?.map((nature) => (
                                    <FormControlLabel
                                        key={nature.id}
                                        control={
                                            <Checkbox
                                                name={nature.name}
                                                value={nature.id}
                                                checked={state.selectedNatures.includes(nature.id)}
                                                onChange={handleNatureChange}
                                            />
                                        }
                                        label={nature.name}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                        <div className="col-md-6">
                            <Typography>Pieces</Typography>
                            <FormGroup>
                                {pieces?.map((piece) => (
                                    <FormControlLabel
                                        key={piece.id}
                                        control={
                                            <Checkbox
                                                name={piece.name}
                                                value={piece.id}
                                                checked={state.selectedPieces.includes(piece.id)}
                                                onChange={handlePieceChange}
                                            />
                                        }
                                        label={piece.piece}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                        <div className="mt-3 form-group">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: "#3e4396",
                                    color: "#e0e0e0",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "10px 20px",
                                }}
                            >
                                Valider la devis
                            </Button>
                        </div>
                    </form>
                </div>
            )
            }
        </Container >
    )
}
const ValiderDevis = ({ devis, userId, handleClose }) => {
    const [submited, setSubmited] = useState(false);
    const [lieExist, setLieExist] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [color, setColor] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [projet, setProjet] = useState(null);
    const projects = useSelector((state) => state.project.projects);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllProjects());
    }, [dispatch]);

    const handleValiderDevis = (e) => {
        e.preventDefault();
        dispatch(updateDevisStatut(devis.id, { valider: 'oui' }))
            .then(() => {
                setSubmited(true);
                setMessageSuccess('Devis confirmé avec succès')
                setColor('success');
            })
            .catch(() => {
                setSubmited(true);
                setMessageSuccess('Error de confirmation de Devis')
                setColor('error');
            })
    }

    const handleLieDevis = (e) => {
        e.preventDefault();
        dispatch(updateDevisStatut(devis.id, { projetId: projet.value, reference: projet.label }))
            .then(() => {
                setSubmited(true);
                setMessageSuccess('Projet ajouté avec succès')
                setColor('success');
            })
            .catch(() => {
                setSubmited(true);
                setMessageSuccess("Error d'ajoutation de Devis")
                setColor('error');
            })
    }

    const projectOptions = projects.map((projet) => ({
        value: projet.id,
        label: projet?.numero,
    }));

    return (
        <>
            {submited ? (
                <>
                    <Container>
                        <div className={`alert alert-${color} mb-2`}>
                            {messageSuccess}
                        </div>
                        <Typography variant="h6" textAlign={'center'} gutterBottom>
                            Voulez-vous être lié au projet ?
                        </Typography>
                        <Box sx={{
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button
                                onClick={() => setLieExist(true)}
                                sx={{
                                    color: "#fff !important",
                                    backgroundColor: "green !important",
                                }}>
                                lié au projet existant
                            </Button>
                            <Button
                                onClick={() => setIsDialogOpen(true)}
                                sx={{
                                    marginLeft: '0.6rem',
                                    color: "#fff !important",
                                    backgroundColor: "red !important",
                                }}>
                                Créer un projet
                            </Button>
                            <Button
                                sx={{
                                    marginLeft: '0.6rem',
                                    color: "#fff !important",
                                    backgroundColor: "red !important",
                                }}>
                                Non
                            </Button>
                        </Box>
                        {lieExist &&
                            <Box marginTop={2}>
                                <Autocomplete
                                    options={projectOptions}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => setProjet(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Projet " />}
                                />
                                <Button
                                    onClick={handleLieDevis}
                                    sx={{
                                        marginTop: '0.6rem',
                                        color: "#fff !important",
                                        backgroundColor: "blue !important",
                                    }}>
                                    Ajouter
                                </Button>
                            </Box>

                        }
                    </Container>

                    <Dialog
                        open={isDialogOpen}
                        onClose={() => setIsDialogOpen(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <>
                            <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                                Créer le projet de devis {devis?.numero}
                            </DialogTitle>
                            <DialogContent>
                                <DevisProject devis={devis} userId={userId} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setIsDialogOpen(false)} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </>
                    </Dialog>
                </>
            ) : (
                <>
                    <Container>
                        <Typography variant="h6" textAlign={'center'} gutterBottom>
                            Es-tu sûr de vouloir confirmer le devis: {devis?.numero}?
                        </Typography>
                        <Box sx={{
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button
                                onClick={handleValiderDevis}
                                sx={{
                                    color: "#fff !important",
                                    backgroundColor: "green !important",
                                }}>
                                Oui
                            </Button>
                            <Button
                                onClick={handleClose}
                                sx={{
                                    marginLeft: '0.6rem',
                                    color: "#fff !important",
                                    backgroundColor: "red !important",
                                }}>
                                Non
                            </Button>
                        </Box>
                    </Container>
                </>
            )}
        </>
    );
}

export default ValiderDevis;