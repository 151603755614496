import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { login } from '../../actions/UserActions';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  useMediaQuery,
  Card,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from 'notistack';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import MuiLink from '@mui/material/Link';
import BackgroundImage from '../../assets/images/3.png';
import Footer from "../../examples/Footer";
import logo from '../../assets/images/1.png';
const Login = () => {
  let navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const form = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { message } = useSelector(state => state.message);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    if (form.current.checkValidity()) {
      dispatch(login({ username, password }))
        .then(() => {
          enqueueSnackbar('Login successful!', { variant: 'success' });
          navigate("/");
        })
        .catch(() => {
          const errorMessage = message ? message : 'Login failed. Please check your credentials.';
          enqueueSnackbar(errorMessage, { variant: 'error' });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      sx={{
        overflowX: "hidden",
        width: "100vw",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Box
        position="absolute"
        width="100%"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            BackgroundImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box mt={10} width="100%">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {/* <Card 
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)', 
            }}> */}
              <Box
                variant="gradient"
                backgroundColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Typography variant="h4" fontWeight="medium" color="white" mt={5}>
                  <img src={logo} width={'100%'} alt="" />
                  {/* Se Connecter */}
                </Typography>
              </Box>
              <Box pt={4} pb={3} px={3}>
                <Box component="form" role="form">
                  <Box mb={2}>
                    <TextField
                      type="text"
                      label="Nom d'utilisateur"
                      variant="standard"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      type="password"
                      label="Mot de passe"
                      variant="standard"
                      fullWidth
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Box>
                  <Box mt={4} mb={1}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleLogin}
                    >
                      Se Connecter
                    </Button>
                  </Box>
                  {loading && <CircularProgress size={20} />}
                  <Box mt={3} mb={1} textAlign="center">
                  </Box>
                </Box>
              </Box>
              <Box
                variant="gradient"
                backgroundColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                  <Grid item xs={2}>
                    <Typography component={MuiLink} href="#" variant="body1" color="white">
                      <FacebookIcon color="inherit" />
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component={MuiLink} href="https://metic.ma" variant="body1" color="white">
                      <LanguageIcon color="inherit" />
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component={MuiLink} href="#" variant="body1" color="white">
                      <YouTubeIcon color="inherit" />
                    </Typography>
                  </Grid>
                </Grid>
            </Box>
            {/* </Card> */}
            <Footer light style={{ marginBottom: '2rem' }} />
          </Grid>
        </Grid>
      </Box>
    </Box >
  );
};

export default Login;
