import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllPendingAvances, updateAvance } from '../../actions/EncaissementActions';
import { DataGrid, GridToolbar, frFR } from '@mui/x-data-grid';
import colors from "../../assets/theme/base/colors"
import {
    Container,
    Typography,
    Button,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    useMediaQuery,
    DialogTitle,
} from "@mui/material";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PendingAvanceList = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const avances = useSelector((state) => state.encaissement.encaissements);
    const [pageSize, setPageSize] = useState(5);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isRejectionOpen, setRejectionOpen] = useState(false);
    const [selectedAvance, setSelectedAvance] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');

    useEffect(() => {
        dispatch(getAllPendingAvances());
    }, [dispatch]);

    useEffect(() => {
        if (isConfirmationOpen === false) {
            dispatch(getAllPendingAvances());
        }
    }, [isConfirmationOpen, dispatch]);

    const openValRejModal = (avance) => {
        setSelectedAvance(avance);
        setConfirmationOpen(true);
    };

    const closeValRejModal = () => {
        setSelectedAvance(null);
        setConfirmationOpen(false);
    };

    const openValModal = (avance) => {
        setSelectedAvance(avance);
        setConfirmationOpen(true);
    };

    const closeValModal = () => {
        setSelectedAvance(null);
        setConfirmationOpen(false);
    };

    const openRejModal = (avance) => {
        setSelectedAvance(avance);
        setRejectionOpen(true);
    };

    const closeRejModal = () => {
        setSelectedAvance(null);
        setRejectionOpen(false);
    };

    const handleValider = () => {
        if (selectedAvance) {
            dispatch(updateAvance(selectedAvance.id, { status: 'oui' }))
                .then(() => {
                    dispatch(getAllPendingAvances());
                    setConfirmationOpen(false);
                    console.log('sucesss');
                });
        }
        closeValModal();
    };

    const handleRejeter = () => {
        if (selectedAvance) {
            dispatch(updateAvance(selectedAvance.id, { status: 'non' }))
                .then(() => {
                    dispatch(getAllPendingAvances());
                    setRejectionOpen(false);
                    console.log('sucesss');
                });
        }
        closeRejModal();
    };

    const columns = [
        ...(isMobile
            ? []
            : [{ field: 'id', headerName: 'ID', flex: 0.5 },]
        ),
        {
            field: 'prix', headerName: 'Prix',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'type', headerName: 'Type de paiement',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'date', headerName: 'Date',
            ...(isMobile ? { width: 120 } : { flex: 1 }),
        },
        {
            field: 'projet.numero',
            headerName: 'Projet Numero',
            ...(isMobile ? { width: 140 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        {
            field: 'projet?.client?.full_name',
            headerName: 'Client',
            ...(isMobile ? { width: 260 } : { flex: 1 }),
            valueGetter: (params) => params.row.projet?.client?.full_name || '',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            renderCell: (params) => (
                <>
                    {(params.row?.status === 'en cours') && (currentUser && currentUser.roles.includes("ROLE_ADMIN")) &&
                        <MDButton
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => openRejModal(params.row)}
                            className='ms-2'
                        >
                            {isMobile ? <><HighlightOffIcon /></> : <><HighlightOffIcon />&nbsp;Rejeter</>}
                        </MDButton>
                    }
                    {(params.row?.status === 'en cours') && (currentUser && currentUser.roles.includes("ROLE_ADMIN")) &&
                        <MDButton
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => openValModal(params.row)}
                            className='ms-2'
                        >
                            {isMobile ? <><CheckCircleOutlineIcon /></> : <><CheckCircleOutlineIcon />&nbsp;Valider</>}
                        </MDButton>
                    }
                </>
            ),
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                {currentUser && (currentUser.roles.includes("ROLE_ADMIN")
                    || currentUser.permissions.includes("Valider des encaissements")) ? (
                    <>
                        <Typography textAlign={'center'} mt={2} mb={3} variant="h2" >
                            Liste des paiements en attente
                        </Typography>
                        <Box>
                            <Box
                                m="0 0 0 0"
                                height="90vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: colors.success,
                                    },
                                    "& .MuiDataGrid-columnHeaders": {
                                        backgroundColor: '#007bff',
                                        borderBottom: "none",
                                        color: "#fff !important"
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: '#007bff',
                                    },
                                }}
                            >
                                <DataGrid
                                    rows={avances.encaissements ?? []}
                                    columns={columns}
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    style={{ color: darkMode ? "#fff" : "#000" }}
                                    rowsPerPageOptions={[5, 10, 20]}
                                    components={{ Toolbar: GridToolbar }}
                                    autoHeight
                                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        <AccessDenied />
                    </>
                )}

                <Dialog
                    open={isConfirmationOpen}
                    onClose={closeValModal}
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle
                        textAlign={'center'}
                        sx={{
                            backgroundColor: "green",
                            color: '#fff '
                        }}
                    >
                        <CheckCircleOutlineIcon fontSize='large' />&nbsp;
                        Confirmation de l'avance
                    </DialogTitle>
                    <DialogContent>
                        <Typography mt={2} variant="h6" >
                            Êtes-vous sûr de vouloir confirmer cette avance?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={closeValModal}>
                            Annuler
                        </MDButton>
                        <MDButton
                            color='success'
                            onClick={handleValider}>
                            Valider
                        </MDButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isRejectionOpen}
                    onClose={closeRejModal}
                    fullWidth
                    maxWidth={'sm'}
                >
                    <DialogTitle
                        textAlign={'center'}
                        sx={{
                            backgroundColor: "red",
                            color: '#fff'
                        }}
                    >
                        <HighlightOffIcon fontSize='large' />&nbsp;
                        Rejet de l'avance
                    </DialogTitle>
                    <DialogContent>
                        <Typography mt={2} variant="h6" >
                            Êtes-vous sûr de vouloir rejeter cette avance ?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <MDButton onClick={closeRejModal}>
                            Annuler
                        </MDButton>
                        <MDButton
                            color='error'
                            onClick={handleRejeter}
                        >
                            Rejeter
                        </MDButton>
                    </DialogActions>
                </Dialog>
            </DashboardLayout>
        </>
    );
};

export default PendingAvanceList;
