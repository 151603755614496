import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createNature } from '../../actions/NatureActions';
import {
    Container,
    Typography,
    TextField,
    Button,
    Alert,
} from '@mui/material';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';

const CreateNature = ({ currentUser, createNature, success }) => {
    
    const [name, setName] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const natureData = {
            name,
        };
        createNature(natureData);
        setName('');
        setShowAlert(true);
    };

    const handleChange = (e) => {
        setName(e.target.value);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            { currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.permissions.includes('Ajouter des natures')) ? (
                <Container maxWidth="md">
                    <div className="row">
                        <Typography variant="h2" className="text-center">
                            Ajouter un nature
                        </Typography>
                        {showAlert && (
                            <Alert severity="success" className="mt-3">
                                La nature créée avec succès !
                            </Alert>
                        )}
                        <form className="form-horizontal row ms-5 ps-5 mt-4" onSubmit={handleSubmit}>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label className="control-label col-sm-4">Nature:</label>
                                    <div className="col-sm-8">
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-4">
                                <Button
                                    sx={{
                                        // backgroundColor: colors.blueAccent[700],
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        padding: "10px 20px",
                                    }}
                                    type="submit">
                                    Sauvegarder
                                </Button>
                            </div>
                        </form>
                    </div>
                </Container>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.user,
        success: state.nature.success,
    };
};

export default connect(mapStateToProps, { createNature })(CreateNature);
