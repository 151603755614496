import {
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAILURE,
    FETCH_PROJECT_SUCCESS,
    FETCH_PROJECT_FAILURE,
    FETCH_ALL_PROJECTS_REQUEST,
    FETCH_ALL_PROJECTS_SUCCESS,
    FETCH_ALL_PROJECTS_FAILURE,
    DELETE_PROJECT_SUCCESS,
    DELETE_PROJECT_FAILURE,
    FETCH_PROJECT_COUNT_REQUEST,
    FETCH_PROJECT_COUNT_SUCCESS,
    FETCH_PROJECT_COUNT_FAILURE,
    GET_PROJECTS_BY_STATUS_REQUEST,
    GET_PROJECTS_BY_STATUS_SUCCESS,
    GET_PROJECTS_BY_STATUS_FAILURE,
    GET_PROJECTS_BY_CLIENT_ID_REQUEST,
    GET_PROJECTS_BY_CLIENT_ID_SUCCESS,
    GET_PROJECTS_BY_CLIENT_ID_FAILURE,
    COUNT_PROJECTS_LAST_MONTH_SUCCESS,
    COUNT_PROJECTS_LAST_MONTH_FAILURE,
    COUNT_PROJECTS_LAST_MONTH,
    FETCH_PROJECT_COUNT_BY_MONTH_REQUEST,
    FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS,
    FETCH_PROJECT_COUNT_BY_MONTH_FAILURE,
    UPLOAD_PROJECT_FILES_FAILURE,
    UPLOAD_PROJECT_FILES_SUCCESS,
    GET_PROJECT_FILES_SUCCESS,
    GET_PROJECT_FILES_FAILURE,
} from './types';
import ProjectService from '../services/projetServices';

export const uploadProjectFiles = (projectId, formData) => {
    return async (dispatch) => {
        try {
            const response = await ProjectService.projectUploadFiles(projectId, formData);
            dispatch({
                type: UPLOAD_PROJECT_FILES_SUCCESS,
                payload: response.data,
            });
            return response;
        } catch (error) {
            dispatch({
                type: UPLOAD_PROJECT_FILES_FAILURE,
                payload: error.response ? error.response.data : 'An error occurred',
            });
            throw error;
        }
    };
};

export const fetchProjectFiles = (projectId) => {
    return (dispatch) => {
        return ProjectService.getAllProjectFiles(projectId)
            .then((response) => {
                dispatch({
                    type: GET_PROJECT_FILES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_PROJECT_FILES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const fetchFileContent = (projectId, fileName) => {
    return async (dispatch) => {
      try {
        const response = await ProjectService.getFileContent(projectId, fileName);
        return response; 
      } catch (error) {
        console.error('Error fetching file content:', error);
        throw error;
      }
    };
  };
  
  export const downloadFile = (projectId, fileName) => {
    return async (dispatch) => {
      try {
        const response = await ProjectService.downloadFile(projectId, fileName);
  
        if (response.data instanceof Blob) {
          const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
  
          document.body.appendChild(link);
          link.click();
  
          document.body.removeChild(link);
        } else {
          console.error('Invalid file content in the response.');
        }
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };
  };
  
  export const deleteFile = (projectId, fileName) => {
    return async (dispatch) => {
      try {
        const response = await ProjectService.deleteFile(projectId, fileName);
        return response; 
      } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
      }
    };
  };

export const createProjectSuccess = (project) => ({
    type: CREATE_PROJECT_SUCCESS,
    payload: project
});

export const createProjectFailure = (error) => ({
    type: CREATE_PROJECT_FAILURE,
    payload: error
});

export const createProject = (projectData) => {
    return (dispatch) => {
        return ProjectService.createProject(projectData)
            .then((response) => {
                dispatch(createProjectSuccess(response.data));
                return response; 
            })
            .catch((error) => {
                dispatch(createProjectFailure(error.message || 'Failed to create project.'));
                throw error; 
            });
    };
};

export const fetchProjectSuccess = (project) => ({
    type: FETCH_PROJECT_SUCCESS,
    payload: project,
});

export const fetchProjectFailure = (error) => ({
    type: FETCH_PROJECT_FAILURE,
    payload: error,
});

export const fetchProject = (projectId) => (dispatch) => {
    ProjectService.fetchProject(projectId)
        .then((response) => {
            console.log('Response data:', response.data);
            dispatch(fetchProjectSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            console.log('Error:', error.message);
            dispatch(fetchProjectFailure(error.message));
            throw error;
        });
};

export const updateProjectSuccess = (project) => ({
    type: UPDATE_PROJECT_SUCCESS,
    payload: project,
});

export const updateProjectFailure = (error) => ({
    type: UPDATE_PROJECT_FAILURE,
    payload: error,
});

export const updateProject = (projectId, updatedProject) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ProjectService.updateProject(projectId, updatedProject)
            .then((response) => {
                dispatch(updateProjectSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(updateProjectFailure(error.message));
                reject(error);
            });
    });
};

export const updateJusOnProjet = (projectId, updatedProject) => (dispatch) => {
    return new Promise((resolve, reject) => {
        ProjectService.updateOneProject(projectId, updatedProject)
            .then((response) => {
                dispatch(updateProjectSuccess(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(updateProjectFailure(error.message));
                reject(error);
            });
    });
};

export const fetchAllProjectsRequest = () => ({
    type: FETCH_ALL_PROJECTS_REQUEST
});

export const fetchAllProjectsSuccess = (projects) => ({
    type: FETCH_ALL_PROJECTS_SUCCESS,
    payload: projects
});

export const fetchAllProjectsFailure = (error) => ({
    type: FETCH_ALL_PROJECTS_FAILURE,
    payload: error
});

export const fetchAllProjects = () => {
    return (dispatch) => {
        dispatch(fetchAllProjectsRequest());
        return ProjectService.getAllProjects()
            .then((response) => {
                dispatch(fetchAllProjectsSuccess(response.data));
            })
            .catch((error) => {
                dispatch(fetchAllProjectsFailure(error.message || 'Failed to fetch projects.'));
            });
    };
};

export const deleteProjectSuccess = (projectId) => ({
    type: DELETE_PROJECT_SUCCESS,
    payload: projectId,
});

export const deleteProjectFailure = (error) => ({
    type: DELETE_PROJECT_FAILURE,
    payload: error,
});

export const deleteProject = (projectId) => (dispatch) => {
    ProjectService.deleteProject(projectId)
        .then(() => {
            dispatch(deleteProjectSuccess(projectId));
        })
        .catch((error) => {
            dispatch(deleteProjectFailure(error.message));
        });
};


// -------------- projects counts ----------------------

export const fetchProjectCountRequest = () => {
    return {
        type: FETCH_PROJECT_COUNT_REQUEST,
    };
};

export const fetchProjectCountSuccess = (projectCount) => {
    return {
        type: FETCH_PROJECT_COUNT_SUCCESS,
        payload: projectCount,
    };
};

export const fetchProjectCountFailure = (error) => {
    return {
        type: FETCH_PROJECT_COUNT_FAILURE,
        payload: error,
    };
};
export const fetchProjectCount = () => {
    console.log("Fetching project count...");
    return (dispatch) => {
        dispatch(fetchProjectCountRequest());
        return ProjectService.countProjectsByStatus()
            .then((response) => {
                const projectCount = response.data;
                console.log("Received project count data:", response.data);
                dispatch(fetchProjectCountSuccess(projectCount));
            })
            .catch((error) => {
                const errorMessage = error.message;
                dispatch(fetchProjectCountFailure(errorMessage));
            });
    };
};
// ---------------------ProjectsByStatus-------------------------------------
export const getProjectsByStatusRequest = () => ({
    type: GET_PROJECTS_BY_STATUS_REQUEST,
});

export const getProjectsByStatusSuccess = (projects) => ({
    type: GET_PROJECTS_BY_STATUS_SUCCESS,
    payload: projects,
});

export const getProjectsByStatusFailure = (error) => ({
    type: GET_PROJECTS_BY_STATUS_FAILURE,
    payload: error,
});

export const fetchProjectsByStatus = (statut) => (dispatch) => {
    ProjectService.getProjectsByStatus(statut)
        .then((response) => {
            dispatch(getProjectsByStatusSuccess(response.data));
        })
        .catch((error) => {
            dispatch(getProjectsByStatusFailure(error.message));
        });
};

// ------------------------------------------------------
export const getProjectsByClientIdRequest = () => ({
    type: GET_PROJECTS_BY_CLIENT_ID_REQUEST,
});

export const getProjectsByClientIdSuccess = (projects) => ({
    type: GET_PROJECTS_BY_CLIENT_ID_SUCCESS,
    payload: projects,
});

export const getProjectsByClientIdFailure = (error) => ({
    type: GET_PROJECTS_BY_CLIENT_ID_FAILURE,
    payload: error,
});

export const getProjectsByClientId = (clientId) => (dispatch) => {
    dispatch(getProjectsByClientIdRequest());
    return ProjectService.getProjectsByClientId(clientId)
        .then((response) => {
            dispatch(getProjectsByClientIdSuccess(response.data));
        })
        .catch((error) => {
            dispatch(getProjectsByClientIdFailure(error.message));
        });
};

export const countProjectsLastMonth = () => {
    return async (dispatch) => {
        dispatch({ type: COUNT_PROJECTS_LAST_MONTH });
        console.log("test");
        try {
            const response = await ProjectService.countProjectsOfMonth();
            const count = response.data.count;
            console.log("test", count);
            dispatch({
                type: COUNT_PROJECTS_LAST_MONTH_SUCCESS,
                payload: count,
            });
        } catch (error) {
            console.log("test", error);
            dispatch({
                type: COUNT_PROJECTS_LAST_MONTH_FAILURE,
                payload: error.message,
            });
        }
    };
};

export const NumberOfProjectsLastMonth = () => {
    return async (dispatch) => {
        dispatch({ type: COUNT_PROJECTS_LAST_MONTH });
        console.log("test");
        try {
            const response = await ProjectService.countProjectsOfMonth();
            const count = response.data.count;
            console.log("test", count);
            dispatch({
                type: COUNT_PROJECTS_LAST_MONTH_SUCCESS,
                payload: count,
            });
        } catch (error) {
            console.log("test", error);
            dispatch({
                type: COUNT_PROJECTS_LAST_MONTH_FAILURE,
                payload: error.message,
            });
        }
    };
};

// -------------------FETCH PROJECTS BY MOUNTHS -----------------
export const fetchProjectCountByMonth = (month, year) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_PROJECT_COUNT_BY_MONTH_REQUEST });
        try {
            const response = await ProjectService.countProjectsByMonth(month, year);
            console.log("API Response:", response);
            const projectCounts = response.data;

            dispatch({
                type: FETCH_PROJECT_COUNT_BY_MONTH_SUCCESS,
                payload: projectCounts
            });
        } catch (error) {
            dispatch({
                type: FETCH_PROJECT_COUNT_BY_MONTH_FAILURE,
                payload: error.response.data.error
            });
        }
    };
};