import {
    CREATE_COTISATION_SUCCESS,
    CREATE_COTISATION_FAILURE,
    UPDATE_COTISATION_SUCCESS,
    UPDATE_COTISATION_FAILURE,
    GET_COTISATION_SUCCESS,
    GET_COTISATION_FAILURE,
    DELETE_COTISATION_SUCCESS,
    DELETE_COTISATION_FAILURE,
    GET_ALL_COTISATIONS_SUCCESS,
    GET_ALL_COTISATIONS_FAILURE,
  } from '../actions/types';
const initialState = {
  cotisation: null,
  cotisations: [],
  error: null,
};

const cotisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_COTISATION_SUCCESS:
      return {
        ...state,
        cotisation: action.payload,
        error: null,
      };
    case CREATE_COTISATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_COTISATION_SUCCESS:
      return {
        ...state,
        cotisation: action.payload,
        error: null,
      };
    case UPDATE_COTISATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_COTISATION_SUCCESS:
      return {
        ...state,
        cotisation: action.payload,
        error: null,
      };
    case GET_COTISATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_COTISATION_SUCCESS:
      return {
        ...state,
        cotisation: null,
        error: null,
      };
    case DELETE_COTISATION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_ALL_COTISATIONS_SUCCESS:
      return {
        ...state,
        cotisations: action.payload,
        error: null,
      };
    case GET_ALL_COTISATIONS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default cotisationsReducer;