import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRequests } from '../../actions/RequestActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";
import {
    Box,
    TextField,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from "../Errors/AccessDenied";
import MDButton from '../../components/MDButton';

const RequestsList = ({ currentUser, darkMode }) => {
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch();
    const requests = useSelector((state) => state.request.requests);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRequests, setFilteredRequests] = useState([]);

    useEffect(() => {
        dispatch(getAllRequests());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        // setCurrentPage(1);
    };
    useEffect(() => {
        const filteredRequests = Array.isArray(requests)
        ? requests.filter((request) => {
            const mois = request?.mois || '';
            const type = request.type || '';
            const user = request.user?.nom || '';
            return (
                mois.toLowerCase().includes(searchTerm.toLowerCase()) ||
                type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.toLowerCase().includes(searchTerm.toLowerCase())
            );
        })
        : [];
    
        setFilteredRequests(filteredRequests);
    }, [requests, searchTerm]);

    const columns = [
        { field: 'type', headerName: 'Type', flex: 1 },
        {
            field: 'user?.nom', headerName: 'User', flex: 1,
            valueGetter: (params) => params.row.user?.nom || '',
        },
        { field: 'mois', headerName: 'Mois', flex: 1 },
        { field: 'createdAt', headerName: 'Created At', flex: 1 },
        { field: 'prix', headerName: 'Cout', flex: 1 },
        {
            field: 'Actions',
            headerName: 'Actions',
            renderCell: (params) => (
                <>
                        <MDButton
                            component={Link}
                            className='me-1'
                            size='small'
                            color='success'
                            to={`/projects/info/project/${params.id}`}>
                            <VisibilityIcon />
                        </MDButton>

                        <MDButton
                            component={Link}
                            className='mS-1'
                            size='small'
                            color='info'
                            to={`/requests/update/${params.id}`}
                        >
                            <BorderColorIcon />
                        </MDButton>

                </>
            ),
            flex: 2,
            cellClassName: 'actions-cell',
        },
    ];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Voir la liste des projets")) ? (
                <Box m="20px">
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Search Project"
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    <Box
                        m="0 0 0 0"
                        height="75vh"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "none",
                            },
                            "& .name-column--cell": {
                                color: "leaderboard",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "secondary",
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: "primary",
                            },
                            "& .MuiDataGrid-footerContainer": {
                                borderTop: "none",
                                backgroundColor: "secondary",
                            },
                            "& .MuiCheckbox-root": {
                                color: `leaderboard !important`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `primary !important`,
                            },
                        }}
                    >
                        <DataGrid
                            rows={filteredRequests}
                            columns={columns}
                            components={{ Toolbar: GridToolbar }}
                            style={{ color: darkMode ? "#fff" : "#000" }}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            disableSelectionOnClick
                        />
                    </Box>
                </Box>
            ) : (
                <>
                    <AccessDenied />
                </>
            )}
        </DashboardLayout>
    );
};

export default RequestsList;

