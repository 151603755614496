import API_URL  from './apiUrl';

class ContactService {
  createCommercial(commercialData) {
    return API_URL.post("/contacts/create/", commercialData);
  }

  getAllCommercials() {
    return API_URL.get("/contacts");
  }

  updateCommercial(commercialId, updatedCommercial) {
    return API_URL.put(`/contacts/update/${commercialId}`, updatedCommercial);
  }

  fetchCommercial(commercialId) {
    return API_URL.get(`/contacts/${commercialId}`);
  }
  
  deleteCommercial(commercialId) {
    return API_URL.delete(`/contacts/delete/${commercialId}`);
  }
}

export default new ContactService();
