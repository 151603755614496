import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPendingTaches, deleteTache } from '../../actions/TacheActions';
import {
    Box,
    Typography,
    Snackbar,
    Alert,
    Card,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useMaterialUIController } from "../../context";
import MDButton from '../../components/MDButton';

const UserPendingTaches = ({ userId }) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const dispatch = useDispatch();
    const tasks = useSelector((state) => state.tache.taches) ?? [];
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [tacheToDelete, setTacheToDelete] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getPendingTaches(userId))
            .then(() => setLoading(false))
            .catch((error) => {
                console.error('Error fetching pending tasks:', error);
                setLoading(false);
            });
    }, [dispatch, userId]);

    const handleDeleteClick = (tacheId) => {
        setTacheToDelete(tacheId);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = () => {
        dispatch(deleteTache(tacheToDelete))
            .then(() => {
                setSuccessMessage('Task deleted successfully.');
                setOpenSnackbar(true);
                dispatch(getPendingTaches(userId))
            })
            .catch((error) => {
                console.error('Error deleting task:', error);
            })
            .finally(() => {
                setDeleteDialogOpen(false);
            });
    };

    const columns = [
        {
            field: 'projet?.numero', headerName: 'Project', flex: 1,
            valueGetter: (params) => params.row.projet?.numero || '',
        },
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'deadline', headerName: 'Deadline', flex: 1 },
        {
            field: 'projet?.reference_fonciere',
            headerName: 'Reference',
            flex: 1,
            valueGetter: (params) => params.row.projet?.reference_fonciere || '',
        },
        {
            field: 'nature',
            headerName: 'Nature',
            flex: 1,
            valueGetter: (params) => params.row.projet?.nature?.map((natures) => natures.name).join(", "),
        },
        {
            field: 'projet?.date_creation',
            headerName: 'Created At',
            flex: 1,
            valueGetter: (params) => params.row.projet?.date_creation || '',
        },
        {
            field: 'delete',
            headerName: 'Delete',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Supprimer les tâches")) && (
                            <MDButton
                                variant="contained"
                                size='small'
                                color="error"
                                onClick={() => handleDeleteClick(params.row.id)}
                            >
                                Delete
                            </MDButton>
                        )}
                    </>
                );
            },
            cellClassName: 'actions-cell',
        },
    ];

    return (
        <>
            <Card>
                <DataGrid
                    className={darkMode ? "text-light" : "text-dark"}
                    rows={tasks?.tasks ?? []}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    loading={loading}
                    autoHeight
                />
            </Card>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={4000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                >
                    {successMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Supprimer la tâche</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer cette tâche ?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button
                        sx={{
                            color: 'white !important',
                            backgroundColor: 'red !important',
                        }}
                        onClick={handleDeleteConfirm} color="error">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserPendingTaches;
