import React, { useEffect, useState } from "react";
import PolygonServices from '../../services/polygonServices';
import PolylineServices from '../../services/polylineServices';
import {
    Typography,
    Card,
    Grid,
    MenuItem,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,

} from "@mui/material";
import MDButton from "../../components/MDButton";
import ProjectPolygons from "./ShowPolygon";
import ShowPolylines from "./ShowPolylines";
import PointsMap from "./PointsRat";
const SelectedZone = () => {
    const [selectedProjection, setSelectedProjection] = useState('26191');
    const [isPointsOpen, setIsPointsOpen] = useState(false);
    const handleProjectionChange = (event) => {
        setSelectedProjection(event.target.value);
    };
    return (
        <>
            <Grid item xs={12} lg={8}>
                <Card>
                    <Box p={2} lineHeight={0}>
                        <Typography variant="h3">Select Zone</Typography>
                    </Box>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} >
                                <TextField
                                    select
                                    label='Select Projection'
                                    id="projection-select"
                                    name="selectedProjection"
                                    value={selectedProjection}
                                    fullWidth
                                    onChange={handleProjectionChange}
                                >
                                    <MenuItem value="26191">Zone 1: Merchich / zone Nord</MenuItem>
                                    <MenuItem value="26192">Zone 2: Merchich / zone Sud </MenuItem>
                                    <MenuItem value="26194">Zone 3: Merchich / sahara Nord</MenuItem>
                                    <MenuItem value="26195">Zone 4: Merchich / sahara Nord</MenuItem>
                                </TextField>
                            </Grid>
                            {selectedProjection && <>
                                <Grid item xs={12} sm={12} >
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        onClick={() => setIsPointsOpen(true)}
                                    >
                                        Show
                                    </MDButton>
                                </Grid>
                            </>}
                        </Grid>
                    </Box>
                </Card>
            </Grid>
            <Dialog
                open={isPointsOpen}
                onClose={() => setIsPointsOpen(false)}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle className='text-center' style={{ fontSize: '29px' }}>
                Points de Rattachements
                </DialogTitle>
                <DialogContent>
                    <PointsMap zone={selectedProjection} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsPointsOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default SelectedZone;