import TachesService from '../services/tacheServices';
import {
    CREATE_TACHE_SUCCESS,
    CREATE_TACHE_FAILURE,
    UPDATE_TACHE_SUCCESS,
    UPDATE_TACHE_FAILURE,
    GET_TACHE_SUCCESS,
    GET_TACHE_FAILURE,
    DELETE_TACHE_SUCCESS,
    DELETE_TACHE_FAILURE,
    GET_ALL_TACHES_SUCCESS,
    GET_ALL_TACHES_FAILURE,
    GET_TACHE_BY_USER_SUCCESS,
    GET_TACHE_BY_USER_FAILURE,
    CONFIRM_TACHE_SUCCESS,
    CONFIRM_TACHE_FAILURE,
    GET_TACHE_BY_PROJECTID_SUCCESS,
    GET_TACHE_BY_PROJECTID_FAILURE,
    RESSIGN_TACHE_SUCCESS,
    RESSIGN_TACHE_FAILURE,
    GET_USER_TACHES_SUCCESS,
    GET_USER_TACHES_FAILURE,
} from './types';

export const createTache = (tacheData) => {
    return (dispatch) => {
        return TachesService.createTache(tacheData)
            .then((response) => {
                dispatch({
                    type: CREATE_TACHE_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch((error) => {
                console.log(error.response.data.message);
                dispatch({
                    type: CREATE_TACHE_FAILURE,
                    payload: error.response.data.message || "Error assigning task.",
                });
                throw error;
            });
    };
};

export const createNewTache = (tacheData) => {
    return (dispatch) => {
        return TachesService.createNewTache(tacheData)
            .then((response) => {
                dispatch({
                    type: CREATE_TACHE_SUCCESS,
                    payload: response.data,
                });
                return Promise.resolve();
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_TACHE_FAILURE,
                    payload: error.message,
                });
                return Promise.reject(error);
            });
    };
};

export const getAllTache = () => {
    return (dispatch) => {
        return TachesService.fetchAllTaches()
            .then((response) => {
                dispatch({
                    type: GET_ALL_TACHES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_ALL_TACHES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const updateTache = (tacheId, updatedTache) => {
    return (dispatch) => {
        return TachesService.updateTache(tacheId, updatedTache)
            .then((response) => {
                dispatch({
                    type: UPDATE_TACHE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: UPDATE_TACHE_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getTache = (tacheId) => {
    return (dispatch) => {
        return TachesService.fetchTache(tacheId)
            .then((response) => {
                dispatch({
                    type: GET_TACHE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TACHE_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const deleteTache = (tacheId) => {
    return (dispatch) => {
        return TachesService.deleteTache(tacheId)
            .then((response) => {
                dispatch({
                    type: DELETE_TACHE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DELETE_TACHE_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getPendingTaches = (userId) => {
    return (dispatch) => {
        return TachesService.fetchPendingTache(userId)
            .then((response) => {
                dispatch({
                    type: GET_TACHE_BY_USER_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TACHE_BY_USER_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getUserTaches = (userId) => {
    return (dispatch) => {
        return TachesService.fetchUserTaches(userId)
            .then((response) => {
                dispatch({
                    type: GET_USER_TACHES_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_USER_TACHES_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const confirmTache = (tacheId) => {
    return (dispatch) => {
        return TachesService.confirmTache(tacheId)
            .then((response) => {
                dispatch({
                    type: CONFIRM_TACHE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CONFIRM_TACHE_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const getProjectTasks = (projectId) => {
    return (dispatch) => {
        return TachesService.fetchProjectTasks(projectId)
            .then((response) => {
                dispatch({
                    type: GET_TACHE_BY_PROJECTID_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: GET_TACHE_BY_PROJECTID_FAILURE,
                    payload: error.message,
                });
            });
    };
};

export const ressignTask = (tacheData) => {
    return (dispatch) => {
        return TachesService.ressignTache(tacheData)
            .then((response) => {
                dispatch({
                    type: RESSIGN_TACHE_SUCCESS,
                    payload: response.data,
                });
            })
            .catch((error) => {
                dispatch({
                    type: RESSIGN_TACHE_FAILURE,
                    payload: error.message,
                });
            });
    };
};