import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Paper,
    Backdrop,
    Fade,
    Typography,
    Button,
    Modal,
    Snackbar,
    Alert,
    IconButton,
    Tooltip,
} from '@mui/material';
import { getAllUserCharges, deleteCharge } from '../../actions/ChargesActions';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AccessDenied from '../Errors/AccessDenied';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';

const formatDateForMonthDisplay = (dateString) => {
    if (!dateString) return "";
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
};
const ChargesByUser = ({ currentUser, darkMode }) => {
    const dispatch = useDispatch();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    let charges = useSelector((state) => state.charges.charges) || [];
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [openModal, setOpenModal] = useState(false);
    const [selectedNature, setSelectedNature] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        dispatch(getAllUserCharges(currentUser.id));
    }, [dispatch, currentUser.id]);

    const handleOpenModal = (nature) => {
        setSelectedNature(nature);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setSelectedNature(null);
        setOpenModal(false);
    };

    const handleDelete = () => {
        if (selectedNature) {
            dispatch(deleteCharge(selectedNature.id))
                .then(() => {
                    setSnackbarMessage('Charge supprimée avec succès');
                    setSnackbarSeverity('success');
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    setSnackbarMessage('Échec de la suppression du Charge');
                    setSnackbarSeverity('error');
                    setSnackbarOpen(true);
                });
            handleCloseModal();
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const indexOfLastCharge = currentPage * itemsPerPage;
    const indexOfFirstCharge = indexOfLastCharge - itemsPerPage;
    const currentCharges = Array.isArray(charges) ? charges.slice(indexOfFirstCharge, indexOfLastCharge) : [];

    const columns = [
        { field: 'designation', headerName: 'Designation', flex: 1 },
        { field: 'mois', headerName: 'Date', flex: 1, valueFormatter: (params) => formatDateForMonthDisplay(params.value) },
        { field: 'cout', headerName: 'Cout', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    {params.row.status === 'en cours' && (
                        <Tooltip title="Modifier" arrow>
                            <IconButton color="info" component={Link} to={`/charges/update/${params.row.id}`} >
                                <BorderColorIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                    {params.row.status === 'en cours' && (
                        <Tooltip title="Supprimer" arrow>
                            <IconButton color="error" onClick={() => handleOpenModal(params.row)}>
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            ),
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {currentUser && (currentUser.roles.includes("ROLE_ADMIN") || currentUser.permissions.includes("Ajouter des charges")) ? (
                <Container maxWidth="md" style={{ color: darkMode ? "#fff" : "#000" }}>
                    <Typography variant="h2" textAlign={'center'} mb={5} gutterBottom>
                        Liste du Charges
                    </Typography>

                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={currentCharges}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            pageSize={itemsPerPage}
                        />
                    </div>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                            count={Math.ceil(charges.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                </Container>
            ) : (
                <div className="container">
                    <AccessDenied />
                </div>
            )}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh"
                    >
                        <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
                            <Typography variant="h6" gutterBottom>
                                Confirmation de la suppression
                            </Typography>
                            <Typography paragraph>
                                Etes-vous sûr de vouloir supprimer ce Charge?
                            </Typography>
                            <Button
                                onClick={handleDelete}
                                variant="contained"
                                color="error"
                                style={{ marginRight: '10px' }}
                            >
                                Supprimer
                            </Button>
                            <Button onClick={handleCloseModal} variant="contained" color="primary">
                                Annuler
                            </Button>
                        </Paper>
                    </Box>
                </Fade>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert variant="filled" onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </DashboardLayout>
    );
};

export default ChargesByUser;
